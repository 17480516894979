body:not(.home) {

  h2 {
    font-size:18px;
    font-weight:bold;
    margin-bottom:15px;
    //line-height:16px;
  }
  .wide-wrapper {
    width:100%;
  }
  .middle {
    padding-top:115px;

    //@media screen and (max-width: 63.9375em) {
    //  padding-top:$topbar_height*0.75 + ($topbar_height*0.75*0.1);
    //}

    > .wide-wrapper:nth-child(1) {
      //padding-bottom:50px;
    }
  }

  .breadcrumbs {
    margin-top:1rem;
    font-size:14px;
  }

  .sidebar {
    float:left;

    .sidebar-box {
      background-color:white;
      margin-top:25px;

      h3 {
        a {
          text-decoration:underline;
        }
      }
      p {
        color:#545454;
        line-height:14px;
        font-size:85%;
      }
      hr {
        margin: 0 0;
      }
    }
    .sidebar-box:nth-child(1) {
      margin-top:0;
    }
  }
  .main-content {
    //float:left;
    background-color:white;
    margin-bottom:25px;

    .row.with-padding > .column, .row.with-padding > .columns {
      padding: 25px;
    }
  }

  .map-frame {
    width:100%;
    height:250px;
    margin-bottom:-10px;
  }
  .small-info {
    font-size:90%;
  }

  table.specs-list {
    tbody {
      border:none;
      font-size:14px;

      tr {
        background-color:white;

        th {
          text-align:left;
          color:#a4a4a4;
        }
        td, th {
          padding:0;
        }
      }
    }
  }



  //.thumbnails-wrapper {
  //  overflow:scroll;
  //  -webkit-overflow-scrolling: touch;
  //
  //  .thumbnails-container {
  //    .thumbnail-wrapper {
  //      float:left;
  //      margin:0.9rem;
  //    }
  //  }
  //}
  //@for $i from 0 through 8 {
  //  .thumbnails-wrapper.has-#{$i}-thumbnails {
  //    .thumbnails-container {
  //      width: calc((0.9rem * 2 * #{$i}) + 0.9rem + (100px * #{$i}));
  //    }
  //  }
  //}
  //
  //.row.thumbnails, .thumbnail-wrapper {
  //  img {
  //    border:solid 1px rgba(0,0,0,0.5);
  //    -webkit-box-shadow: none;
  //    -moz-box-shadow: none;
  //    box-shadow: none;
  //    margin-bottom:0.9rem;
  //  }
  //}
}