.profile-selector-wrapper {
  //position: relative;

  .profile-results-wrapper {
    //position: absolute;
    //width: 100%;

    ul {
      list-style: none;
      margin: 0 0 25px;

      li {
        cursor: pointer;
        padding: 10px;

        .left {
          width: 10%;
          float: left;

          img {
            height: 50px;
            width: 50px;
            border-radius: 25px;
          }
        }
        .right {
          width: 90%;
          float: left;
          line-height: 50px;
        }

        &:hover {
          background-color: rgba(0,0,0,0.10);
        }
        &:nth-child(odd):not(:hover) {
          background-color: rgba(0,0,0,0.05);
        }
        &.active {
          outline: 2px solid rgba(125, 198, 255,1);
        }
      }
    }
  }
}