.project-lightbox-background {
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.9);
  position:fixed;
  left:0;
  top:0;
  z-index:100;
}
.project-lightbox-wrapper {
  width:100vw;
  height:100vh;
  z-index:101;
  position:fixed;
  left:0;
  top:0;
  padding:50px 0;
  max-width:1200px;
  color:white;
  text-shadow: 1px 1px 0 #212121;
  @media screen and (min-width: 1200px) {
    left:50%;
    margin-left:-600px;
  }

  > .row {
    //background-color:rgba(0,0,0,0.85);
    border-radius:3px;

    > .column, > .columns {
    }
  }

  .img-wrapper {
    width:100%;
    height:calc(100vh - 100px);
    position:relative;

    @media only screen and (max-width: 64em) {
      height:calc(100% - 200px);
      margin-top:50px;
    }


    .table {
      height:100%;

      .cell {
        height:100%;
        text-align:center;

        .slides-wrapper {
          height:100%;
          width:100%;
          overflow:hidden;

          .slides-container {
            height:100%;

            .slide {
              height:100%;
              width:100%;
              float:left;

              img {
                border:solid 5px white;
                max-width:100%;
                max-height:calc(100vh - 100px);
              }
            }
          }
          @for $i from 1 through 3
          {
            .slides-container.contains-#{$i}-slides {
              width:percentage($i);
              .slide {
                width:percentage(1 / $i);
              }
            }
          }
        }
        .slides-wrapper.touch {
          overflow-x:scroll;
          -webkit-overflow-scrolling: touch;
        }
      }
    }
    .slides-buttons {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;

      .slide-button {
        float:left;
        width:50%;
        height:100%;
        opacity:.5;

        background-position:center right;
        background-repeat:no-repeat;
        background-size: 50px 50px;
        display: inline-block;
        background-image:url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847" enable-background="new 0 0 451.846 451.847" xml:space="preserve"> <path style="fill: rgba(255,255,255,0.999);" d="M345.441,248.292L151.154,442.574c-12.359,12.363-32.397,12.363-44.75,0c-12.354-12.355-12.354-32.393,0-44.744 l171.914-171.91L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/></svg>');
        //background-color:white;
        //-webkit-box-shadow: 0 0 50px 80px #FFFFFF;
        //box-shadow: 0 0 50px 80px #FFFFFF;
        -webkit-transition: opacity 0.15s ease-in;
        -moz-transition: opacity 0.15s ease-in;
        -ms-transition: opacity 0.15s ease-in;
        -o-transition: opacity 0.15s ease-in;
        transition: opacity 0.15s ease-in;
      }
      .slide-button:nth-child(1) {
        transform: rotate(180deg);
      }
      .slide-button:active {
        opacity:1;
      }
    }
    .mobile-overlay {
      bottom:0;
      width:100%;
      position:absolute;
      padding:10px;

      h1 {
        margin-top:auto;
      }
    }
  }
  h1 {
    margin-top:30px;
    font-size:34px;
    color:white;
    text-transform: none;
    @media only screen and (max-width: 64em) {
      font-size:24px;
    }
  }
  .lightbox-close-button-wrapper {
    height:0;
    position:relative;

    a {
      position:absolute;
      right:18px;
      top:0;
      color:white;
      font-size:40px;
      z-index:105;
    }
  }
}