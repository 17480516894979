.content-icon {
  position:absolute;
  margin-left:-60px;
  background-repeat:no-repeat;
  background-size:contain;
}
.content-icon#contact {
  background-image:url('/img/icons/mail-orange.svg');
  width:41px;
  height:30px;
  margin-left:-59.5px;
}
.content-icon#about {
  background-image:url('/img/icons/text-paper.svg');
  width:42px;
  height:53px;
  margin-left:-59px;
}
.content-icon#skills {
  background-image:url('/img/icons/skills.svg');
  width:41px;
  height:60px;
  margin-left:-59.5px;
}
.content-icon#projects {
  background-image:url('/img/icons/projects.svg');
  width:43px;
  height:37px;
  margin-left:-58.5px;
}
.content-icon#account {
  background-image:url('/img/icons/account.svg');
  $width: 46px * 3 / 2;
  width:$width;
  height:57px;
  margin-left:(38px + $width / 2) * -1;
}
.content-icon#company {
  background-image:url('/img/icons/zakelijk-oranje.svg');
  width:57.333px;
  height:49.3333px;
  margin-left:(38px + 57.333px / 2) * -1;
}
.content-icon#reviews {
  background-image:url('/img/icons/reviews.svg');
  width:43px;
  height:47px;
  margin-left:(38px + 43px / 2) * -1;
}
.content-icon#messages {
  background-image:url('/img/icons/berichten.svg');
  width: 50px;
  height: 45px;
}