.search-bar-wrapper {
  height:65px;
  width:100vw;
  background-color:#E5E5E5;
  top:49px;
  position:fixed;
  left:0;
  z-index:10;
  overflow:hidden;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in;

  form {
    > .row, > .row > .column > .row, > .row > .columns > .row {
      line-height:62px;

      > .column, > .columns {
        padding-top:0;
        padding-bottom:0;
        display:inline-block;
        input, select {
          margin:0;
        }
        input[data-ajax-autocomplete-compare=identifier] {
          top:0 !important;
          width:80%;
        }
        input:nth-child(2) {
          background-color:white !important;
        }

      }
      .table {
        height:65px;
      }
    }
    .button {
      height:40px;
      padding-top:0;
      padding-bottom:0;
      display:inline-block;
      margin:0;
    }
    .button.for-mobile {
      width:40px;
      padding:0;
    }

  }

}
@media only screen and (max-width: 64em) {
  .search-bar-wrapper.has-focus {
    height:130px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
  }
}