.scroll-screen-wrapper {
  width:100%;
  overflow:hidden;
}
@for $i from 1 through 10 {
  .scroll-screen-wrapper.contains-#{$i}-screens {
    .scroll-screen-container {
      width:percentage(1*$i);

      .scroll-screen {
        width:percentage(1/$i);
        float:left;
      }
    }
  }
}
.scroll-screen-progress-wrapper {
  width:100%;
  height:5px;

  .scroll-screen-progress {
    height:100%;
    background-color:#0088ff;
    transition:width 0.5s ease-in;
  }
}
@for $i from 0 through 100 {
  .scroll-screen-progress-wrapper[data-progress="#{$i}"] .scroll-screen-progress {
    width:percentage($i/100);
  }
}