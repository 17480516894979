.hover-options-wrapper {
  background-color:rgba(0,0,0,0.5);
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  -webkit-transform: translate(0,50%) scale(1,0.001);
  -moz-transform: translate(0,50%) scale(1,0.001);
  -ms-transform: translate(0,50%) scale(1,0.001);
  -o-transform: translate(0,50%) scale(1,.001);
  transform: translate(0,50%) scale(1,0.001);
  transition:transform 0.25s ease-in;

  .table {
    height:100%;

    .cell {
      text-align:center;
    }
  }
  a {
    color:white;
    -webkit-transform: translate(0,20px);
    -moz-transform: translate(0,20px);
    -ms-transform: translate(0,20px);
    -o-transform: translate(0,20px);
    transform: translate(0,20px);
    display:inline-block;
    opacity:0;
    transition:all 0.25s ease-in;
    -webkit-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    transition-delay: 0.25s;
    padding-left:5px;
    padding-right:5px;
  }
  a.delete::after {
    content: "\00d7";
    font-size:40px;
  }
  a.camera {
    background-image:url('/img/icons/camera.svg');
    background-size:cover;
    background-repeat:no-repeat;
    display: inline-block;
    height:40px;
    width:40px;
  }
  a.default {
    position: relative;
  }
  a.default::after {
    font-family: "Arial", sans-serif;
    font-weight: bold;
    position: absolute;
    content: "L";
    font-size: 26px;
    padding-top: 4px;
    margin-top: -40px;
    -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-35deg);
  }
  a.default[data-is-default="1"] {
    color: #00AA00;
  }
}
.hover-options-wrapper.show {
  -webkit-transform: scale(1,1) translate(0,0);
  -moz-transform: scale(1,1) translate(0,0);
  -ms-transform: scale(1,1) translate(0,0);
  -o-transform: scale(1,1) translate(0,0);
  transform: scale(1,1) translate(0,0);

  a {
    opacity:1;
    -webkit-transform: translate(0,0);
    -moz-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
  }
}