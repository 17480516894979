@charset "UTF-8";
body {
  background-color: #e1e1e1;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #414141; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #414141; }

h1.gray,
h2.gray,
h3.gray,
h4.gray,
h5.gray,
h6.gray
.relative-wrapper {
  color: #949494; }

.debug {
  background-color: white;
  min-height: 35px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999; }

.table {
  display: table;
  min-height: inherit;
  width: 100%;
  left: 0; }
  .table .cell {
    display: table-cell;
    vertical-align: middle; }

.wide-wrapper {
  min-height: 0;
  width: 100vw; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wide-wrapper {
      /*
    min-height:calc(100vh - 75px - 7.5px);
    height:calc(100vh - 75px - 7.5px);
    */ } }
  .wide-wrapper h1,
  .wide-wrapper h2,
  .wide-wrapper h3,
  .wide-wrapper h4,
  .wide-wrapper h5,
  .wide-wrapper h6 {
    font-weight: 300; }
  .wide-wrapper h1 {
    font-size: 1.6em; }
    @media screen and (min-width: 40em) {
      .wide-wrapper h1 {
        font-size: 3em; } }
  .wide-wrapper h6 {
    line-height: 20px; }
  .wide-wrapper .table {
    max-width: 100%; }

.wide-wrapper.spaced {
  padding-top: 100px;
  padding-bottom: 100px; }

.wide-wrapper.blue {
  background-color: #339fff;
  color: white; }
  .wide-wrapper.blue h1,
  .wide-wrapper.blue h2,
  .wide-wrapper.blue h3,
  .wide-wrapper.blue h4,
  .wide-wrapper.blue h5,
  .wide-wrapper.blue h6,
  .wide-wrapper.blue a {
    color: white; }

.wide-wrapper.dark {
  background-color: #343434;
  color: white; }
  .wide-wrapper.dark h1,
  .wide-wrapper.dark h2,
  .wide-wrapper.dark h3,
  .wide-wrapper.dark h4,
  .wide-wrapper.dark h5,
  .wide-wrapper.dark h6,
  .wide-wrapper.dark a {
    color: white; }

.wide-wrapper.white {
  background-color: #FFF;
  color: #949494; }
  .wide-wrapper.white h1,
  .wide-wrapper.white h2,
  .wide-wrapper.white h3,
  .wide-wrapper.white h4,
  .wide-wrapper.white h5,
  .wide-wrapper.white h6 {
    color: #949494; }

ul.no-list {
  margin: 0;
  list-style: none; }

.bold {
  font-weight: bold; }

.help-tooltip.tooltip-questionmark::before {
  content: "?"; }

.help-tooltip.tooltip-questionmark {
  height: 16px;
  width: 16px;
  line-height: 14px;
  font-size: 90%;
  background-color: #0088ff;
  display: inline-block;
  position: absolute;
  margin-top: -5px;
  margin-left: 5px;
  text-align: center;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  border: solid 1px #339fff;
  font-weight: 600; }

.design-by {
  margin: 10px; }

.gray-text {
  color: #949494; }

.dropdown-arrow {
  display: inline-block;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2810, 10, 10%29'></polygon></svg>");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 9px 6px;
  margin-left: 5px;
  width: 9px;
  height: 6px; }

.footer-wrapper {
  padding-top: 100px; }

.social-button .fb-share-button span {
  height: 26.5px !important; }

.button {
  border-radius: 2px;
  font-size: 16px;
  margin: 0;
  transition: all 0.15s ease-in;
  margin-bottom: 1rem; }

.button.primary:not(.alt), .button:not(.secondary) {
  background-color: #00a3ff;
  -webkit-box-shadow: inset 0 -10px 10px 0 #0088ff;
  box-shadow: inset 0 -10px 10px 0 #0088ff;
  border: solid 1px #0088FF;
  color: #FFFFFF; }

.button.primary:not(.alt):hover {
  background-color: #0092e5;
  -webkit-box-shadow: inset 0 -10px 10px 0 #007ae5;
  box-shadow: inset 0 -10px 10px 0 #007ae5; }

.button.primary.alt, button.alt:not(.secondary) {
  background-color: #FF9000;
  -webkit-box-shadow: inset 0 -10px 10px 0 #F76B1C;
  box-shadow: inset 0 -10px 10px 0 #F76B1C;
  border: solid 1px #FF9000;
  color: #FFFFFF; }

.button.primary.alt:hover {
  background-color: #e58100;
  -webkit-box-shadow: inset 0 -10px 10px 0 #de6019;
  box-shadow: inset 0 -10px 10px 0 #de6019; }

.button.secondary {
  background-color: #F4F4F4;
  -webkit-box-shadow: inset 0 -10px 10px 0 #DDDDDD;
  box-shadow: inset 0 -10px 10px 0 #DDDDDD;
  border: solid 1px #DCDCDC; }

.button.secondary:hover {
  background-color: #ececec;
  -webkit-box-shadow: inset 0 -10px 10px 0 #d6d6d6;
  box-shadow: inset 0 -10px 10px 0 #d6d6d6; }

.button.secondary:not(.alt) {
  color: #0088FF; }

.button.secondary.alt {
  color: #F76B1C; }

.alert button {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0;
  color: rgba(10, 10, 10, 0.25); }

.alert button:hover {
  color: rgba(10, 10, 10, 0.5); }

.logo {
  height: 580px;
  width: 580px;
  max-width: 90vw;
  max-height: 90vw; }
  .logo .logo-pupil {
    height: 87px;
    width: 87px;
    margin-left: -43.5px;
    margin-top: -43.5px;
    background-size: 87px 87px; }

.logo.medium {
  height: 300px;
  width: 300px; }
  .logo.medium .logo-pupil {
    height: 45px;
    width: 45px;
    margin-left: -22.5px;
    margin-top: -22.5px;
    background-size: 45px 45px; }

.logo.small {
  height: 50px;
  width: 60px; }
  .logo.small .logo-pupil {
    height: 9px;
    width: 9px;
    margin-left: -4.5px;
    margin-top: -4.5px;
    background-size: 9px 9px; }

.logo {
  display: inline-block;
  position: relative; }
  .logo > div {
    position: absolute; }
  .logo .logo-foreground {
    background-image: url("https://bullseye.nl/img/logo-foreground-01.svg");
    height: inherit;
    width: inherit;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 3; }
  .logo .logo-pupil-wrapper {
    width: 25%;
    height: 25%;
    left: 50%;
    margin-left: -13%;
    top: 50%;
    margin-top: -5%;
    z-index: 2;
    overflow: hidden; }
    .logo .logo-pupil-wrapper .logo-pupil {
      background-image: url("https://bullseye.nl/img/pupil.svg");
      position: absolute;
      left: 60%;
      top: 65%; }
  .logo .logo-background {
    background-image: url("https://bullseye.nl/img/logo-background-01.svg");
    height: inherit;
    width: inherit;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1; }

.static-logo {
  display: inline-block;
  position: relative;
  background-image: url("https://bullseye.nl/img/logo-horns-no-b.svg");
  width: 15vw;
  height: 7.5vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

@media screen and (max-width: 63.9375em) and (orientation: landscape) {
  .static-logo {
    width: 30vw;
    height: 15vw; } }

@media screen and (max-width: 63.9375em) and (orientation: portrait) {
  .static-logo {
    width: 50vw;
    height: 25vw; } }

.top-bar-wrapper {
  position: fixed;
  height: 45px;
  width: 100vw;
  z-index: 11;
  top: 0; }
  .top-bar-wrapper a {
    color: white; }
  .top-bar-wrapper .relative-wrapper > div {
    width: 100vw;
    position: absolute;
    top: 0; }
  .top-bar-wrapper .top-bar-background {
    height: 49.5px;
    background-color: #0088ff;
    border-bottom: solid 4px #339fff;
    z-index: -1; }
  .top-bar-wrapper .top-bar-left-wrapper, .top-bar-wrapper .top-bar-right-wrapper {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s; }

.horizontal-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }
  .horizontal-menu li {
    float: right;
    line-height: 45px;
    font-size: 15px;
    font-weight: 400;
    padding: 0 20px;
    position: relative; }
    .horizontal-menu li > ul {
      position: fixed;
      display: none;
      opacity: 0;
      transition: all 0.25s ease-in;
      list-style: none;
      margin: 0 0 0 -10px;
      width: inherit;
      z-index: 11;
      top: 0;
      background-color: #0088ff;
      border-radius: 2px;
      padding: 5px;
      /* Rectangle 11: */
      box-shadow: 0 4px 0 0 #339FFF, 0 0 4px 0 #414141; }
      .horizontal-menu li > ul li {
        float: none;
        line-height: 35px;
        min-width: 10px;
        width: 100%;
        text-align: left;
        padding: 0;
        padding-right: 10px;
        border-top: solid 1px #339FFF;
        padding-left: 5px; }
        .horizontal-menu li > ul li a {
          display: block; }
      .horizontal-menu li > ul li:nth-last-child(1) {
        -webkit-border-radius: 0 0 2px 2px;
        border-radius: 0 0 2px 2px; }
      .horizontal-menu li > ul li:nth-child(1) {
        border: none;
        -webkit-border-radius: 2px 2px 0 0;
        border-radius: 2px 2px 0 0; }
      .horizontal-menu li > ul li:hover {
        background-color: rgba(255, 255, 255, 0.5); }
  .horizontal-menu > li:nth-child(1) {
    float: left;
    padding: 0; }
  .horizontal-menu > li:hover > ul {
    display: block;
    opacity: 1; }
  .horizontal-menu > li.has-sub::after {
    position: absolute;
    width: 9px;
    height: 6px;
    top: 19px;
    right: 0;
    display: inline-block;
    content: "\00a0";
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
    background-size: 9px 6px;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity .25s ease-in; }
  .horizontal-menu > li.has-sub:not(:hover)::after {
    opacity: 1; }

.hamburger-button-wrapper {
  position: fixed;
  right: 25px;
  top: 0;
  z-index: 12;
  margin-top: -5px; }

.hamburger-button {
  display: inline-block;
  width: 55px;
  padding-left: 15px;
  padding-bottom: 15px; }

.hamburger-wrapper {
  display: inline-block;
  height: 25px;
  width: 25px;
  margin-right: 25px;
  margin-top: 10px;
  transition: margin .15s ease-in;
  position: relative; }
  .hamburger-wrapper .hamburger-line {
    height: 4px;
    width: 25px;
    background-color: white;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    transition: all 0.8s;
    position: absolute;
    opacity: 1;
    margin-left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  .hamburger-wrapper .hamburger-line:nth-child(1) {
    top: 4.375px; }
  .hamburger-wrapper .hamburger-line:nth-child(2) {
    top: 14.875px; }
  .hamburger-wrapper .hamburger-line:nth-child(3) {
    top: 25.375px; }

.hamburger-wrapper.close {
  margin-top: 0; }
  .hamburger-wrapper.close .hamburger-button {
    margin-top: 0; }
  .hamburger-wrapper.close .hamburger-line:nth-child(2) {
    opacity: 0;
    margin-left: -25px; }
  .hamburger-wrapper.close .hamburger-line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 25px; }
  .hamburger-wrapper.close .hamburger-line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px; }

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 11;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  transition: all 0.8s;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 300; }
  .mobile-menu ul {
    margin: 0;
    margin-top: 49.5px;
    text-align: right;
    list-style: none; }
    .mobile-menu ul li {
      width: 100vw;
      min-height: 50px;
      padding-right: 50px; }
      .mobile-menu ul li a {
        color: white; }
    .mobile-menu ul li.has-sub > ul {
      margin: 0;
      height: 0;
      overflow: hidden;
      -webkit-transition: height 0.5s ease-in;
      -moz-transition: height 0.5s ease-in;
      -ms-transition: height 0.5s ease-in;
      -o-transition: height 0.5s ease-in;
      transition: height 0.5s ease-in; }
      .mobile-menu ul li.has-sub > ul li {
        font-size: 20px;
        line-height: 40px;
        min-height: 10px;
        text-align: right;
        padding-right: 85px; }
      .mobile-menu ul li.has-sub > ul li:nth-child(1) {
        display: none; }
    .mobile-menu ul li.has-sub.open ul {
      display: block; }
    .mobile-menu ul li.has-sub > a::after {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
      background-size: 12px 8px;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-transition: transform 0.5s ease-in;
      -moz-transition: transform 0.5s ease-in;
      -ms-transition: transform 0.5s ease-in;
      -o-transition: transform 0.5s ease-in;
      transition: transform 0.5s ease-in;
      display: inline-block; }
    .mobile-menu ul > li > a::after {
      content: "\00a0\00a0\00a0\00a0"; }
    .mobile-menu ul li.has-sub.open > a::after {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg); }

.mobile-menu.shown {
  opacity: 1; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999; }

form label {
  margin-bottom: -5px;
  font-weight: bold;
  color: #545454; }
  form label sup {
    color: red; }
  form label sup::before {
    content: " "; }

form input.no-margin {
  margin-top: -1rem; }

form fieldset {
  border: solid 1px rgba(0, 0, 0, 0.25);
  padding: 0 25px; }
  form fieldset legend {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    font-weight: bold; }

.spacer {
  height: 15px; }

p.comment {
  font-size: 80%;
  line-height: 110%;
  font-style: oblique;
  margin: 0;
  color: #565656; }

.hidden-by-overflow-wrapper {
  overflow: hidden;
  width: 100%;
  height: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

/*Special select2 css*/
.select2-container .select2-selection--single, .select2-container .select2-selection--multiple {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%28138, 138, 138%29"></polygon></svg>');
  background-origin: content-box;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  border: 1px solid #cacaca;
  border-radius: 2px;
  color: #0a0a0a;
  font-size: 1rem;
  line-height: normal;
  margin: 0 0 1rem;
  padding: 0.5rem; }
  .select2-container .select2-selection--single .select2-selection__rendered, .select2-container .select2-selection--multiple .select2-selection__rendered {
    margin-top: -0.25rem;
    margin-left: -0.25rem; }
  .select2-container .select2-selection--single .select2-selection__arrow, .select2-container .select2-selection--multiple .select2-selection__arrow {
    display: none; }

.select2-container .select2-selection--single {
  height: 2.4375rem; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #CACACA; }

.select2-dropdown {
  margin-top: -1rem; }

/*Password view icon settings*/
.password-wrapper {
  position: relative; }
  .password-wrapper .view-icon {
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    color: #AAA;
    cursor: pointer; }
  .password-wrapper .view-icon.shown {
    color: #555; }

input.inline-input {
  display: inline-block;
  width: auto;
  text-transform: lowercase;
  font-size: inherit;
  padding-top: 6px; }

input.add-icon {
  background-image: url("/img/icons/plus-sign.svg");
  background-position: 95% center;
  background-repeat: no-repeat;
  max-width: 250px;
  padding-right: 35px; }

span.error {
  font-size: 10px;
  color: red;
  font-style: italic;
  display: none; }

body.home {
  overflow-x: hidden; }
  body.home h1, body.home h2, body.home h3, body.home h4 {
    text-transform: none; }
  body.home .big-image:nth-child(1) {
    background-image: url("/img/bg.jpg"); }
    body.home .big-image:nth-child(1) h1 {
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      color: white; }
      @media only screen and (max-width: 40em) {
        body.home .big-image:nth-child(1) h1 {
          font-size: 3em; } }
  body.home .big-image#screen-2 {
    background-image: url("/img/badkamer.jpg"); }
  body.home .big-image#screen-3 {
    background-image: url("/img/project.jpg"); }
  body.home .big-image#screen-4 {
    background-image: url("/img/vrouw.jpg");
    color: white;
    overflow: hidden; }
    body.home .big-image#screen-4 h2 {
      color: white;
      line-height: 1.2em; }
  body.home .big-image {
    background-size: cover;
    background-position: center center;
    height: calc(100vh);
    font-size: 16px;
    position: relative; }
    body.home .big-image .table {
      top: 0;
      width: 100vw;
      height: inherit; }
    body.home .big-image > .row {
      height: 100%; }
    body.home .big-image .homepage-box {
      display: inline-block;
      min-height: 200px;
      background-color: white;
      width: 100%;
      padding: 40px; }
    body.home .big-image h1 {
      font-size: 4.3em;
      line-height: 1.2em; }
    body.home .big-image h2 {
      font-size: 2.25em; }
    body.home .big-image h3 {
      font-size: 1.55625em;
      color: #414141;
      line-height: 1.2em; }
  @media screen and (max-width: 63.9375em) and (orientation: landscape) {
    body.home .big-image {
      height: 100vh; } }
  @media screen and (max-width: 63.9375em) and (orientation: portrait) {
    body.home .big-image h1 {
      font-size: 4em; }
    body.home .big-image h2 {
      font-size: 1.8em; }
    body.home .big-image h3 {
      font-size: 1.245em; } }
  body.home .big-image#screen-1 {
    margin-top: 113px;
    height: calc(100vh - 113px); }
  body.home .wide-wrapper.full {
    min-height: 50vh; }
    body.home .wide-wrapper.full > .table {
      top: 0;
      width: 100vw;
      height: 20px; }
      body.home .wide-wrapper.full > .table > .cell > .row {
        padding: 50px;
        color: #414141; }
        body.home .wide-wrapper.full > .table > .cell > .row h2 {
          line-height: 1.2em;
          font-weight: 400; }
          @media only screen and (max-width: 40em) {
            body.home .wide-wrapper.full > .table > .cell > .row h2 {
              font-size: 2em; } }
        body.home .wide-wrapper.full > .table > .cell > .row h4 {
          font-weight: 400; }
  body.home .wide-wrapper.full.dark > .table > .cell > .row {
    color: white; }
    body.home .wide-wrapper.full.dark > .table > .cell > .row h2 {
      color: white; }
    body.home .wide-wrapper.full.dark > .table > .cell > .row h4 {
      color: #949494; }
  body.home .wide-wrapper.full.grey, body.home .wide-wrapper.grey {
    background-color: white; }
  body.home .zzp-images {
    margin-bottom: 10px; }
  body.home .small-wide-wrapper {
    margin: 0;
    min-height: auto; }

.floating-iframe-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: calc((100vw / 1600 * -415) + (100vw / 1600 * 38));
  margin-top: calc(100vw / 1600 * 70);
  width: calc(100vw / 1600 * 415);
  height: calc(100vw / 1600 * 350); }
  @media screen and (max-width: 1600px) and (orientation: landscape) {
    .floating-iframe-wrapper {
      margin-left: -397;
      margin-top: 70px;
      width: 415px;
      height: 350px; } }
  @media screen and (orientation: portrait) {
    .floating-iframe-wrapper {
      margin-left: calc((100vh / 1067 * -415) + (100vh / 1067 * 38));
      margin-top: calc(100vh / 1067 * 70);
      width: calc(100vh / 1067 * 415);
      height: calc(100vh / 1067 * 350); } }
  .floating-iframe-wrapper iframe {
    pointer-events: auto;
    transform: matrix3d(0.103918, -0.251872, 0, 0.000141121, 0.436207, 0.220914, 0, -0.0000072314, 0, 0, 1, 0, 133, 281, 0, 1);
    transform-origin: 0px 0px 0px;
    width: calc(100% / 665 * 768);
    height: calc(100% / 560 * 1025); }
  @media screen and (width: 2560px) {
    .floating-iframe-wrapper iframe {
      display: block; } }
  .floating-iframe-wrapper .box {
    height: 1024px;
    width: 768px;
    background-color: red;
    opacity: 0.5;
    pointer-events: auto;
    transform: matrix3d(0.103918, -0.251872, 0, 0.000141121, 0.436207, 0.220914, 0, -0.0000072314, 0, 0, 1, 0, 133, 281, 0, 1);
    transform-origin: 0px 0px 0px; }

body:not(.home) h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px; }

body:not(.home) .wide-wrapper {
  width: 100%; }

body:not(.home) .middle {
  padding-top: 115px; }

body:not(.home) .breadcrumbs {
  margin-top: 1rem;
  font-size: 14px; }

body:not(.home) .sidebar {
  float: left; }
  body:not(.home) .sidebar .sidebar-box {
    background-color: white;
    margin-top: 25px; }
    body:not(.home) .sidebar .sidebar-box h3 a {
      text-decoration: underline; }
    body:not(.home) .sidebar .sidebar-box p {
      color: #545454;
      line-height: 14px;
      font-size: 85%; }
    body:not(.home) .sidebar .sidebar-box hr {
      margin: 0 0; }
  body:not(.home) .sidebar .sidebar-box:nth-child(1) {
    margin-top: 0; }

body:not(.home) .main-content {
  background-color: white;
  margin-bottom: 25px; }
  body:not(.home) .main-content .row.with-padding > .column, body:not(.home) .main-content .row.with-padding > .columns {
    padding: 25px; }

body:not(.home) .map-frame {
  width: 100%;
  height: 250px;
  margin-bottom: -10px; }

body:not(.home) .small-info {
  font-size: 90%; }

body:not(.home) table.specs-list tbody {
  border: none;
  font-size: 14px; }
  body:not(.home) table.specs-list tbody tr {
    background-color: white; }
    body:not(.home) table.specs-list tbody tr th {
      text-align: left;
      color: #a4a4a4; }
    body:not(.home) table.specs-list tbody tr td, body:not(.home) table.specs-list tbody tr th {
      padding: 0; }

.page-icon-wrapper {
  width: 0;
  height: 0;
  position: relative; }

.page-icon {
  width: 55px;
  height: 55px;
  position: absolute;
  left: -72.5px; }

.page-icon#job-icon {
  background-image: url("/img/icons/zakelijk 2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.arrow-icon::before {
  width: 20px;
  height: 20px;
  content: "\00a0";
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847" enable-background="new 0 0 451.846 451.847" xml:space="preserve"> <path style="fill: rgba(51,159,255,0.999);" d="M345.441,248.292L151.154,442.574c-12.359,12.363-32.397,12.363-44.75,0c-12.354-12.355-12.354-32.393,0-44.744 l171.914-171.91L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/></svg>'); }

.info-icon-wrapper {
  font-size: 12px; }
  .info-icon-wrapper > .row {
    margin-bottom: 20px; }
  .info-icon-wrapper span {
    display: block;
    text-align: center; }
  .info-icon-wrapper span.key {
    font-weight: bold;
    color: #a4a4a4; }
  .info-icon-wrapper .info-icon {
    width: 100%;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
  .info-icon-wrapper .info-icon.money {
    background-image: url("/img/icons/geld.svg"); }
  .info-icon-wrapper .info-icon.time {
    background-image: url("/img/icons/tijd.svg"); }
  .info-icon-wrapper .info-icon.calendar {
    background-image: url("/img/icons/cirkel-kalender.svg"); }
  .info-icon-wrapper .info-icon.category {
    background-image: url("/img/icons/cirkel-categorie.svg"); }
  .info-icon-wrapper .info-icon.location {
    background-image: url("/img/icons/cirkel-locatie.svg"); }
  .info-icon-wrapper .info-icon.comments {
    background-image: url("/img/icons/cirkel-reacties.svg"); }
  .info-icon-wrapper .info-icon.views {
    background-image: url("/img/icons/cirkel-oog.svg"); }

.image-upload-wrapper {
  width: 232px;
  height: 232px;
  color: #949494;
  margin-bottom: 0.9rem;
  display: inline-block; }
  .image-upload-wrapper .table {
    height: 100%;
    /* Rectangle 137: */
    background-color: #F8F8F8;
    background-image: url("/img/icons/big-upload-icon.svg");
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    font-size: 24px; }
    .image-upload-wrapper .table .cell:not(.multiple):not(.dz-started) {
      cursor: pointer; }
    .image-upload-wrapper .table .cell {
      text-align: center;
      vertical-align: bottom;
      padding-bottom: 25px; }
    .image-upload-wrapper .table .cell.dz-started {
      position: relative; }
      .image-upload-wrapper .table .cell.dz-started .dz-preview {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
        .image-upload-wrapper .table .cell.dz-started .dz-preview > :not(.dz-image) {
          display: none; }
        .image-upload-wrapper .table .cell.dz-started .dz-preview > .dz-image {
          border: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center; }
          .image-upload-wrapper .table .cell.dz-started .dz-preview > .dz-image > img {
            border: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%; }

.uploaded-picture-wrapper {
  display: none; }

.uploaded-picture-wrapper, .saved-picture-wrapper {
  text-align: center; }
  .uploaded-picture-wrapper .uploaded-picture, .uploaded-picture-wrapper .saved-picture, .saved-picture-wrapper .uploaded-picture, .saved-picture-wrapper .saved-picture {
    background-size: cover;
    background-position: center center;
    height: 232px;
    width: 232px;
    border: 1px solid #dedede;
    border-radius: 2px;
    display: inline-block; }
  .uploaded-picture-wrapper .uploaded-picture.fi-page-pdf::before, .uploaded-picture-wrapper .saved-picture.fi-page-pdf::before, .saved-picture-wrapper .uploaded-picture.fi-page-pdf::before, .saved-picture-wrapper .saved-picture.fi-page-pdf::before {
    line-height: 232px;
    font-size: 200px; }

body:not(.home) .search-screen-wrapper {
  /*
    .search-filters-wrapper {
      background-color:white;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      transition: all 0.8s;

    }
    */ }
  body:not(.home) .search-screen-wrapper .filters-button-wrapper {
    position: fixed;
    right: 0;
    top: 130px; }
  body:not(.home) .search-screen-wrapper .filters-and-results-wrapper {
    overflow-x: hidden;
    width: 100%;
    min-height: 25vh; }
    body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container {
      width: 100%; }
      body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container > * {
        float: left; }
      body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper {
        width: 20%; }
        body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container {
          background-color: white;
          padding: 25px 0.9375rem; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container h2 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 15px;
            line-height: 16px; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter {
            padding-bottom: 15px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter input {
              margin: 0; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter label {
              font-weight: normal; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter input.search-filters {
              margin-top: 15px;
              margin-bottom: 5px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter h3 {
              font-size: 14px;
              font-weight: bold;
              text-transform: none; }
            @media screen and (max-width: 63.9375em) {
              body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .rating {
                font-size: 26px; } }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper {
              overflow-y: hidden;
              -webkit-transition: all 1s;
              -moz-transition: all 1s;
              -ms-transition: all 1s;
              -o-transition: all 1s;
              transition: all 1s; }
              body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li {
                display: none;
                font-size: 0.875rem; }
              body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li:nth-child(1), body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li:nth-child(2), body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li:nth-child(3), body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li:nth-child(4) {
                display: block; }
              body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter .list-wrapper ul li.no-match-filter {
                display: none; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper .filters-container .filter:nth-last-child(1) {
            padding-bottom: 0; }
      body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper {
        width: 75%;
        float: right;
        background-color: white; }
        body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header {
          padding: 25px;
          padding-bottom: 0; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header label {
            line-height: 2.4375rem; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .chosen-filters-wrapper label {
            display: inline; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .chosen-filters-wrapper a.remove-filter .chosen-filter-wrapper {
            line-height: calc(2.4375rem - 1px);
            height: 2.2375rem;
            border: solid 1px #545454;
            background-color: #0088ff;
            color: white;
            border-radius: 6px;
            padding-left: 5px;
            padding-right: 5px;
            display: inline-block;
            -webkit-box-shadow: 2px 2px 0 0 #b4b4b4;
            box-shadow: 2px 2px 0 0 #b4b4b4;
            font-size: 120%;
            margin-right: 5px;
            margin-bottom: 10px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .chosen-filters-wrapper a.remove-filter .chosen-filter-wrapper span.cross {
              color: white;
              text-decoration: underline;
              font-size: 120%;
              font-weight: 300;
              -webkit-transition: all 0.25s;
              -moz-transition: all 0.25s;
              -ms-transition: all 0.25s;
              -o-transition: all 0.25s;
              transition: all 0.25s; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .chosen-filters-wrapper a.remove-filter:hover .chosen-filter-wrapper span.cross {
            color: #ff9393; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .results-counter-wrapper {
            position: relative;
            color: white;
            font-weight: bold;
            height: 50px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .before-results-header .results-counter-wrapper .results-counter {
              position: absolute;
              min-width: 150%;
              background-color: #c2c2c2;
              padding-left: 15px;
              padding-right: 15px;
              padding-top: 8px;
              padding-bottom: 8px; }
        body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .divider-wrapper {
          padding: 0 25px; }
        body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper:nth-child(even) {
          background-color: #f7f7f7; }
        body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper {
          color: #737373;
          font-size: 14px;
          padding: 20px 25px;
          min-height: 160px; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper img.profile-picture, body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper img.job-picture {
            height: 100px;
            width: 100px;
            margin: 10px; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper h3 a {
            line-height: 20px;
            font-size: 22px;
            font-weight: bold;
            text-decoration: underline;
            -webkit-transition: all 0.25s;
            -moz-transition: all 0.25s;
            -ms-transition: all 0.25s;
            -o-transition: all 0.25s;
            transition: all 0.25s; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper h3 a:hover {
            color: #339fff; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper .location-info {
            margin-top: 22px;
            line-height: 16px; }
          body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper .statistics-info {
            font-size: 80%; }
          @media screen and (max-width: 39.9375em) {
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper h3 {
              margin-top: 10px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper .location-info {
              margin-top: 15px;
              margin-left: 10px; }
            body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper .results-container .result-wrapper .statistics-info {
              margin-left: 10px; } }
  @media screen and (max-width: 63.9375em) {
    body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container {
      width: 160vw; }
      body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .filters-wrapper {
        width: 57vw;
        margin-right: 3vw;
        margin-left: -60vw;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s; }
      body:not(.home) .search-screen-wrapper .filters-and-results-wrapper .filters-and-results-container .results-wrapper {
        width: 100vw;
        float: left; }
    body:not(.home) .search-screen-wrapper .filters-and-results-wrapper.open .filters-and-results-container .filters-wrapper {
      margin-left: 0; } }
  body:not(.home) .search-screen-wrapper .busy-loading {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height .5s ease-in; }
    body:not(.home) .search-screen-wrapper .busy-loading p {
      line-height: 50px; }
  body:not(.home) .search-screen-wrapper .busy-loading.show {
    height: 50px; }

.search-bar-wrapper {
  height: 65px;
  width: 100vw;
  background-color: #E5E5E5;
  top: 49px;
  position: fixed;
  left: 0;
  z-index: 10;
  overflow: hidden;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in; }
  .search-bar-wrapper form > .row, .search-bar-wrapper form > .row > .column > .row, .search-bar-wrapper form > .row > .columns > .row {
    line-height: 62px; }
    .search-bar-wrapper form > .row > .column, .search-bar-wrapper form > .row > .columns, .search-bar-wrapper form > .row > .column > .row > .column, .search-bar-wrapper form > .row > .column > .row > .columns, .search-bar-wrapper form > .row > .columns > .row > .column, .search-bar-wrapper form > .row > .columns > .row > .columns {
      padding-top: 0;
      padding-bottom: 0;
      display: inline-block; }
      .search-bar-wrapper form > .row > .column input, .search-bar-wrapper form > .row > .column select, .search-bar-wrapper form > .row > .columns input, .search-bar-wrapper form > .row > .columns select, .search-bar-wrapper form > .row > .column > .row > .column input, .search-bar-wrapper form > .row > .column > .row > .column select, .search-bar-wrapper form > .row > .column > .row > .columns input, .search-bar-wrapper form > .row > .column > .row > .columns select, .search-bar-wrapper form > .row > .columns > .row > .column input, .search-bar-wrapper form > .row > .columns > .row > .column select, .search-bar-wrapper form > .row > .columns > .row > .columns input, .search-bar-wrapper form > .row > .columns > .row > .columns select {
        margin: 0; }
      .search-bar-wrapper form > .row > .column input[data-ajax-autocomplete-compare=identifier], .search-bar-wrapper form > .row > .columns input[data-ajax-autocomplete-compare=identifier], .search-bar-wrapper form > .row > .column > .row > .column input[data-ajax-autocomplete-compare=identifier], .search-bar-wrapper form > .row > .column > .row > .columns input[data-ajax-autocomplete-compare=identifier], .search-bar-wrapper form > .row > .columns > .row > .column input[data-ajax-autocomplete-compare=identifier], .search-bar-wrapper form > .row > .columns > .row > .columns input[data-ajax-autocomplete-compare=identifier] {
        top: 0 !important;
        width: 80%; }
      .search-bar-wrapper form > .row > .column input:nth-child(2), .search-bar-wrapper form > .row > .columns input:nth-child(2), .search-bar-wrapper form > .row > .column > .row > .column input:nth-child(2), .search-bar-wrapper form > .row > .column > .row > .columns input:nth-child(2), .search-bar-wrapper form > .row > .columns > .row > .column input:nth-child(2), .search-bar-wrapper form > .row > .columns > .row > .columns input:nth-child(2) {
        background-color: white !important; }
    .search-bar-wrapper form > .row .table, .search-bar-wrapper form > .row > .column > .row .table, .search-bar-wrapper form > .row > .columns > .row .table {
      height: 65px; }
  .search-bar-wrapper form .button {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-block;
    margin: 0; }
  .search-bar-wrapper form .button.for-mobile {
    width: 40px;
    padding: 0; }

@media only screen and (max-width: 64em) {
  .search-bar-wrapper.has-focus {
    height: 130px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5); } }

.rating.rating-0 .star-1, .rating.rating-0 .star-2, .rating.rating-0 .star-3, .rating.rating-0 .star-4, .rating.rating-0 .star-5 {
  color: #CCC; }

.rating.rating-1 .star-1 {
  color: #FF9000; }

.rating.rating-1 .star-2, .rating.rating-1 .star-3, .rating.rating-1 .star-4, .rating.rating-1 .star-5 {
  color: #CCC; }

.rating.rating-2 .star-1, .rating.rating-2 .star-2 {
  color: #FF9000; }

.rating.rating-2 .star-3, .rating.rating-2 .star-4, .rating.rating-2 .star-5 {
  color: #CCC; }

.rating.rating-3 .star-1, .rating.rating-3 .star-2, .rating.rating-3 .star-3 {
  color: #FF9000; }

.rating.rating-3 .star-4, .rating.rating-3 .star-5 {
  color: #CCC; }

.rating.rating-4 .star-1, .rating.rating-4 .star-2, .rating.rating-4 .star-3, .rating.rating-4 .star-4 {
  color: #FF9000; }

.rating.rating-4 .star-5 {
  color: #CCC; }

.rating.rating-5 .star-1, .rating.rating-5 .star-2, .rating.rating-5 .star-3, .rating.rating-5 .star-4, .rating.rating-5 .star-5 {
  color: #FF9000; }

body.profile_view .cover-photo-wrapper {
  position: absolute;
  top: 115px;
  width: 100vw;
  height: 385px;
  left: 0;
  z-index: -1; }
  @media only screen and (max-width: 64em) {
    body.profile_view .cover-photo-wrapper {
      height: 555px; } }
  body.profile_view .cover-photo-wrapper .cover-photo {
    height: inherit;
    width: inherit;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }

body.profile_view .global-full-page-wrapper {
  background-color: transparent; }

body.profile_view .profile-wrapper {
  width: 100%;
  background-color: transparent;
  padding-top: 65px; }
  body.profile_view .profile-wrapper .profile-container .profile-heading {
    min-height: 254px; }
    body.profile_view .profile-wrapper .profile-container .profile-heading > *:not(.clearfix) {
      float: left;
      min-height: inherit;
      padding: 30px; }
    body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left {
      background-color: rgba(245, 245, 245, 0.92);
      min-height: inherit;
      width: 66%; }
      body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left > * {
        float: left; }
      body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-picture-wrapper {
        width: 193px;
        height: 193px;
        margin-right: 25px; }
        body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-picture-wrapper img {
          width: inherit;
          height: inherit; }
      body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-main-data-wrapper {
        min-height: 193px;
        width: calc(100% - 193px - 25px); }
        body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-main-data-wrapper p {
          margin-bottom: 0; }
    body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-right {
      background-color: rgba(255, 255, 255, 0.92);
      width: 34%;
      font-size: 18px; }
      body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-right .contact-data-wrapper {
        height: 193px; }
    @media only screen and (max-width: 64em) {
      body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left {
        width: 100%;
        text-align: center;
        padding-bottom: 0; }
        body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-picture-wrapper, body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-main-data-wrapper {
          margin: 0;
          display: inline-block;
          float: none;
          clear: both;
          width: 100%; }
          body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-picture-wrapper img, body.profile_view .profile-wrapper .profile-container .profile-heading .profile-heading-left .profile-main-data-wrapper img {
            width: 193px; } }
    body.profile_view .profile-wrapper .profile-container .profile-heading .table {
      height: 100%; }
  body.profile_view .profile-wrapper .profile-container .profile-content-wrapper {
    min-height: 400px;
    background-color: white; }
    body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .crumbs {
      padding: 0 30px; }
    body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .column .profile-content-container, body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .columns .profile-content-container {
      position: relative;
      padding: 0 80px;
      width: 100%; }
      @media only screen and (max-width: 40em) {
        body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .column .profile-content-container, body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .columns .profile-content-container {
          padding: 0 30px; } }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .column .profile-content-container select, body.profile_view .profile-wrapper .profile-container .profile-content-wrapper > .profile-content > .row > .columns .profile-content-container select {
        width: 100%; }
    body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .new-project-wrapper {
      width: 100%;
      height: 25vh;
      color: #999;
      font-weight: 600;
      background-color: #F6F6F6;
      cursor: pointer; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .new-project-wrapper a {
        color: inherit; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .new-project-wrapper img {
        width: 25%; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .new-project-wrapper .table {
        height: 100%; }
    body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .start-adding-projects-wrapper {
      background-color: #F6F6F6;
      padding: 25px;
      margin-bottom: 75px; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .start-adding-projects-wrapper img {
        width: 125px;
        margin-bottom: 20px; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .start-adding-projects-wrapper h3 {
        font-size: 36px; }
      body.profile_view .profile-wrapper .profile-container .profile-content-wrapper .start-adding-projects-wrapper p.large-text {
        font-size: 1.3em; }

body.profile_view .reviews-wrapper .reviews-listing-wrapper > div, body.profile_view .review-preview .reviews-listing-wrapper > div {
  padding-bottom: 15px;
  border-bottom: solid 1px #DCDCDC;
  margin-bottom: 50px; }

body.profile_view .reviews-wrapper .rating-summary .bar, body.profile_view .review-preview .rating-summary .bar {
  height: 10px;
  display: inline-block;
  background-color: #CCC;
  width: 100%;
  padding: 0;
  line-height: 10px;
  position: relative; }
  body.profile_view .reviews-wrapper .rating-summary .bar .bar-fill, body.profile_view .review-preview .rating-summary .bar .bar-fill {
    height: inherit;
    display: inline-block;
    background-color: #0088FF;
    line-height: 10px;
    position: absolute; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-0 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-0 .bar-fill {
  width: 0%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-1 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-1 .bar-fill {
  width: 1%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-2 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-2 .bar-fill {
  width: 2%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-3 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-3 .bar-fill {
  width: 3%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-4 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-4 .bar-fill {
  width: 4%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-5 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-5 .bar-fill {
  width: 5%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-6 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-6 .bar-fill {
  width: 6%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-7 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-7 .bar-fill {
  width: 7%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-8 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-8 .bar-fill {
  width: 8%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-9 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-9 .bar-fill {
  width: 9%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-10 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-10 .bar-fill {
  width: 10%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-11 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-11 .bar-fill {
  width: 11%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-12 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-12 .bar-fill {
  width: 12%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-13 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-13 .bar-fill {
  width: 13%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-14 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-14 .bar-fill {
  width: 14%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-15 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-15 .bar-fill {
  width: 15%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-16 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-16 .bar-fill {
  width: 16%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-17 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-17 .bar-fill {
  width: 17%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-18 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-18 .bar-fill {
  width: 18%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-19 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-19 .bar-fill {
  width: 19%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-20 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-20 .bar-fill {
  width: 20%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-21 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-21 .bar-fill {
  width: 21%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-22 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-22 .bar-fill {
  width: 22%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-23 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-23 .bar-fill {
  width: 23%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-24 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-24 .bar-fill {
  width: 24%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-25 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-25 .bar-fill {
  width: 25%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-26 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-26 .bar-fill {
  width: 26%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-27 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-27 .bar-fill {
  width: 27%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-28 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-28 .bar-fill {
  width: 28%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-29 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-29 .bar-fill {
  width: 29%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-30 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-30 .bar-fill {
  width: 30%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-31 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-31 .bar-fill {
  width: 31%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-32 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-32 .bar-fill {
  width: 32%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-33 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-33 .bar-fill {
  width: 33%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-34 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-34 .bar-fill {
  width: 34%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-35 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-35 .bar-fill {
  width: 35%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-36 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-36 .bar-fill {
  width: 36%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-37 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-37 .bar-fill {
  width: 37%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-38 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-38 .bar-fill {
  width: 38%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-39 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-39 .bar-fill {
  width: 39%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-40 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-40 .bar-fill {
  width: 40%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-41 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-41 .bar-fill {
  width: 41%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-42 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-42 .bar-fill {
  width: 42%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-43 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-43 .bar-fill {
  width: 43%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-44 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-44 .bar-fill {
  width: 44%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-45 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-45 .bar-fill {
  width: 45%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-46 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-46 .bar-fill {
  width: 46%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-47 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-47 .bar-fill {
  width: 47%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-48 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-48 .bar-fill {
  width: 48%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-49 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-49 .bar-fill {
  width: 49%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-50 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-50 .bar-fill {
  width: 50%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-51 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-51 .bar-fill {
  width: 51%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-52 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-52 .bar-fill {
  width: 52%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-53 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-53 .bar-fill {
  width: 53%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-54 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-54 .bar-fill {
  width: 54%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-55 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-55 .bar-fill {
  width: 55%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-56 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-56 .bar-fill {
  width: 56%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-57 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-57 .bar-fill {
  width: 57%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-58 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-58 .bar-fill {
  width: 58%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-59 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-59 .bar-fill {
  width: 59%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-60 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-60 .bar-fill {
  width: 60%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-61 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-61 .bar-fill {
  width: 61%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-62 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-62 .bar-fill {
  width: 62%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-63 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-63 .bar-fill {
  width: 63%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-64 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-64 .bar-fill {
  width: 64%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-65 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-65 .bar-fill {
  width: 65%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-66 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-66 .bar-fill {
  width: 66%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-67 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-67 .bar-fill {
  width: 67%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-68 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-68 .bar-fill {
  width: 68%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-69 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-69 .bar-fill {
  width: 69%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-70 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-70 .bar-fill {
  width: 70%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-71 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-71 .bar-fill {
  width: 71%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-72 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-72 .bar-fill {
  width: 72%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-73 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-73 .bar-fill {
  width: 73%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-74 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-74 .bar-fill {
  width: 74%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-75 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-75 .bar-fill {
  width: 75%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-76 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-76 .bar-fill {
  width: 76%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-77 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-77 .bar-fill {
  width: 77%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-78 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-78 .bar-fill {
  width: 78%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-79 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-79 .bar-fill {
  width: 79%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-80 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-80 .bar-fill {
  width: 80%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-81 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-81 .bar-fill {
  width: 81%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-82 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-82 .bar-fill {
  width: 82%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-83 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-83 .bar-fill {
  width: 83%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-84 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-84 .bar-fill {
  width: 84%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-85 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-85 .bar-fill {
  width: 85%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-86 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-86 .bar-fill {
  width: 86%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-87 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-87 .bar-fill {
  width: 87%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-88 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-88 .bar-fill {
  width: 88%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-89 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-89 .bar-fill {
  width: 89%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-90 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-90 .bar-fill {
  width: 90%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-91 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-91 .bar-fill {
  width: 91%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-92 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-92 .bar-fill {
  width: 92%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-93 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-93 .bar-fill {
  width: 93%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-94 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-94 .bar-fill {
  width: 94%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-95 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-95 .bar-fill {
  width: 95%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-96 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-96 .bar-fill {
  width: 96%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-97 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-97 .bar-fill {
  width: 97%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-98 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-98 .bar-fill {
  width: 98%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-99 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-99 .bar-fill {
  width: 99%; }

body.profile_view .reviews-wrapper .rating-summary .bar.filled-100 .bar-fill, body.profile_view .review-preview .rating-summary .bar.filled-100 .bar-fill {
  width: 100%; }

body.profile_view .reviews-wrapper .rating-summary *, body.profile_view .review-preview .rating-summary * {
  line-height: 25px; }

body.profile_view .reviews-wrapper .rating-summary .count-number, body.profile_view .review-preview .rating-summary .count-number {
  font-size: 10px;
  color: #999; }

body.profile_view .reviews-wrapper [data-state=closed], body.profile_view .review-preview [data-state=closed] {
  display: none; }

body.profile_view .reviews-wrapper .review-parts-wrapper, body.profile_view .review-preview .review-parts-wrapper {
  padding-top: 1px;
  margin-bottom: 25px;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in; }
  body.profile_view .reviews-wrapper .review-parts-wrapper .review-parts-container, body.profile_view .review-preview .review-parts-wrapper .review-parts-container {
    margin-top: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #F6F6F6;
    position: relative; }
    body.profile_view .reviews-wrapper .review-parts-wrapper .review-parts-container .arrow-up-wrapper::before, body.profile_view .review-preview .review-parts-wrapper .review-parts-container .arrow-up-wrapper::before {
      content: "Verberg beoordelingen per onderdeel ";
      font-size: 0.875rem;
      font-weight: bold;
      visibility: hidden;
      line-height: 0;
      overflow: hidden;
      height: 0; }
    body.profile_view .reviews-wrapper .review-parts-wrapper .review-parts-container .arrow-up, body.profile_view .review-preview .review-parts-wrapper .review-parts-container .arrow-up {
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='32,24 0,24 16,0' style='fill: rgb%28246, 246, 246%29'></polygon></svg>");
      position: absolute;
      background-repeat: no-repeat;
      background-origin: content-box;
      background-size: 9px 6px;
      margin-left: 8.5px;
      width: 9px;
      height: 6px;
      margin-top: -6px; }
    body.profile_view .reviews-wrapper .review-parts-wrapper .review-parts-container .review-parts, body.profile_view .review-preview .review-parts-wrapper .review-parts-container .review-parts {
      padding: 0 15px 25px 25px; }

body.profile_view .reviews-wrapper .review-parts-wrapper.closed, body.profile_view .review-preview .review-parts-wrapper.closed {
  overflow: hidden;
  height: 0; }

body.profile_view .respond-button::before {
  background-image: url("/img/icons/respond-button.svg");
  background-size: contain;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: center center;
  content: "\00a0\00a0";
  width: 13px;
  height: 20px;
  display: inline-block; }

body.profile_view .respond-button {
  cursor: pointer; }

body.profile_view .respond-button:hover {
  text-decoration: underline; }

body.profile_view .votes-wrapper {
  font-size: 14px;
  color: #949494; }

body.profile_view .response-input-wrapper {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in; }

body.profile_view .review-answer {
  background-color: #F6F6F6;
  border: solid 1px #DCDCDC;
  padding: 10px; }

.project-lightbox-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100; }

.project-lightbox-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  padding: 50px 0;
  max-width: 1200px;
  color: white;
  text-shadow: 1px 1px 0 #212121; }
  @media screen and (min-width: 1200px) {
    .project-lightbox-wrapper {
      left: 50%;
      margin-left: -600px; } }
  .project-lightbox-wrapper > .row {
    border-radius: 3px; }
  .project-lightbox-wrapper .img-wrapper {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative; }
    @media only screen and (max-width: 64em) {
      .project-lightbox-wrapper .img-wrapper {
        height: calc(100% - 200px);
        margin-top: 50px; } }
    .project-lightbox-wrapper .img-wrapper .table {
      height: 100%; }
      .project-lightbox-wrapper .img-wrapper .table .cell {
        height: 100%;
        text-align: center; }
        .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper {
          height: 100%;
          width: 100%;
          overflow: hidden; }
          .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container {
            height: 100%; }
            .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container .slide {
              height: 100%;
              width: 100%;
              float: left; }
              .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container .slide img {
                border: solid 5px white;
                max-width: 100%;
                max-height: calc(100vh - 100px); }
          .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-1-slides {
            width: 100%; }
            .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-1-slides .slide {
              width: 100%; }
          .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-2-slides {
            width: 200%; }
            .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-2-slides .slide {
              width: 50%; }
          .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-3-slides {
            width: 300%; }
            .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper .slides-container.contains-3-slides .slide {
              width: 33.3333333333%; }
        .project-lightbox-wrapper .img-wrapper .table .cell .slides-wrapper.touch {
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch; }
    .project-lightbox-wrapper .img-wrapper .slides-buttons {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .project-lightbox-wrapper .img-wrapper .slides-buttons .slide-button {
        float: left;
        width: 50%;
        height: 100%;
        opacity: .5;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 50px 50px;
        display: inline-block;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847" enable-background="new 0 0 451.846 451.847" xml:space="preserve"> <path style="fill: rgba(255,255,255,0.999);" d="M345.441,248.292L151.154,442.574c-12.359,12.363-32.397,12.363-44.75,0c-12.354-12.355-12.354-32.393,0-44.744 l171.914-171.91L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/></svg>');
        -webkit-transition: opacity 0.15s ease-in;
        -moz-transition: opacity 0.15s ease-in;
        -ms-transition: opacity 0.15s ease-in;
        -o-transition: opacity 0.15s ease-in;
        transition: opacity 0.15s ease-in; }
      .project-lightbox-wrapper .img-wrapper .slides-buttons .slide-button:nth-child(1) {
        transform: rotate(180deg); }
      .project-lightbox-wrapper .img-wrapper .slides-buttons .slide-button:active {
        opacity: 1; }
    .project-lightbox-wrapper .img-wrapper .mobile-overlay {
      bottom: 0;
      width: 100%;
      position: absolute;
      padding: 10px; }
      .project-lightbox-wrapper .img-wrapper .mobile-overlay h1 {
        margin-top: auto; }
  .project-lightbox-wrapper h1 {
    margin-top: 30px;
    font-size: 34px;
    color: white;
    text-transform: none; }
    @media only screen and (max-width: 64em) {
      .project-lightbox-wrapper h1 {
        font-size: 24px; } }
  .project-lightbox-wrapper .lightbox-close-button-wrapper {
    height: 0;
    position: relative; }
    .project-lightbox-wrapper .lightbox-close-button-wrapper a {
      position: absolute;
      right: 18px;
      top: 0;
      color: white;
      font-size: 40px;
      z-index: 105; }

.hover-options-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 50%) scale(1, 0.001);
  -moz-transform: translate(0, 50%) scale(1, 0.001);
  -ms-transform: translate(0, 50%) scale(1, 0.001);
  -o-transform: translate(0, 50%) scale(1, 0.001);
  transform: translate(0, 50%) scale(1, 0.001);
  transition: transform 0.25s ease-in; }
  .hover-options-wrapper .table {
    height: 100%; }
    .hover-options-wrapper .table .cell {
      text-align: center; }
  .hover-options-wrapper a {
    color: white;
    -webkit-transform: translate(0, 20px);
    -moz-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    display: inline-block;
    opacity: 0;
    transition: all 0.25s ease-in;
    -webkit-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
    -o-transition-delay: 0.25s;
    transition-delay: 0.25s;
    padding-left: 5px;
    padding-right: 5px; }
  .hover-options-wrapper a.delete::after {
    content: "\00d7";
    font-size: 40px; }
  .hover-options-wrapper a.camera {
    background-image: url("/img/icons/camera.svg");
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    height: 40px;
    width: 40px; }
  .hover-options-wrapper a.default {
    position: relative; }
  .hover-options-wrapper a.default::after {
    font-family: "Arial", sans-serif;
    font-weight: bold;
    position: absolute;
    content: "L";
    font-size: 26px;
    padding-top: 4px;
    margin-top: -40px;
    -ms-transform: scaleX(-1) rotate(-35deg);
    /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-35deg);
    /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-35deg); }
  .hover-options-wrapper a.default[data-is-default="1"] {
    color: #00AA00; }

.hover-options-wrapper.show {
  -webkit-transform: scale(1, 1) translate(0, 0);
  -moz-transform: scale(1, 1) translate(0, 0);
  -ms-transform: scale(1, 1) translate(0, 0);
  -o-transform: scale(1, 1) translate(0, 0);
  transform: scale(1, 1) translate(0, 0); }
  .hover-options-wrapper.show a {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }

.global-page-wrapper .page-wrapper {
  padding: 25px; }

.global-page-wrapper .sidebar {
  width: 20%; }
  .global-page-wrapper .sidebar .sidebar-box {
    padding: 25px 0.9375rem; }
  .global-page-wrapper .sidebar .sidebar-box.options-menu .row .column > ul {
    margin: 0 -0.9375rem; }
  .global-page-wrapper .sidebar .sidebar-box.options-menu ul {
    list-style: none; }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li a {
      line-height: 30px;
      display: block;
      padding: 0 0.9375rem; }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li > ul {
      margin: 0;
      overflow: hidden;
      height: 0; }
      .global-page-wrapper .sidebar .sidebar-box.options-menu ul li > ul li a {
        padding-left: 30px; }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li.active > ul, .global-page-wrapper .sidebar .sidebar-box.options-menu ul li:hover > ul {
      overflow: auto;
      height: auto; }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li:not(.active):hover {
      position: relative; }
      .global-page-wrapper .sidebar .sidebar-box.options-menu ul li:not(.active):hover > ul {
        position: absolute;
        background-color: white;
        width: 100%;
        margin-left: 100%;
        margin-top: -30px; }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul a:hover {
      background-color: rgba(0, 0, 0, 0.025); }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li.active > a {
      background-color: rgba(0, 136, 255, 0.1); }
    .global-page-wrapper .sidebar .sidebar-box.options-menu ul li.active > a:hover {
      background-color: rgba(0, 136, 255, 0.25); }

.global-page-wrapper .sidebar.has-button .sidebar-box:nth-child(2) {
  margin-top: 0; }

.global-page-wrapper .sidebar.sidebar-left {
  margin-right: 5%; }

.global-page-wrapper .sidebar.sidebar-right {
  margin-left: 5%; }

.global-page-wrapper .main-content {
  width: 75%;
  float: left; }

@media screen and (max-width: 63.9375em) and (min-width: 40em) {
  .global-page-wrapper .sidebar {
    width: 35%; }
  .global-page-wrapper .main-content {
    width: 60%; } }

@media screen and (max-width: 39.9375em) {
  .global-page-wrapper .sidebar, .global-page-wrapper .sidebar.sidebar-left, .global-page-wrapper .sidebar.sidebar-right {
    width: 100%;
    margin: 0;
    margin-bottom: 5%; }
  .global-page-wrapper .main-content {
    width: 100%; } }

.global-full-page-wrapper {
  background-color: white; }
  .global-full-page-wrapper .page-wrapper {
    padding: 25px; }
  .global-full-page-wrapper .main-content {
    float: none;
    width: 55%;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 63.9375em) and (min-width: 40em) {
      .global-full-page-wrapper .main-content {
        width: 90%; } }
    @media screen and (max-width: 39.9375em) {
      .global-full-page-wrapper .main-content {
        width: 100%; } }
    .global-full-page-wrapper .main-content h1 {
      font-size: 2.5em; }
    .global-full-page-wrapper .main-content h2 {
      font-size: 2em; }
    .global-full-page-wrapper .main-content h3 {
      font-size: 1.6em; }
    .global-full-page-wrapper .main-content h4 {
      font-size: 1.28em; }
    .global-full-page-wrapper .main-content h5 {
      font-size: 1.024em; }
    .global-full-page-wrapper .main-content h1, .global-full-page-wrapper .main-content h2, .global-full-page-wrapper .main-content h3, .global-full-page-wrapper .main-content h4, .global-full-page-wrapper .main-content h5 {
      line-height: normal;
      font-weight: normal;
      text-transform: none; }
    .global-full-page-wrapper .main-content .large-text {
      font-size: 1.25em; }
    .global-full-page-wrapper .main-content .small-text {
      font-size: 0.75em; }

.page-image {
  height: 200px;
  width: 100%;
  background-position: center center;
  background-size: cover; }
  .page-image h1 {
    color: white;
    text-shadow: 1px 1px 0 #414141;
    line-height: 200px;
    margin: 0; }

.profile-creator-heading-wrapper {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s; }
  .profile-creator-heading-wrapper .profile-creator-heading progress {
    width: 100%;
    border-radius: 0;
    border: none;
    height: 5px;
    background-color: #339fff; }

.profile-creator-wrapper {
  width: 100%;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s; }
  .profile-creator-wrapper .profile-creator-container {
    width: 300%; }
    .profile-creator-wrapper .profile-creator-container .profile-creator-step {
      padding-bottom: 15px;
      float: left;
      width: 33.3333333333%; }
      .profile-creator-wrapper .profile-creator-container .profile-creator-step fieldset {
        border: none;
        border-top: solid 1px #565656; }
        .profile-creator-wrapper .profile-creator-container .profile-creator-step fieldset legend {
          padding: 0 25px;
          text-transform: uppercase;
          color: #565656; }
        .profile-creator-wrapper .profile-creator-container .profile-creator-step fieldset > .row:nth-last-child(1) {
          margin-top: 25px; }

.g-recaptcha > div {
  text-align: center; }
  .g-recaptcha > div > div {
    display: inline-block; }

.register_follow_up span.account-choice-sprite {
  display: inline-block;
  height: 220px;
  width: 86px;
  background-image: url("/img/icons/account-choice-sprites.svg");
  background-repeat: no-repeat; }

.register_follow_up .fancy-radio-button {
  padding: 15px 5px;
  display: inline-block;
  width: 46%;
  float: left;
  border-radius: 3px;
  border: solid 1px #E9E9E9;
  background-color: #FAFAFA;
  margin: 0 1%;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in; }
  @media screen and (max-width: 39.9375em) {
    .register_follow_up .fancy-radio-button {
      width: 80%;
      margin-left: 10%;
      text-align: center;
      margin-bottom: 2%; } }

.register_follow_up .fancy-radio-button[data-input-value=searcher] span.account-choice-sprite {
  background-position: right center; }

.register_follow_up .fancy-radio-button[data-input-value=profile] span.account-choice-sprite {
  background-position: left center; }

.register_follow_up .fancy-radio-button:not([data-input-checked]):not(.no-choice) {
  opacity: 0.5; }

.register_follow_up .profile-form-wrapper {
  display: block; }

.register_follow_up span.big-professions-sprite {
  display: inline-block;
  height: 184px;
  width: 130px;
  background-image: url("/img/icons/big-professions-icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: 25px; }

.register_follow_up input#profession {
  z-index: 5; }

.fancy-radio-button:hover {
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25); }

body.register_follow_up > .bullseye-flash {
  transform: translateY(115px); }

body.login .middle, body.password_forgot .middle, body.password_reset .middle {
  background-image: url("../img/bg_blur.jpg");
  background-size: cover;
  background-position: center top; }
  body.login .middle > .wide-wrapper.main-content-wrapper, body.password_forgot .middle > .wide-wrapper.main-content-wrapper, body.password_reset .middle > .wide-wrapper.main-content-wrapper {
    height: calc(100vh - 200px); }
    body.login .middle > .wide-wrapper.main-content-wrapper .table, body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table, body.password_reset .middle > .wide-wrapper.main-content-wrapper .table {
      height: inherit; }
      body.login .middle > .wide-wrapper.main-content-wrapper .table .auth-box, body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table .auth-box, body.password_reset .middle > .wide-wrapper.main-content-wrapper .table .auth-box {
        width: 100%;
        background-color: white;
        min-height: 10px;
        position: relative;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        animation-name: fly-auth-box-in;
        animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards; }
        body.login .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title, body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title, body.password_reset .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title {
          margin-top: -25px;
          padding-left: 10px;
          padding-right: 10px;
          min-width: 1px;
          background-color: white;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px; }
          body.login .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title h1, body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title h1, body.password_reset .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-title h1 {
            line-height: 50px;
            font-size: 24px;
            margin: 0; }
        body.login .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content, body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content, body.password_reset .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content {
          padding: 25px;
          padding-bottom: 5px; }
          body.login .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content input[type=submit], body.password_forgot .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content input[type=submit], body.password_reset .middle > .wide-wrapper.main-content-wrapper .table .auth-box .auth-box-content input[type=submit] {
            margin-bottom: 10px; }

@keyframes fly-auth-box-in {
  from {
    margin-top: 100px;
    opacity: 0; }
  to {
    margin-top: 0;
    opacity: 1; } }

.reveal-overlay .reveal {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }
  .reveal-overlay .reveal h1 {
    line-height: 100%;
    font-size: 1.3rem; }
  .reveal-overlay .reveal p:not(.lead) {
    font-size: 14px; }

body.show_job .job-wrapper {
  color: #737373;
  padding: 40px 100px; }
  @media screen and (max-width: 63.9375em) {
    body.show_job .job-wrapper {
      padding: 25px; } }
  body.show_job .job-wrapper h1 {
    line-height: 100%;
    font-size: 28px;
    font-weight: bold;
    min-height: 56px; }

body.edit_job form.add_job_form fieldset {
  display: none; }

body.edit_job form.add_job_form fieldset:nth-of-type(1) {
  display: block; }

.new-job-wrapper h1, .new-job-wrapper h2, .new-job-wrapper h3, .new-job-wrapper h4, .new-job-wrapper h5 {
  text-transform: none; }

.new-job-wrapper .job-lightbox-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100; }

.new-job-wrapper .job-lightbox-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 101;
  position: fixed;
  left: 0;
  top: 0;
  padding: 50px 0;
  max-width: 1200px; }
  @media screen and (min-width: 1200px) {
    .new-job-wrapper .job-lightbox-wrapper {
      left: 50%;
      margin-left: -600px; } }
  .new-job-wrapper .job-lightbox-wrapper > .row {
    border-radius: 3px; }
  .new-job-wrapper .job-lightbox-wrapper .lightbox-close-button-wrapper {
    height: 0;
    position: relative; }
    .new-job-wrapper .job-lightbox-wrapper .lightbox-close-button-wrapper a {
      text-shadow: 1px 1px 0 #212121;
      position: absolute;
      right: 18px;
      top: 0;
      color: white;
      font-size: 40px;
      z-index: 105; }
  .new-job-wrapper .job-lightbox-wrapper .table {
    height: 100%; }
  .new-job-wrapper .job-lightbox-wrapper .new-job-container {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 50px 100px; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper {
      width: 100%;
      height: inherit;
      overflow: hidden; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller {
        float: left;
        padding: 0 100px; }
        .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller .job-preview {
          width: inherit;
          /* Rectangle 14: */
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
          border-radius: 2px;
          padding: 20px;
          margin-bottom: 25px; }
          .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller .job-preview .large-text {
            font-size: 1.25em; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller.big-text {
        font-size: 24px; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller:nth-child(0) {
        padding-left: 0; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller:nth-last-child(0) {
        padding-right: 0; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="1"] .new-job-scroller-container {
      width: 100%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="1"] .new-job-scroller-container .new-job-scroller {
        width: 100%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="2"] .new-job-scroller-container {
      width: 200%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="2"] .new-job-scroller-container .new-job-scroller {
        width: 50%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="3"] .new-job-scroller-container {
      width: 300%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="3"] .new-job-scroller-container .new-job-scroller {
        width: 33.3333333333%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="4"] .new-job-scroller-container {
      width: 400%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="4"] .new-job-scroller-container .new-job-scroller {
        width: 25%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="5"] .new-job-scroller-container {
      width: 500%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="5"] .new-job-scroller-container .new-job-scroller {
        width: 20%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="6"] .new-job-scroller-container {
      width: 600%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="6"] .new-job-scroller-container .new-job-scroller {
        width: 16.6666666667%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="7"] .new-job-scroller-container {
      width: 700%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="7"] .new-job-scroller-container .new-job-scroller {
        width: 14.2857142857%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="8"] .new-job-scroller-container {
      width: 800%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="8"] .new-job-scroller-container .new-job-scroller {
        width: 12.5%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="9"] .new-job-scroller-container {
      width: 900%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="9"] .new-job-scroller-container .new-job-scroller {
        width: 11.1111111111%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="10"] .new-job-scroller-container {
      width: 1000%; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper[data-contains="10"] .new-job-scroller-container .new-job-scroller {
        width: 10%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper {
      position: relative;
      height: 0;
      width: calc(100% + (100px*2)); }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper .job-progress {
        height: 5px;
        background-color: #0088FF;
        position: absolute;
        top: -50px;
        left: -100px;
        -webkit-transition: width 0.25s ease-in;
        -moz-transition: width 0.25s ease-in;
        -ms-transition: width 0.25s ease-in;
        -o-transition: width 0.25s ease-in;
        transition: width 0.25s ease-in; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="0"] .job-progress {
      width: 0%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="1"] .job-progress {
      width: 1%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="2"] .job-progress {
      width: 2%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="3"] .job-progress {
      width: 3%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="4"] .job-progress {
      width: 4%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="5"] .job-progress {
      width: 5%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="6"] .job-progress {
      width: 6%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="7"] .job-progress {
      width: 7%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="8"] .job-progress {
      width: 8%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="9"] .job-progress {
      width: 9%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="10"] .job-progress {
      width: 10%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="11"] .job-progress {
      width: 11%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="12"] .job-progress {
      width: 12%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="13"] .job-progress {
      width: 13%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="14"] .job-progress {
      width: 14%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="15"] .job-progress {
      width: 15%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="16"] .job-progress {
      width: 16%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="17"] .job-progress {
      width: 17%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="18"] .job-progress {
      width: 18%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="19"] .job-progress {
      width: 19%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="20"] .job-progress {
      width: 20%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="21"] .job-progress {
      width: 21%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="22"] .job-progress {
      width: 22%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="23"] .job-progress {
      width: 23%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="24"] .job-progress {
      width: 24%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="25"] .job-progress {
      width: 25%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="26"] .job-progress {
      width: 26%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="27"] .job-progress {
      width: 27%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="28"] .job-progress {
      width: 28%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="29"] .job-progress {
      width: 29%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="30"] .job-progress {
      width: 30%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="31"] .job-progress {
      width: 31%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="32"] .job-progress {
      width: 32%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="33"] .job-progress {
      width: 33%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="34"] .job-progress {
      width: 34%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="35"] .job-progress {
      width: 35%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="36"] .job-progress {
      width: 36%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="37"] .job-progress {
      width: 37%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="38"] .job-progress {
      width: 38%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="39"] .job-progress {
      width: 39%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="40"] .job-progress {
      width: 40%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="41"] .job-progress {
      width: 41%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="42"] .job-progress {
      width: 42%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="43"] .job-progress {
      width: 43%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="44"] .job-progress {
      width: 44%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="45"] .job-progress {
      width: 45%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="46"] .job-progress {
      width: 46%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="47"] .job-progress {
      width: 47%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="48"] .job-progress {
      width: 48%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="49"] .job-progress {
      width: 49%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="50"] .job-progress {
      width: 50%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="51"] .job-progress {
      width: 51%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="52"] .job-progress {
      width: 52%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="53"] .job-progress {
      width: 53%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="54"] .job-progress {
      width: 54%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="55"] .job-progress {
      width: 55%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="56"] .job-progress {
      width: 56%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="57"] .job-progress {
      width: 57%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="58"] .job-progress {
      width: 58%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="59"] .job-progress {
      width: 59%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="60"] .job-progress {
      width: 60%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="61"] .job-progress {
      width: 61%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="62"] .job-progress {
      width: 62%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="63"] .job-progress {
      width: 63%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="64"] .job-progress {
      width: 64%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="65"] .job-progress {
      width: 65%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="66"] .job-progress {
      width: 66%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="67"] .job-progress {
      width: 67%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="68"] .job-progress {
      width: 68%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="69"] .job-progress {
      width: 69%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="70"] .job-progress {
      width: 70%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="71"] .job-progress {
      width: 71%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="72"] .job-progress {
      width: 72%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="73"] .job-progress {
      width: 73%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="74"] .job-progress {
      width: 74%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="75"] .job-progress {
      width: 75%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="76"] .job-progress {
      width: 76%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="77"] .job-progress {
      width: 77%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="78"] .job-progress {
      width: 78%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="79"] .job-progress {
      width: 79%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="80"] .job-progress {
      width: 80%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="81"] .job-progress {
      width: 81%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="82"] .job-progress {
      width: 82%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="83"] .job-progress {
      width: 83%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="84"] .job-progress {
      width: 84%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="85"] .job-progress {
      width: 85%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="86"] .job-progress {
      width: 86%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="87"] .job-progress {
      width: 87%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="88"] .job-progress {
      width: 88%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="89"] .job-progress {
      width: 89%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="90"] .job-progress {
      width: 90%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="91"] .job-progress {
      width: 91%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="92"] .job-progress {
      width: 92%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="93"] .job-progress {
      width: 93%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="94"] .job-progress {
      width: 94%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="95"] .job-progress {
      width: 95%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="96"] .job-progress {
      width: 96%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="97"] .job-progress {
      width: 97%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="98"] .job-progress {
      width: 98%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="99"] .job-progress {
      width: 99%; }
    .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper[data-progress="100"] .job-progress {
      width: 100%; }
  @media only screen and (max-width: 64em) {
    .new-job-wrapper .job-lightbox-wrapper .new-job-container {
      padding: 50px 10px; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .new-job-scroller-wrapper .new-job-scroller-container .new-job-scroller {
        padding: 0 10px; }
      .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper {
        width: calc(100% + (10px*2)); }
        .new-job-wrapper .job-lightbox-wrapper .new-job-container .job-progress-wrapper .job-progress {
          left: -10px; } }

.main-info-wrapper {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 25px;
  font-size: 14px;
  border: dashed 2px rgba(0, 0, 0, 0.1); }
  .main-info-wrapper a.button {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 14px; }

body.jobs .result-wrapper .done-indicator {
  height: 100px;
  width: 100px;
  background-image: url("/img/job-done.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 10px;
  margin-top: 10px;
  position: absolute;
  z-index: 10; }
  body.jobs .result-wrapper .done-indicator.shown {
    display: inline-block; }
  body.jobs .result-wrapper .done-indicator:not(.shown) {
    display: none; }

body.jobs .result-wrapper .done-indicator.shown + img {
  opacity: 0.35; }

.no-margin-page-wrapper h1, .no-margin-page-wrapper h2, .no-margin-page-wrapper h3, .no-margin-page-wrapper h4, .no-margin-page-wrapper h5, .no-margin-page-wrapper h6 {
  text-transform: none; }

.no-margin-page-wrapper .main-content {
  width: 100%;
  padding: 25px 100px;
  float: left; }
  .no-margin-page-wrapper .main-content h1 {
    text-transform: none; }
  .no-margin-page-wrapper .main-content .open-sidebar-wrapper {
    position: absolute;
    font-size: 30px;
    color: #343434;
    left: 0.9375rem;
    margin-top: -0.2625rem;
    cursor: pointer; }

.no-margin-page-wrapper .grouped-menu {
  border-right: solid 1px #979797; }
  .no-margin-page-wrapper .grouped-menu ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .no-margin-page-wrapper .grouped-menu ul li {
      padding: 0;
      margin: 0; }
      .no-margin-page-wrapper .grouped-menu ul li a {
        padding: 0 0.9375rem;
        display: block;
        line-height: 40px; }
        .no-margin-page-wrapper .grouped-menu ul li a .icon {
          padding-right: 0.5rem;
          font-size: 30px;
          display: block;
          float: left;
          color: #434343; }
        .no-margin-page-wrapper .grouped-menu ul li a [class^="beye-"]:before,
        .no-margin-page-wrapper .grouped-menu ul li a [class*=" beye-"]:before {
          font-size: 20px; }
      .no-margin-page-wrapper .grouped-menu ul li a:hover {
        background-color: rgba(0, 0, 0, 0.1); }
    .no-margin-page-wrapper .grouped-menu ul li.group-header {
      border-top: solid 1px #979797; }
      .no-margin-page-wrapper .grouped-menu ul li.group-header > span {
        padding: 0 0.9375rem;
        color: #757575;
        font-weight: bold; }
      .no-margin-page-wrapper .grouped-menu ul li.group-header > ul > li > a {
        padding: 0 0.9375rem; }
    .no-margin-page-wrapper .grouped-menu ul li:not(.group-header) {
      height: 40px;
      line-height: 40px; }

.no-margin-page-wrapper .gray-background {
  background-color: rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 63.9375em) {
  .no-margin-page-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white; }
    .no-margin-page-wrapper .main-content {
      padding: 25px 100px;
      position: relative; } }
    @media only screen and (max-width: 63.9375em) and (max-width: 40em) {
      .no-margin-page-wrapper .main-content {
        padding: 25px 0px; } }

.edit-form-wrapper {
  line-height: 40px; }
  .edit-form-wrapper .field-editor {
    display: none; }
  .edit-form-wrapper .show-on-edit {
    display: none; }
  .edit-form-wrapper .is-editing {
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.25s ease-in;
    -moz-transition: background-color 0.25s ease-in;
    -ms-transition: background-color 0.25s ease-in;
    -o-transition: background-color 0.25s ease-in;
    transition: background-color 0.25s ease-in;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding-top: 0.8rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem; }
    .edit-form-wrapper .is-editing > :not(.identifier) {
      display: none; }
    .edit-form-wrapper .is-editing > .show-on-edit {
      display: block; }
    .edit-form-wrapper .is-editing .field-editor {
      display: block; }
  .edit-form-wrapper .button {
    width: 100%; }
  .edit-form-wrapper .value-wrapper {
    overflow: hidden; }

.scroll-screen-wrapper {
  width: 100%;
  overflow: hidden; }

.scroll-screen-wrapper.contains-1-screens .scroll-screen-container {
  width: 100%; }
  .scroll-screen-wrapper.contains-1-screens .scroll-screen-container .scroll-screen {
    width: 100%;
    float: left; }

.scroll-screen-wrapper.contains-2-screens .scroll-screen-container {
  width: 200%; }
  .scroll-screen-wrapper.contains-2-screens .scroll-screen-container .scroll-screen {
    width: 50%;
    float: left; }

.scroll-screen-wrapper.contains-3-screens .scroll-screen-container {
  width: 300%; }
  .scroll-screen-wrapper.contains-3-screens .scroll-screen-container .scroll-screen {
    width: 33.3333333333%;
    float: left; }

.scroll-screen-wrapper.contains-4-screens .scroll-screen-container {
  width: 400%; }
  .scroll-screen-wrapper.contains-4-screens .scroll-screen-container .scroll-screen {
    width: 25%;
    float: left; }

.scroll-screen-wrapper.contains-5-screens .scroll-screen-container {
  width: 500%; }
  .scroll-screen-wrapper.contains-5-screens .scroll-screen-container .scroll-screen {
    width: 20%;
    float: left; }

.scroll-screen-wrapper.contains-6-screens .scroll-screen-container {
  width: 600%; }
  .scroll-screen-wrapper.contains-6-screens .scroll-screen-container .scroll-screen {
    width: 16.6666666667%;
    float: left; }

.scroll-screen-wrapper.contains-7-screens .scroll-screen-container {
  width: 700%; }
  .scroll-screen-wrapper.contains-7-screens .scroll-screen-container .scroll-screen {
    width: 14.2857142857%;
    float: left; }

.scroll-screen-wrapper.contains-8-screens .scroll-screen-container {
  width: 800%; }
  .scroll-screen-wrapper.contains-8-screens .scroll-screen-container .scroll-screen {
    width: 12.5%;
    float: left; }

.scroll-screen-wrapper.contains-9-screens .scroll-screen-container {
  width: 900%; }
  .scroll-screen-wrapper.contains-9-screens .scroll-screen-container .scroll-screen {
    width: 11.1111111111%;
    float: left; }

.scroll-screen-wrapper.contains-10-screens .scroll-screen-container {
  width: 1000%; }
  .scroll-screen-wrapper.contains-10-screens .scroll-screen-container .scroll-screen {
    width: 10%;
    float: left; }

.scroll-screen-progress-wrapper {
  width: 100%;
  height: 5px; }
  .scroll-screen-progress-wrapper .scroll-screen-progress {
    height: 100%;
    background-color: #0088ff;
    transition: width 0.5s ease-in; }

.scroll-screen-progress-wrapper[data-progress="0"] .scroll-screen-progress {
  width: 0%; }

.scroll-screen-progress-wrapper[data-progress="1"] .scroll-screen-progress {
  width: 1%; }

.scroll-screen-progress-wrapper[data-progress="2"] .scroll-screen-progress {
  width: 2%; }

.scroll-screen-progress-wrapper[data-progress="3"] .scroll-screen-progress {
  width: 3%; }

.scroll-screen-progress-wrapper[data-progress="4"] .scroll-screen-progress {
  width: 4%; }

.scroll-screen-progress-wrapper[data-progress="5"] .scroll-screen-progress {
  width: 5%; }

.scroll-screen-progress-wrapper[data-progress="6"] .scroll-screen-progress {
  width: 6%; }

.scroll-screen-progress-wrapper[data-progress="7"] .scroll-screen-progress {
  width: 7%; }

.scroll-screen-progress-wrapper[data-progress="8"] .scroll-screen-progress {
  width: 8%; }

.scroll-screen-progress-wrapper[data-progress="9"] .scroll-screen-progress {
  width: 9%; }

.scroll-screen-progress-wrapper[data-progress="10"] .scroll-screen-progress {
  width: 10%; }

.scroll-screen-progress-wrapper[data-progress="11"] .scroll-screen-progress {
  width: 11%; }

.scroll-screen-progress-wrapper[data-progress="12"] .scroll-screen-progress {
  width: 12%; }

.scroll-screen-progress-wrapper[data-progress="13"] .scroll-screen-progress {
  width: 13%; }

.scroll-screen-progress-wrapper[data-progress="14"] .scroll-screen-progress {
  width: 14%; }

.scroll-screen-progress-wrapper[data-progress="15"] .scroll-screen-progress {
  width: 15%; }

.scroll-screen-progress-wrapper[data-progress="16"] .scroll-screen-progress {
  width: 16%; }

.scroll-screen-progress-wrapper[data-progress="17"] .scroll-screen-progress {
  width: 17%; }

.scroll-screen-progress-wrapper[data-progress="18"] .scroll-screen-progress {
  width: 18%; }

.scroll-screen-progress-wrapper[data-progress="19"] .scroll-screen-progress {
  width: 19%; }

.scroll-screen-progress-wrapper[data-progress="20"] .scroll-screen-progress {
  width: 20%; }

.scroll-screen-progress-wrapper[data-progress="21"] .scroll-screen-progress {
  width: 21%; }

.scroll-screen-progress-wrapper[data-progress="22"] .scroll-screen-progress {
  width: 22%; }

.scroll-screen-progress-wrapper[data-progress="23"] .scroll-screen-progress {
  width: 23%; }

.scroll-screen-progress-wrapper[data-progress="24"] .scroll-screen-progress {
  width: 24%; }

.scroll-screen-progress-wrapper[data-progress="25"] .scroll-screen-progress {
  width: 25%; }

.scroll-screen-progress-wrapper[data-progress="26"] .scroll-screen-progress {
  width: 26%; }

.scroll-screen-progress-wrapper[data-progress="27"] .scroll-screen-progress {
  width: 27%; }

.scroll-screen-progress-wrapper[data-progress="28"] .scroll-screen-progress {
  width: 28%; }

.scroll-screen-progress-wrapper[data-progress="29"] .scroll-screen-progress {
  width: 29%; }

.scroll-screen-progress-wrapper[data-progress="30"] .scroll-screen-progress {
  width: 30%; }

.scroll-screen-progress-wrapper[data-progress="31"] .scroll-screen-progress {
  width: 31%; }

.scroll-screen-progress-wrapper[data-progress="32"] .scroll-screen-progress {
  width: 32%; }

.scroll-screen-progress-wrapper[data-progress="33"] .scroll-screen-progress {
  width: 33%; }

.scroll-screen-progress-wrapper[data-progress="34"] .scroll-screen-progress {
  width: 34%; }

.scroll-screen-progress-wrapper[data-progress="35"] .scroll-screen-progress {
  width: 35%; }

.scroll-screen-progress-wrapper[data-progress="36"] .scroll-screen-progress {
  width: 36%; }

.scroll-screen-progress-wrapper[data-progress="37"] .scroll-screen-progress {
  width: 37%; }

.scroll-screen-progress-wrapper[data-progress="38"] .scroll-screen-progress {
  width: 38%; }

.scroll-screen-progress-wrapper[data-progress="39"] .scroll-screen-progress {
  width: 39%; }

.scroll-screen-progress-wrapper[data-progress="40"] .scroll-screen-progress {
  width: 40%; }

.scroll-screen-progress-wrapper[data-progress="41"] .scroll-screen-progress {
  width: 41%; }

.scroll-screen-progress-wrapper[data-progress="42"] .scroll-screen-progress {
  width: 42%; }

.scroll-screen-progress-wrapper[data-progress="43"] .scroll-screen-progress {
  width: 43%; }

.scroll-screen-progress-wrapper[data-progress="44"] .scroll-screen-progress {
  width: 44%; }

.scroll-screen-progress-wrapper[data-progress="45"] .scroll-screen-progress {
  width: 45%; }

.scroll-screen-progress-wrapper[data-progress="46"] .scroll-screen-progress {
  width: 46%; }

.scroll-screen-progress-wrapper[data-progress="47"] .scroll-screen-progress {
  width: 47%; }

.scroll-screen-progress-wrapper[data-progress="48"] .scroll-screen-progress {
  width: 48%; }

.scroll-screen-progress-wrapper[data-progress="49"] .scroll-screen-progress {
  width: 49%; }

.scroll-screen-progress-wrapper[data-progress="50"] .scroll-screen-progress {
  width: 50%; }

.scroll-screen-progress-wrapper[data-progress="51"] .scroll-screen-progress {
  width: 51%; }

.scroll-screen-progress-wrapper[data-progress="52"] .scroll-screen-progress {
  width: 52%; }

.scroll-screen-progress-wrapper[data-progress="53"] .scroll-screen-progress {
  width: 53%; }

.scroll-screen-progress-wrapper[data-progress="54"] .scroll-screen-progress {
  width: 54%; }

.scroll-screen-progress-wrapper[data-progress="55"] .scroll-screen-progress {
  width: 55%; }

.scroll-screen-progress-wrapper[data-progress="56"] .scroll-screen-progress {
  width: 56%; }

.scroll-screen-progress-wrapper[data-progress="57"] .scroll-screen-progress {
  width: 57%; }

.scroll-screen-progress-wrapper[data-progress="58"] .scroll-screen-progress {
  width: 58%; }

.scroll-screen-progress-wrapper[data-progress="59"] .scroll-screen-progress {
  width: 59%; }

.scroll-screen-progress-wrapper[data-progress="60"] .scroll-screen-progress {
  width: 60%; }

.scroll-screen-progress-wrapper[data-progress="61"] .scroll-screen-progress {
  width: 61%; }

.scroll-screen-progress-wrapper[data-progress="62"] .scroll-screen-progress {
  width: 62%; }

.scroll-screen-progress-wrapper[data-progress="63"] .scroll-screen-progress {
  width: 63%; }

.scroll-screen-progress-wrapper[data-progress="64"] .scroll-screen-progress {
  width: 64%; }

.scroll-screen-progress-wrapper[data-progress="65"] .scroll-screen-progress {
  width: 65%; }

.scroll-screen-progress-wrapper[data-progress="66"] .scroll-screen-progress {
  width: 66%; }

.scroll-screen-progress-wrapper[data-progress="67"] .scroll-screen-progress {
  width: 67%; }

.scroll-screen-progress-wrapper[data-progress="68"] .scroll-screen-progress {
  width: 68%; }

.scroll-screen-progress-wrapper[data-progress="69"] .scroll-screen-progress {
  width: 69%; }

.scroll-screen-progress-wrapper[data-progress="70"] .scroll-screen-progress {
  width: 70%; }

.scroll-screen-progress-wrapper[data-progress="71"] .scroll-screen-progress {
  width: 71%; }

.scroll-screen-progress-wrapper[data-progress="72"] .scroll-screen-progress {
  width: 72%; }

.scroll-screen-progress-wrapper[data-progress="73"] .scroll-screen-progress {
  width: 73%; }

.scroll-screen-progress-wrapper[data-progress="74"] .scroll-screen-progress {
  width: 74%; }

.scroll-screen-progress-wrapper[data-progress="75"] .scroll-screen-progress {
  width: 75%; }

.scroll-screen-progress-wrapper[data-progress="76"] .scroll-screen-progress {
  width: 76%; }

.scroll-screen-progress-wrapper[data-progress="77"] .scroll-screen-progress {
  width: 77%; }

.scroll-screen-progress-wrapper[data-progress="78"] .scroll-screen-progress {
  width: 78%; }

.scroll-screen-progress-wrapper[data-progress="79"] .scroll-screen-progress {
  width: 79%; }

.scroll-screen-progress-wrapper[data-progress="80"] .scroll-screen-progress {
  width: 80%; }

.scroll-screen-progress-wrapper[data-progress="81"] .scroll-screen-progress {
  width: 81%; }

.scroll-screen-progress-wrapper[data-progress="82"] .scroll-screen-progress {
  width: 82%; }

.scroll-screen-progress-wrapper[data-progress="83"] .scroll-screen-progress {
  width: 83%; }

.scroll-screen-progress-wrapper[data-progress="84"] .scroll-screen-progress {
  width: 84%; }

.scroll-screen-progress-wrapper[data-progress="85"] .scroll-screen-progress {
  width: 85%; }

.scroll-screen-progress-wrapper[data-progress="86"] .scroll-screen-progress {
  width: 86%; }

.scroll-screen-progress-wrapper[data-progress="87"] .scroll-screen-progress {
  width: 87%; }

.scroll-screen-progress-wrapper[data-progress="88"] .scroll-screen-progress {
  width: 88%; }

.scroll-screen-progress-wrapper[data-progress="89"] .scroll-screen-progress {
  width: 89%; }

.scroll-screen-progress-wrapper[data-progress="90"] .scroll-screen-progress {
  width: 90%; }

.scroll-screen-progress-wrapper[data-progress="91"] .scroll-screen-progress {
  width: 91%; }

.scroll-screen-progress-wrapper[data-progress="92"] .scroll-screen-progress {
  width: 92%; }

.scroll-screen-progress-wrapper[data-progress="93"] .scroll-screen-progress {
  width: 93%; }

.scroll-screen-progress-wrapper[data-progress="94"] .scroll-screen-progress {
  width: 94%; }

.scroll-screen-progress-wrapper[data-progress="95"] .scroll-screen-progress {
  width: 95%; }

.scroll-screen-progress-wrapper[data-progress="96"] .scroll-screen-progress {
  width: 96%; }

.scroll-screen-progress-wrapper[data-progress="97"] .scroll-screen-progress {
  width: 97%; }

.scroll-screen-progress-wrapper[data-progress="98"] .scroll-screen-progress {
  width: 98%; }

.scroll-screen-progress-wrapper[data-progress="99"] .scroll-screen-progress {
  width: 99%; }

.scroll-screen-progress-wrapper[data-progress="100"] .scroll-screen-progress {
  width: 100%; }

.complete-symbol {
  display: inline-block;
  margin-top: 50px;
  height: 86px;
  width: 86px;
  background-image: url("/img/icons/green-check.svg");
  background-repeat: no-repeat;
  background-position: center center; }

.wrapper {
  position: relative;
  margin: 40px auto;
  background: white; }

.show-if-complete {
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in; }

.show-if-complete:not(.show) {
  opacity: 0; }

.autocomplete-option-list-wrapper {
  height: 0;
  left: 0;
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in;
  background-color: white;
  -webkit-box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1); }
  .autocomplete-option-list-wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .autocomplete-option-list-wrapper ul li {
      min-height: 45px;
      line-height: 45px;
      cursor: pointer;
      border-bottom: solid 1px #cacaca; }
    .autocomplete-option-list-wrapper ul li:nth-last-child(1) {
      border: none; }
    .autocomplete-option-list-wrapper ul li:hover, .autocomplete-option-list-wrapper ul li.sort_of_selected {
      background-color: rgba(0, 0, 0, 0.05); }

.autocomplete-option-list-wrapper.shown {
  border: solid 1px #cacaca; }

.select-children-wrapper > ul, .tag-selector > ul {
  margin: 0;
  padding: 0; }
  .select-children-wrapper > ul > li, .tag-selector > ul > li {
    display: inline-block;
    margin-left: 7.5px;
    margin-right: 7.5px;
    position: relative; }
  .select-children-wrapper > ul > li.option, .tag-selector > ul > li.option {
    line-height: 60px; }
    .select-children-wrapper > ul > li.option span, .tag-selector > ul > li.option span {
      background: #FF9000;
      border-radius: 2px;
      padding: 9px 10px;
      font-size: 18px;
      color: #FFFFFF; }
  .select-children-wrapper > ul > li:not(.option), .tag-selector > ul > li:not(.option) {
    position: relative;
    clear: both;
    float: left; }
  .select-children-wrapper > ul > li:not(.option)::before, .tag-selector > ul > li:not(.option)::before {
    content: "\00a0"; }
  .select-children-wrapper > ul > li.option:hover::after, .tag-selector > ul > li.option:hover::after {
    background-color: #999; }

.select-children-wrapper input, .tag-selector input {
  margin: 7.5px; }

.select-children-wrapper {
  width: 100%;
  min-height: 200px;
  padding: 5px; }

.tag-selector {
  padding: 0; }
  .tag-selector > ul > li.option {
    cursor: pointer; }
    .tag-selector > ul > li.option span {
      background: #0088ff; }

.select-children-wrapper:not([readonly]), .tag-selector:not([readonly]) {
  border: solid 1px #cacaca; }
  .select-children-wrapper:not([readonly]) > li.option span, .tag-selector:not([readonly]) > li.option span {
    cursor: pointer; }
  .select-children-wrapper:not([readonly]) li.option::after, .tag-selector:not([readonly]) li.option::after {
    content: "×";
    line-height: 10px;
    cursor: pointer;
    position: absolute;
    top: 5.5px;
    margin-left: -7px;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    font-size: 13px;
    text-align: center;
    border: solid 2px white;
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    background-color: #CCC;
    color: white; }

.tag-selector:not([readonly]) {
  border: none; }
  .tag-selector:not([readonly]) > li.option {
    background-color: blue; }

.loading-indicator {
  height: 100px;
  width: 100px;
  display: inline-block;
  background-image: url("/img/icons/refresh-round-symbol.svg");
  background-size: contain;
  -webkit-animation-name: rotate-loader;
  -moz-animation-name: rotate-loader;
  -o-animation-name: rotate-loader;
  animation-name: rotate-loader;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear; }

@keyframes rotate-loader {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); } }

.logo-popup-background {
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed; }

.logo-popup-wrapper {
  z-index: 21;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh; }
  .logo-popup-wrapper .table {
    height: 100vh; }
    .logo-popup-wrapper .table .cell {
      text-align: center; }
      .logo-popup-wrapper .table .cell .logo-popup {
        display: inline-block;
        padding: 50px;
        background-color: white;
        border-radius: 11px;
        max-width: 740px; }
        @media only screen and (max-width: 40em) {
          .logo-popup-wrapper .table .cell .logo-popup {
            padding: 10px; } }
        .logo-popup-wrapper .table .cell .logo-popup h1 {
          text-transform: none; }

.content-icon {
  position: absolute;
  margin-left: -60px;
  background-repeat: no-repeat;
  background-size: contain; }

.content-icon#contact {
  background-image: url("/img/icons/mail-orange.svg");
  width: 41px;
  height: 30px;
  margin-left: -59.5px; }

.content-icon#about {
  background-image: url("/img/icons/text-paper.svg");
  width: 42px;
  height: 53px;
  margin-left: -59px; }

.content-icon#skills {
  background-image: url("/img/icons/skills.svg");
  width: 41px;
  height: 60px;
  margin-left: -59.5px; }

.content-icon#projects {
  background-image: url("/img/icons/projects.svg");
  width: 43px;
  height: 37px;
  margin-left: -58.5px; }

.content-icon#account {
  background-image: url("/img/icons/account.svg");
  width: 69px;
  height: 57px;
  margin-left: -72.5px; }

.content-icon#company {
  background-image: url("/img/icons/zakelijk-oranje.svg");
  width: 57.333px;
  height: 49.3333px;
  margin-left: -66.6665px; }

.content-icon#reviews {
  background-image: url("/img/icons/reviews.svg");
  width: 43px;
  height: 47px;
  margin-left: -59.5px; }

.content-icon#messages {
  background-image: url("/img/icons/berichten.svg");
  width: 50px;
  height: 45px; }

body.edit_user h2 {
  font-weight: 400;
  font-size: 2.5rem; }

.form-loader-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 1000;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in; }

.form-loader-overlay:not(.shown) {
  opacity: 0; }

.form-loader-overlay.shown {
  opacity: 1; }

.form-loader-overlay.shown::before {
  display: block;
  height: 200px;
  width: 200px;
  background-image: url("/img/loader-logo.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
  content: "\00a0";
  animation-name: rotate-logo;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes rotate-logo {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.new-review-wrapper .review-preview {
  width: inherit;
  /* Rectangle 14: */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 25px; }
  .new-review-wrapper .review-preview .large-text {
    font-size: 1.25em; }

.big-ass-input input:not(.original-size), .big-ass-input textarea:not(.original-size) {
  font-size: 24px;
  line-height: 30px;
  height: auto; }

.big-ass-input button:not(.original-size), .big-ass-input .button:not(.original-size) {
  font-size: 24px;
  line-height: 24px;
  height: auto; }

.google-city-search-wrapper {
  position: relative; }
  .google-city-search-wrapper > input:nth-child(1) {
    position: absolute;
    left: 0;
    background: transparent; }
  .google-city-search-wrapper input.error {
    background: lightcoral; }

.contact-box .button {
  margin: 0; }

.images-wrapper .big-image-wrapper {
  height: 300px;
  width: 100%;
  overflow: hidden;
  padding: 0; }
  .images-wrapper .big-image-wrapper .big-image-container {
    height: 100%; }
    .images-wrapper .big-image-wrapper .big-image-container .single-big-image-wrapper {
      float: left;
      text-align: center; }

.images-wrapper .big-image-wrapper[data-contains="1"] .big-image-container {
  width: 100%; }
  .images-wrapper .big-image-wrapper[data-contains="1"] .big-image-container .single-big-image-wrapper {
    width: 100%; }

.images-wrapper .big-image-wrapper[data-contains="2"] .big-image-container {
  width: 200%; }
  .images-wrapper .big-image-wrapper[data-contains="2"] .big-image-container .single-big-image-wrapper {
    width: 50%; }

.images-wrapper .big-image-wrapper[data-contains="3"] .big-image-container {
  width: 300%; }
  .images-wrapper .big-image-wrapper[data-contains="3"] .big-image-container .single-big-image-wrapper {
    width: 33.3333333333%; }

.images-wrapper .big-image-wrapper[data-contains="4"] .big-image-container {
  width: 400%; }
  .images-wrapper .big-image-wrapper[data-contains="4"] .big-image-container .single-big-image-wrapper {
    width: 25%; }

.images-wrapper .big-image-wrapper[data-contains="5"] .big-image-container {
  width: 500%; }
  .images-wrapper .big-image-wrapper[data-contains="5"] .big-image-container .single-big-image-wrapper {
    width: 20%; }

.images-wrapper .big-image-wrapper[data-contains="6"] .big-image-container {
  width: 600%; }
  .images-wrapper .big-image-wrapper[data-contains="6"] .big-image-container .single-big-image-wrapper {
    width: 16.6666666667%; }

.images-wrapper .big-image-wrapper[data-contains="7"] .big-image-container {
  width: 700%; }
  .images-wrapper .big-image-wrapper[data-contains="7"] .big-image-container .single-big-image-wrapper {
    width: 14.2857142857%; }

.images-wrapper .big-image-wrapper[data-contains="8"] .big-image-container {
  width: 800%; }
  .images-wrapper .big-image-wrapper[data-contains="8"] .big-image-container .single-big-image-wrapper {
    width: 12.5%; }

.images-wrapper .big-image-wrapper[data-contains="9"] .big-image-container {
  width: 900%; }
  .images-wrapper .big-image-wrapper[data-contains="9"] .big-image-container .single-big-image-wrapper {
    width: 11.1111111111%; }

.images-wrapper .big-image-wrapper[data-contains="10"] .big-image-container {
  width: 1000%; }
  .images-wrapper .big-image-wrapper[data-contains="10"] .big-image-container .single-big-image-wrapper {
    width: 10%; }

.images-wrapper .big-image-wrapper[data-contains="11"] .big-image-container {
  width: 1100%; }
  .images-wrapper .big-image-wrapper[data-contains="11"] .big-image-container .single-big-image-wrapper {
    width: 9.0909090909%; }

.images-wrapper .big-image-wrapper[data-contains="12"] .big-image-container {
  width: 1200%; }
  .images-wrapper .big-image-wrapper[data-contains="12"] .big-image-container .single-big-image-wrapper {
    width: 8.3333333333%; }

.images-wrapper .big-image-wrapper[data-contains="13"] .big-image-container {
  width: 1300%; }
  .images-wrapper .big-image-wrapper[data-contains="13"] .big-image-container .single-big-image-wrapper {
    width: 7.6923076923%; }

.images-wrapper .big-image-wrapper[data-contains="14"] .big-image-container {
  width: 1400%; }
  .images-wrapper .big-image-wrapper[data-contains="14"] .big-image-container .single-big-image-wrapper {
    width: 7.1428571429%; }

.images-wrapper .big-image-wrapper[data-contains="15"] .big-image-container {
  width: 1500%; }
  .images-wrapper .big-image-wrapper[data-contains="15"] .big-image-container .single-big-image-wrapper {
    width: 6.6666666667%; }

.images-wrapper .big-image-wrapper[data-contains="16"] .big-image-container {
  width: 1600%; }
  .images-wrapper .big-image-wrapper[data-contains="16"] .big-image-container .single-big-image-wrapper {
    width: 6.25%; }

.images-wrapper .big-image-wrapper[data-contains="17"] .big-image-container {
  width: 1700%; }
  .images-wrapper .big-image-wrapper[data-contains="17"] .big-image-container .single-big-image-wrapper {
    width: 5.8823529412%; }

.images-wrapper .big-image-wrapper[data-contains="18"] .big-image-container {
  width: 1800%; }
  .images-wrapper .big-image-wrapper[data-contains="18"] .big-image-container .single-big-image-wrapper {
    width: 5.5555555556%; }

.images-wrapper .big-image-wrapper[data-contains="19"] .big-image-container {
  width: 1900%; }
  .images-wrapper .big-image-wrapper[data-contains="19"] .big-image-container .single-big-image-wrapper {
    width: 5.2631578947%; }

.images-wrapper .big-image-wrapper[data-contains="20"] .big-image-container {
  width: 2000%; }
  .images-wrapper .big-image-wrapper[data-contains="20"] .big-image-container .single-big-image-wrapper {
    width: 5%; }

.images-wrapper .thumbnails-wrapper {
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }
  .images-wrapper .thumbnails-wrapper .thumbnails-container .single-thumbnail-wrapper {
    float: left;
    margin: 0.9rem; }
  .images-wrapper .thumbnails-wrapper .thumbnails-container .single-thumbnail-wrapper.active {
    -webkit-box-shadow: 0 0 5px 0 #7dc6ff;
    box-shadow: 0 0 5px 0 #7dc6ff; }

.promotion-side-wrapper {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 50vh;
  transform: translateY(-50%) translateX(4px);
  min-height: 200px;
  width: 150px;
  background-color: white;
  border: 4px solid #339fff;
  border-radius: 2px;
  transition: transform .5s ease-out; }
  .promotion-side-wrapper .open-close-wrapper {
    position: absolute;
    padding-top: 3px; }
    .promotion-side-wrapper .open-close-wrapper .arrow-icon::before {
      transition: transform .5s ease-in; }
  .promotion-side-wrapper span.title {
    color: #339fff;
    font-weight: bold;
    margin: 4px; }
  .promotion-side-wrapper ul.options {
    margin: 0;
    padding: 0;
    list-style: none; }
    .promotion-side-wrapper ul.options li {
      margin: 0;
      padding: 0;
      display: none;
      text-align: center; }
      .promotion-side-wrapper ul.options li .present-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }
  .promotion-side-wrapper span.progress-text {
    font-size: 12px;
    text-align: center; }
  .promotion-side-wrapper ul.steps {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-align: left;
    list-style: none; }
    .promotion-side-wrapper ul.steps li {
      margin: 0;
      padding: 0;
      opacity: 0.6; }
    .promotion-side-wrapper ul.steps li::before {
      display: block;
      float: left;
      background-image: url("/img/icons/green-check-no-circle.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
      content: "\00a0";
      clear: left; }
    .promotion-side-wrapper ul.steps li.done {
      opacity: 1; }
  .promotion-side-wrapper p.done {
    font-size: 12px;
    text-align: left;
    padding: 10px;
    word-break: keep-all;
    margin: 0;
    padding-bottom: 0; }
    .promotion-side-wrapper p.done .button {
      font-size: inherit;
      margin-top: 10px;
      margin-bottom: 10px; }

.promotion-side-wrapper.hidden {
  transform: translateX(100%) translateY(-50%); }

body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .phone-select-wrapper {
  animation-name: show_the_select_wrapper;
  animation-duration: .5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin-bottom: 50px; }

@keyframes show_the_select_wrapper {
  from {
    opacity: 0;
    transform: scaleY(0); }
  to {
    opacity: 1;
    transform: scaleY(1); } }

body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .present-item {
  margin: 20px;
  width: calc(100% - 40px);
  background-color: white; }
  body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .present-item > * {
    width: 100%; }
  @media only screen and (max-width: 40em) {
    body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .present-item > * {
      width: 50%;
      float: left; }
    body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .present-item > *:nth-last-child(1) {
      clear: right; } }
  body.register_follow_up .profile-form-wrapper:not([style="display: none;"]) .present-item .present-image {
    display: inline-block;
    height: 100px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }

@media only screen and (max-width: 64em) {
  .promotion-side-wrapper:not(.open):not(.hidden) {
    transform: translateY(-50%) translateX(80%); }
  .promotion-side-wrapper.open .open-close-wrapper .arrow-icon::before {
    transform: rotate(180deg); } }

@media only screen and (min-width: 64.063em) {
  .open-close-wrapper:not(.hidden) {
    display: none; } }

body.home .phone-button-wrapper {
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: #0088ff;
  border: solid 10px white;
  border-radius: 100px;
  width: 200px;
  height: 200px;
  padding: 15px;
  color: white;
  transform: rotate(8deg); }
  @media only screen and (max-width: 40em) {
    body.home .phone-button-wrapper {
      transform: rotate(8deg) scale(0.75);
      right: 0;
      top: 0; } }
  body.home .phone-button-wrapper .phone-button-content {
    height: 80%;
    width: 80%;
    margin-left: 10%;
    margin-top: 20%;
    font-size: 14px;
    font-weight: bold;
    text-align: center; }

.login-first-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px #C1B8A4;
  background-color: rgba(255, 243, 217, 0.95);
  padding: 10px; }
  .login-first-wrapper .login-first-container {
    padding: 10px;
    background-color: #FFF;
    position: absolute;
    width: calc(100% - 10px - 10px);
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); }

.row.error-wrapper {
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform .5s ease;
  color: red !important; }
  .row.error-wrapper .column {
    font-style: italic; }
  .row.error-wrapper.shown {
    transform: scaleY(1); }

body.secret_map .top-bar-wrapper, body.secret_map .search-bar-wrapper, body.secret_map .hamburger-button-wrapper, body.secret_map .promotion-side-wrapper {
  display: none; }

body.secret_map .middle {
  padding-top: 0; }
  body.secret_map .middle > :not(:nth-child(1)) {
    display: none; }

body.secret_map .map-wrapper {
  width: 100vw;
  height: 100vh; }
  body.secret_map .map-wrapper #chartdiv {
    width: 100%;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.complete-job-wrapper h1, .complete-job-wrapper h2, .complete-job-wrapper h3, .complete-job-wrapper h4, .complete-job-wrapper h5 {
  text-transform: none; }

.complete-job-wrapper .complete-job-lightbox-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100; }

.complete-job-wrapper .complete-job-lightbox-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 101;
  position: absolute;
  left: 0;
  top: 0;
  padding: 50px 0;
  max-width: 1200px; }
  @media screen and (min-width: 1200px) {
    .complete-job-wrapper .complete-job-lightbox-wrapper {
      left: 50%;
      margin-left: -600px; } }
  .complete-job-wrapper .complete-job-lightbox-wrapper > .row {
    border-radius: 3px; }
  .complete-job-wrapper .complete-job-lightbox-wrapper .lightbox-close-button-wrapper {
    height: 0;
    position: relative; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .lightbox-close-button-wrapper a {
      text-shadow: 1px 1px 0 #212121;
      position: absolute;
      right: 18px;
      top: 0;
      color: rgba(255, 255, 255, 0.6);
      font-size: 40px;
      z-index: 105; }
  .complete-job-wrapper .complete-job-lightbox-wrapper .table {
    height: 100%; }
  .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 50px 100px; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper {
      width: 100%;
      height: inherit;
      overflow: hidden; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper .complete-job-scroller-container .complete-job-scroller {
        float: left;
        padding: 0 100px; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper .complete-job-scroller-container .complete-job-scroller.big-text {
        font-size: 24px; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper .complete-job-scroller-container .complete-job-scroller:nth-child(0) {
        padding-left: 0; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper .complete-job-scroller-container .complete-job-scroller:nth-last-child(0) {
        padding-right: 0; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="1"] .complete-job-scroller-container {
      width: 100%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="1"] .complete-job-scroller-container .complete-job-scroller {
        width: 100%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="2"] .complete-job-scroller-container {
      width: 200%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="2"] .complete-job-scroller-container .complete-job-scroller {
        width: 50%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="3"] .complete-job-scroller-container {
      width: 300%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="3"] .complete-job-scroller-container .complete-job-scroller {
        width: 33.3333333333%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="4"] .complete-job-scroller-container {
      width: 400%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="4"] .complete-job-scroller-container .complete-job-scroller {
        width: 25%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="5"] .complete-job-scroller-container {
      width: 500%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="5"] .complete-job-scroller-container .complete-job-scroller {
        width: 20%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="6"] .complete-job-scroller-container {
      width: 600%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="6"] .complete-job-scroller-container .complete-job-scroller {
        width: 16.6666666667%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="7"] .complete-job-scroller-container {
      width: 700%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="7"] .complete-job-scroller-container .complete-job-scroller {
        width: 14.2857142857%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="8"] .complete-job-scroller-container {
      width: 800%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="8"] .complete-job-scroller-container .complete-job-scroller {
        width: 12.5%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="9"] .complete-job-scroller-container {
      width: 900%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="9"] .complete-job-scroller-container .complete-job-scroller {
        width: 11.1111111111%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="10"] .complete-job-scroller-container {
      width: 1000%; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper[data-contains="10"] .complete-job-scroller-container .complete-job-scroller {
        width: 10%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper {
      position: relative;
      height: 0;
      width: calc(100% + (100px*2)); }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper .complete-job-progress {
        height: 5px;
        background-color: #0088FF;
        position: absolute;
        top: -50px;
        left: -100px;
        -webkit-transition: width 0.25s ease-in;
        -moz-transition: width 0.25s ease-in;
        -ms-transition: width 0.25s ease-in;
        -o-transition: width 0.25s ease-in;
        transition: width 0.25s ease-in; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="0"] .complete-job-progress {
      width: 0%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="1"] .complete-job-progress {
      width: 1%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="2"] .complete-job-progress {
      width: 2%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="3"] .complete-job-progress {
      width: 3%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="4"] .complete-job-progress {
      width: 4%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="5"] .complete-job-progress {
      width: 5%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="6"] .complete-job-progress {
      width: 6%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="7"] .complete-job-progress {
      width: 7%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="8"] .complete-job-progress {
      width: 8%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="9"] .complete-job-progress {
      width: 9%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="10"] .complete-job-progress {
      width: 10%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="11"] .complete-job-progress {
      width: 11%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="12"] .complete-job-progress {
      width: 12%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="13"] .complete-job-progress {
      width: 13%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="14"] .complete-job-progress {
      width: 14%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="15"] .complete-job-progress {
      width: 15%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="16"] .complete-job-progress {
      width: 16%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="17"] .complete-job-progress {
      width: 17%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="18"] .complete-job-progress {
      width: 18%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="19"] .complete-job-progress {
      width: 19%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="20"] .complete-job-progress {
      width: 20%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="21"] .complete-job-progress {
      width: 21%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="22"] .complete-job-progress {
      width: 22%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="23"] .complete-job-progress {
      width: 23%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="24"] .complete-job-progress {
      width: 24%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="25"] .complete-job-progress {
      width: 25%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="26"] .complete-job-progress {
      width: 26%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="27"] .complete-job-progress {
      width: 27%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="28"] .complete-job-progress {
      width: 28%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="29"] .complete-job-progress {
      width: 29%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="30"] .complete-job-progress {
      width: 30%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="31"] .complete-job-progress {
      width: 31%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="32"] .complete-job-progress {
      width: 32%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="33"] .complete-job-progress {
      width: 33%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="34"] .complete-job-progress {
      width: 34%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="35"] .complete-job-progress {
      width: 35%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="36"] .complete-job-progress {
      width: 36%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="37"] .complete-job-progress {
      width: 37%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="38"] .complete-job-progress {
      width: 38%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="39"] .complete-job-progress {
      width: 39%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="40"] .complete-job-progress {
      width: 40%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="41"] .complete-job-progress {
      width: 41%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="42"] .complete-job-progress {
      width: 42%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="43"] .complete-job-progress {
      width: 43%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="44"] .complete-job-progress {
      width: 44%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="45"] .complete-job-progress {
      width: 45%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="46"] .complete-job-progress {
      width: 46%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="47"] .complete-job-progress {
      width: 47%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="48"] .complete-job-progress {
      width: 48%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="49"] .complete-job-progress {
      width: 49%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="50"] .complete-job-progress {
      width: 50%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="51"] .complete-job-progress {
      width: 51%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="52"] .complete-job-progress {
      width: 52%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="53"] .complete-job-progress {
      width: 53%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="54"] .complete-job-progress {
      width: 54%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="55"] .complete-job-progress {
      width: 55%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="56"] .complete-job-progress {
      width: 56%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="57"] .complete-job-progress {
      width: 57%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="58"] .complete-job-progress {
      width: 58%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="59"] .complete-job-progress {
      width: 59%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="60"] .complete-job-progress {
      width: 60%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="61"] .complete-job-progress {
      width: 61%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="62"] .complete-job-progress {
      width: 62%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="63"] .complete-job-progress {
      width: 63%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="64"] .complete-job-progress {
      width: 64%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="65"] .complete-job-progress {
      width: 65%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="66"] .complete-job-progress {
      width: 66%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="67"] .complete-job-progress {
      width: 67%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="68"] .complete-job-progress {
      width: 68%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="69"] .complete-job-progress {
      width: 69%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="70"] .complete-job-progress {
      width: 70%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="71"] .complete-job-progress {
      width: 71%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="72"] .complete-job-progress {
      width: 72%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="73"] .complete-job-progress {
      width: 73%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="74"] .complete-job-progress {
      width: 74%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="75"] .complete-job-progress {
      width: 75%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="76"] .complete-job-progress {
      width: 76%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="77"] .complete-job-progress {
      width: 77%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="78"] .complete-job-progress {
      width: 78%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="79"] .complete-job-progress {
      width: 79%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="80"] .complete-job-progress {
      width: 80%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="81"] .complete-job-progress {
      width: 81%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="82"] .complete-job-progress {
      width: 82%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="83"] .complete-job-progress {
      width: 83%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="84"] .complete-job-progress {
      width: 84%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="85"] .complete-job-progress {
      width: 85%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="86"] .complete-job-progress {
      width: 86%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="87"] .complete-job-progress {
      width: 87%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="88"] .complete-job-progress {
      width: 88%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="89"] .complete-job-progress {
      width: 89%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="90"] .complete-job-progress {
      width: 90%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="91"] .complete-job-progress {
      width: 91%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="92"] .complete-job-progress {
      width: 92%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="93"] .complete-job-progress {
      width: 93%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="94"] .complete-job-progress {
      width: 94%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="95"] .complete-job-progress {
      width: 95%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="96"] .complete-job-progress {
      width: 96%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="97"] .complete-job-progress {
      width: 97%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="98"] .complete-job-progress {
      width: 98%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="99"] .complete-job-progress {
      width: 99%; }
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper[data-progress="100"] .complete-job-progress {
      width: 100%; }
  @media only screen and (max-width: 64em) {
    .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container {
      padding: 50px 10px; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-scroller-wrapper .complete-job-scroller-container .complete-job-scroller {
        padding: 0 10px; }
      .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper {
        width: calc(100% + (10px*2)); }
        .complete-job-wrapper .complete-job-lightbox-wrapper .complete-job-container .complete-job-progress-wrapper .complete-job-progress {
          left: -10px; } }

.profile-selector-wrapper .profile-results-wrapper ul {
  list-style: none;
  margin: 0 0 25px; }
  .profile-selector-wrapper .profile-results-wrapper ul li {
    cursor: pointer;
    padding: 10px; }
    .profile-selector-wrapper .profile-results-wrapper ul li .left {
      width: 10%;
      float: left; }
      .profile-selector-wrapper .profile-results-wrapper ul li .left img {
        height: 50px;
        width: 50px;
        border-radius: 25px; }
    .profile-selector-wrapper .profile-results-wrapper ul li .right {
      width: 90%;
      float: left;
      line-height: 50px; }
    .profile-selector-wrapper .profile-results-wrapper ul li:hover {
      background-color: rgba(0, 0, 0, 0.1); }
    .profile-selector-wrapper .profile-results-wrapper ul li:nth-child(odd):not(:hover) {
      background-color: rgba(0, 0, 0, 0.05); }
    .profile-selector-wrapper .profile-results-wrapper ul li.active {
      outline: 2px solid #7dc6ff; }

.loading-indicator-wrapper {
  display: none;
  background-color: rgba(255, 255, 255, 0.5); }

.loading-indicator-wrapper.dark {
  background-color: rgba(10, 10, 10, 0.45); }

[data-is-loading] {
  position: relative; }
  [data-is-loading] .loading-indicator-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    display: block;
    text-align: center; }
    [data-is-loading] .loading-indicator-wrapper .loading-indicator {
      line-height: 100%;
      height: 25px;
      width: 25px;
      border-radius: 12.5px;
      background-image: none !important;
      background-color: #0088ff;
      display: inline-block;
      animation: squareAndBack 1.5s linear infinite; }

@keyframes squareAndBack {
  0% {
    border-radius: 0;
    transform: rotate(0deg) scale(1, 1); }
  50% {
    border-radius: 12.5px;
    transform: rotate(180deg) scale(0.4, 0.4); }
  75% {
    border-radius: 12.5px;
    transform: rotate(270deg) scale(0.7, 0.7); }
  90% {
    border-radius: 0;
    transform: rotate(351deg) scale(0.95, 0.95); }
  100% {
    border-radius: 0;
    transform: rotate(360deg) scale(1, 1); } }

.squde-popup-latest-wrapper {
  border-radius: 10px;
  padding: 0; }
  @media only screen and (max-width: 40em) {
    .squde-popup-latest-wrapper {
      top: 0 !important; } }
  .squde-popup-latest-wrapper .close-reveal-modal {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 30px;
    transform: translate(-10px, -5px); }
  .squde-popup-latest-wrapper .squde-popup-latest-html {
    width: 100%; }
    .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      transition: height 500ms ease; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="1"] .scroll-screen-container {
        width: 100%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="1"] .scroll-screen-container .scroll-screen {
          width: 100%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="2"] .scroll-screen-container {
        width: 200%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="2"] .scroll-screen-container .scroll-screen {
          width: 50%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="3"] .scroll-screen-container {
        width: 300%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="3"] .scroll-screen-container .scroll-screen {
          width: 33.3333333333%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="4"] .scroll-screen-container {
        width: 400%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="4"] .scroll-screen-container .scroll-screen {
          width: 25%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="5"] .scroll-screen-container {
        width: 500%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="5"] .scroll-screen-container .scroll-screen {
          width: 20%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="6"] .scroll-screen-container {
        width: 600%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="6"] .scroll-screen-container .scroll-screen {
          width: 16.6666666667%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="7"] .scroll-screen-container {
        width: 700%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="7"] .scroll-screen-container .scroll-screen {
          width: 14.2857142857%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="8"] .scroll-screen-container {
        width: 800%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="8"] .scroll-screen-container .scroll-screen {
          width: 12.5%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="9"] .scroll-screen-container {
        width: 900%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="9"] .scroll-screen-container .scroll-screen {
          width: 11.1111111111%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="10"] .scroll-screen-container {
        width: 1000%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="10"] .scroll-screen-container .scroll-screen {
          width: 10%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="11"] .scroll-screen-container {
        width: 1100%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="11"] .scroll-screen-container .scroll-screen {
          width: 9.0909090909%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="12"] .scroll-screen-container {
        width: 1200%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="12"] .scroll-screen-container .scroll-screen {
          width: 8.3333333333%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="13"] .scroll-screen-container {
        width: 1300%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="13"] .scroll-screen-container .scroll-screen {
          width: 7.6923076923%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="14"] .scroll-screen-container {
        width: 1400%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="14"] .scroll-screen-container .scroll-screen {
          width: 7.1428571429%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="15"] .scroll-screen-container {
        width: 1500%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="15"] .scroll-screen-container .scroll-screen {
          width: 6.6666666667%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="16"] .scroll-screen-container {
        width: 1600%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="16"] .scroll-screen-container .scroll-screen {
          width: 6.25%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="17"] .scroll-screen-container {
        width: 1700%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="17"] .scroll-screen-container .scroll-screen {
          width: 5.8823529412%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="18"] .scroll-screen-container {
        width: 1800%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="18"] .scroll-screen-container .scroll-screen {
          width: 5.5555555556%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="19"] .scroll-screen-container {
        width: 1900%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="19"] .scroll-screen-container .scroll-screen {
          width: 5.2631578947%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="20"] .scroll-screen-container {
        width: 2000%; }
        .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper[data-screen-count="20"] .scroll-screen-container .scroll-screen {
          width: 5%; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper .scroll-screen-container .scroll-screen {
        float: left;
        padding: 50px 100px; }
        @media only screen and (max-width: 40em) {
          .squde-popup-latest-wrapper .squde-popup-latest-html .scroll-screen-wrapper .scroll-screen-container .scroll-screen {
            padding: 10px 20px; } }
    .squde-popup-latest-wrapper .squde-popup-latest-html .big-text {
      font-size: 24px; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .big-text p {
        font-size: 24px; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .big-text small.error {
        font-size: 20px;
        background-color: red;
        color: white;
        transform: translateY(-1rem);
        display: inline-block;
        width: 100%;
        outline: solid 1px white; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .big-text h1 {
        font-size: 3rem;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 0.5rem; }
      @media only screen and (max-width: 40em) {
        .squde-popup-latest-wrapper .squde-popup-latest-html .big-text {
          font-size: 18px; }
          .squde-popup-latest-wrapper .squde-popup-latest-html .big-text h1 {
            font-size: 1.7rem; } }
    .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 5px;
      background-color: #0088FF;
      -webkit-transition: transform 0.25s ease-in;
      -moz-transition: transform 0.25s ease-in;
      -ms-transition: transform 0.25s ease-in;
      -o-transition: transform 0.25s ease-in;
      transition: transform 0.25s ease-in;
      transform-origin: left center; }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="0"] {
        transform: scaleX(0); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="1"] {
        transform: scaleX(0.01); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="2"] {
        transform: scaleX(0.02); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="3"] {
        transform: scaleX(0.03); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="4"] {
        transform: scaleX(0.04); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="5"] {
        transform: scaleX(0.05); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="6"] {
        transform: scaleX(0.06); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="7"] {
        transform: scaleX(0.07); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="8"] {
        transform: scaleX(0.08); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="9"] {
        transform: scaleX(0.09); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="10"] {
        transform: scaleX(0.1); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="11"] {
        transform: scaleX(0.11); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="12"] {
        transform: scaleX(0.12); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="13"] {
        transform: scaleX(0.13); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="14"] {
        transform: scaleX(0.14); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="15"] {
        transform: scaleX(0.15); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="16"] {
        transform: scaleX(0.16); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="17"] {
        transform: scaleX(0.17); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="18"] {
        transform: scaleX(0.18); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="19"] {
        transform: scaleX(0.19); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="20"] {
        transform: scaleX(0.2); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="21"] {
        transform: scaleX(0.21); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="22"] {
        transform: scaleX(0.22); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="23"] {
        transform: scaleX(0.23); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="24"] {
        transform: scaleX(0.24); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="25"] {
        transform: scaleX(0.25); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="26"] {
        transform: scaleX(0.26); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="27"] {
        transform: scaleX(0.27); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="28"] {
        transform: scaleX(0.28); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="29"] {
        transform: scaleX(0.29); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="30"] {
        transform: scaleX(0.3); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="31"] {
        transform: scaleX(0.31); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="32"] {
        transform: scaleX(0.32); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="33"] {
        transform: scaleX(0.33); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="34"] {
        transform: scaleX(0.34); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="35"] {
        transform: scaleX(0.35); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="36"] {
        transform: scaleX(0.36); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="37"] {
        transform: scaleX(0.37); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="38"] {
        transform: scaleX(0.38); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="39"] {
        transform: scaleX(0.39); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="40"] {
        transform: scaleX(0.4); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="41"] {
        transform: scaleX(0.41); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="42"] {
        transform: scaleX(0.42); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="43"] {
        transform: scaleX(0.43); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="44"] {
        transform: scaleX(0.44); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="45"] {
        transform: scaleX(0.45); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="46"] {
        transform: scaleX(0.46); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="47"] {
        transform: scaleX(0.47); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="48"] {
        transform: scaleX(0.48); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="49"] {
        transform: scaleX(0.49); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="50"] {
        transform: scaleX(0.5); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="51"] {
        transform: scaleX(0.51); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="52"] {
        transform: scaleX(0.52); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="53"] {
        transform: scaleX(0.53); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="54"] {
        transform: scaleX(0.54); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="55"] {
        transform: scaleX(0.55); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="56"] {
        transform: scaleX(0.56); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="57"] {
        transform: scaleX(0.57); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="58"] {
        transform: scaleX(0.58); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="59"] {
        transform: scaleX(0.59); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="60"] {
        transform: scaleX(0.6); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="61"] {
        transform: scaleX(0.61); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="62"] {
        transform: scaleX(0.62); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="63"] {
        transform: scaleX(0.63); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="64"] {
        transform: scaleX(0.64); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="65"] {
        transform: scaleX(0.65); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="66"] {
        transform: scaleX(0.66); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="67"] {
        transform: scaleX(0.67); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="68"] {
        transform: scaleX(0.68); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="69"] {
        transform: scaleX(0.69); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="70"] {
        transform: scaleX(0.7); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="71"] {
        transform: scaleX(0.71); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="72"] {
        transform: scaleX(0.72); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="73"] {
        transform: scaleX(0.73); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="74"] {
        transform: scaleX(0.74); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="75"] {
        transform: scaleX(0.75); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="76"] {
        transform: scaleX(0.76); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="77"] {
        transform: scaleX(0.77); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="78"] {
        transform: scaleX(0.78); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="79"] {
        transform: scaleX(0.79); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="80"] {
        transform: scaleX(0.8); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="81"] {
        transform: scaleX(0.81); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="82"] {
        transform: scaleX(0.82); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="83"] {
        transform: scaleX(0.83); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="84"] {
        transform: scaleX(0.84); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="85"] {
        transform: scaleX(0.85); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="86"] {
        transform: scaleX(0.86); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="87"] {
        transform: scaleX(0.87); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="88"] {
        transform: scaleX(0.88); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="89"] {
        transform: scaleX(0.89); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="90"] {
        transform: scaleX(0.9); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="91"] {
        transform: scaleX(0.91); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="92"] {
        transform: scaleX(0.92); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="93"] {
        transform: scaleX(0.93); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="94"] {
        transform: scaleX(0.94); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="95"] {
        transform: scaleX(0.95); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="96"] {
        transform: scaleX(0.96); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="97"] {
        transform: scaleX(0.97); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="98"] {
        transform: scaleX(0.98); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="99"] {
        transform: scaleX(0.99); }
      .squde-popup-latest-wrapper .squde-popup-latest-html .progress-bar[data-progress="100"] {
        transform: scaleX(1); }

.image-zoom-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1000;
  transition: opacity 500ms ease; }
  .image-zoom-wrapper.show {
    opacity: 1; }
  .image-zoom-wrapper img {
    max-width: 90vw;
    max-height: 90vh;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
    outline: 10px solid white; }

.ad-wrapper {
  margin-top: 25px;
  width: 100%; }
  .ad-wrapper iframe {
    height: 770px;
    width: 100%;
    margin: 0;
    overflow: hidden; }

body.ad-frame-wrapper {
  background-color: white;
  overflow: hidden;
  height: 100%; }
  body.ad-frame-wrapper .ad {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    position: relative; }
    body.ad-frame-wrapper .ad h1 {
      position: absolute;
      left: 50%;
      width: calc(100% - 20px);
      text-align: center;
      transform: translateX(-50%);
      bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: rgba(255, 255, 255, 0.8);
      text-transform: none; }
  body.ad-frame-wrapper .phpdebugbar {
    display: none !important; }
