$images-wrapper-size: 300px;
.images-wrapper {
  .big-image-wrapper {
    height: $images-wrapper-size;
    width: 100%;
    overflow: hidden;
    padding: 0;

    .big-image-container {
      height: 100%;

      .single-big-image-wrapper {
        float: left;
        text-align: center;
      }
    }
  }
  @for $i from 1 through 20 {
    .big-image-wrapper[data-contains="#{$i}"]  {

      .big-image-container {
        width:percentage($i);

        .single-big-image-wrapper {
          width:percentage(1/$i);
        }
      }
    }
  }

  .thumbnails-wrapper {
    overflow:scroll;
    -webkit-overflow-scrolling: touch;

    .thumbnails-container {
      .single-thumbnail-wrapper {
        float:left;
        margin:0.9rem;
      }
      .single-thumbnail-wrapper.active {
        -webkit-box-shadow: 0 0 5px 0 rgb(125, 198, 255);
        box-shadow: 0 0 5px 0 rgb(125, 198, 255);
      }
    }
  }
}