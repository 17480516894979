body.secret_map {
  .top-bar-wrapper, .search-bar-wrapper, .hamburger-button-wrapper, .promotion-side-wrapper {
    display: none;
  }
  .middle {
    padding-top: 0;

    > :not(:nth-child(1)) {
      display: none;
    }
  }

  .map-wrapper {
    width: 100vw;
    height: 100vh;

    #chartdiv {
      width: 100%;
      height: 500px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    //svg {
    //  width: 614.033px;
    //  height: 725.017px;
    //  position: absolute;
    //  left: 50%;
    //  top: 50%;
    //  transform: translate(-50%, -50%);
    //
    //  @media only screen and (max-width: 614.032px) {
    //    transform: translate(-50%, -50%) scale(.5);
    //  }
    //}
  }
}