.ad-wrapper {
  margin-top: 25px;
  width: 100%;

  iframe {
    height: 770px;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}
body.ad-frame-wrapper {
  background-color: white;
  overflow: hidden;
  height: 100%;

  .ad {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    position: relative;

    //a {
    //  color: white;
    //  text-decoration: none;
    //}

    h1 {
      position: absolute;
      left: 50%;
      width: calc(100% - 20px);
      text-align: center;
      transform: translateX(-50%);
      bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: rgba(255,255,255,0.8);
      text-transform: none;
    }
  }
  .phpdebugbar {
    display: none !important;
  }
}