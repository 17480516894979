.google-city-search-wrapper {
  position: relative;

  > input:nth-child(1) {
    position: absolute;
    left: 0;
    background: transparent;
  }
  > input:nth-last-child(1) {

  }
  input.error {
    background: lightcoral;
  }
}