$topbar_height : 45px;
.top-bar-wrapper {
  position:fixed;
  height:$topbar_height;
  width:100vw;
  z-index:11;
  top:0;
  @media screen and (max-width: 63.9375em) {
    //height:$topbar_height*0.75;
  }

  a {
    color:white;
  }

  .relative-wrapper > div {
    width:100vw;
    position:absolute;
    top:0;
  }
  .top-bar-contents {
  }
  .top-bar-background {
    height:$topbar_height + ($topbar_height*0.1);
    background-color:$primary_color;
    border-bottom:solid 4px $primary_light_color;
    z-index:-1;
  }
  //@media screen and (max-width: 63.9375em) {
  //  .top-bar-background {
  //    height:$topbar_height*0.75 + ($topbar_height*0.75*0.1);
  //  }
  //}
  .top-bar-left-wrapper, .top-bar-right-wrapper {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}
.horizontal-menu {
  list-style:none;
  margin:0;
  padding:0;
  width:100%;

  li {
    float: right;
    //width: 25%;
    line-height: $topbar_height;
    font-size: 15px;
    //text-transform: uppercase;
    font-weight: 400;
    padding: 0 20px;
    position:relative;

    > ul {
      position:fixed;
      display: none;
      opacity:0;
      transition:all 0.25s ease-in;
      list-style:none;
      margin: 0 0 0 -10px;
      width:inherit;
      z-index:11;
      top:0;
      background-color: $primary_color;
      border-radius:2px;
      padding: 5px;

      /* Rectangle 11: */
      box-shadow: 0 4px 0 0 #339FFF, 0 0 4px 0 #414141;

      li {
        float:none;
        line-height:35px;
        min-width:10px;
        width:100%;
        text-align:left;
        padding:0;
        padding-right:10px;
        border-top:solid 1px #339FFF;
        padding-left:5px;

        a {
          display:block;
        }
      }
      li:nth-last-child(1) {
        -webkit-border-radius: 0 0 2px 2px;
        border-radius: 0 0 2px 2px;
      }
      li:nth-child(1) {
        border:none;
        -webkit-border-radius: 2px 2px 0 0;
        border-radius: 2px 2px 0 0;
      }
      li:hover {
        background-color:rgba(255,255,255,0.5);
      }
    }
  }
  > li:nth-child(1) {
    float:left;
    padding:0;
  }
  > li:hover {
    > ul {
      display:block;
      opacity:1;
    }
  }
  > li.has-sub::after {
    position:absolute;
    width:9px;
    height:6px;
    top:19px;
    right:0;
    display:inline-block;
    content:"\00a0";

    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
    background-size: 9px 6px;
    //background-position: right -1rem center;
    //background-origin: content-box;
    background-repeat: no-repeat;
    opacity:0;
    transition:opacity .25s ease-in;
  }
  > li.has-sub:not(:hover)::after {
    opacity:1;
  }
}
.hamburger-button-wrapper {
  position:fixed;
  right:25px;
  top: 0;
  z-index:12;
  margin-top:-5px;
}
$line_thickness: 4px;
$hamburger_size: 25px;
.hamburger-button {
  display:inline-block;
  width:$hamburger_size + 15*2;
  padding-left:15px;
  padding-bottom:15px;
}
.hamburger-wrapper {
  display:inline-block;
  //margin-top:($topbar_height - $hamburger_size) / 2;
  height:$hamburger_size;
  width:$hamburger_size;
  margin-right:25px;
  margin-top:10px;
  transition: margin .15s ease-in;
  position:relative;

  .hamburger-line {
    height:$line_thickness;
    width:$hamburger_size;
    background-color:white;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    transition: all 0.8s;
    position:absolute;

    opacity: 1;
    margin-left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .hamburger-line:nth-child(1) {
    top:($topbar_height*0.75 - $hamburger_size) / 2;
  }
  .hamburger-line:nth-child(2) {
    top:($line_thickness * 1 + ($topbar_height*0.75 - $hamburger_size) / 2) + ($hamburger_size - ($line_thickness * 3)) / 2;
  }
  .hamburger-line:nth-child(3) {
    top:($line_thickness * 2 + ($topbar_height*0.75 - $hamburger_size) / 2) + (($hamburger_size - ($line_thickness * 3)) / 2 * 2);
  }
}
.hamburger-wrapper.close {
  margin-top:0;
  .hamburger-button {
    margin-top:0;
  }
  .hamburger-line:nth-child(2) {
    opacity:0;
    margin-left:-$hamburger_size;
  }
  .hamburger-line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top:$hamburger_size;
  }
  .hamburger-line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top:$hamburger_size;
  }
}
.mobile-menu {
  position:fixed;
  width: 100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.75);
  z-index:11;
  opacity:0;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  transition: all 0.8s;
  font-size:30px;
  text-transform:uppercase;
  font-weight:300;

  ul {
    margin:0;
    margin-top:$topbar_height + ($topbar_height*0.1);
    text-align:right;
    list-style:none;

    li {
      width:100vw;
      min-height:50px;
      padding-right:50px;

      a {
        color:white;
      }

    }

    li.has-sub {
      > ul {
        margin:0;
        height:0;
        overflow:hidden;
        -webkit-transition: height 0.5s ease-in;
        -moz-transition: height 0.5s ease-in;
        -ms-transition: height 0.5s ease-in;
        -o-transition: height 0.5s ease-in;
        transition: height 0.5s ease-in;

        li {
          font-size:20px;
          line-height:40px;
          min-height:10px;
          text-align:right;
          padding-right:85px;
        }
        li:nth-child(1) {
          display: none;
        }
      }
    }
    li.has-sub.open {

      ul {
        display:block;
      }
    }

    li.has-sub > a::after {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
      //background-size: 9px 6px;
      background-size: 12px 8px;
      background-position: center center;
      //background-origin: content-box;
      background-repeat: no-repeat;
      -webkit-transition: transform 0.5s ease-in;
      -moz-transition: transform 0.5s ease-in;
      -ms-transition: transform 0.5s ease-in;
      -o-transition: transform 0.5s ease-in;
      transition: transform 0.5s ease-in;
      display: inline-block;
    }

    > li > a::after {
      content:"\00a0\00a0\00a0\00a0";
    }
    li.has-sub.open > a::after {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}
.mobile-menu.shown {
  opacity:1;
}