.login-first-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px #C1B8A4;
  background-color: rgba(255,243,217,0.95);
  padding: 10px;

  .login-first-container {
    padding: 10px;
    background-color: #FFF;
    position: absolute;
    width: calc(100% - 10px - 10px);
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
    //-webkit-animation-name: float-to-the-center;
    //-moz-animation-name: float-to-the-center;
    //-o-animation-name: float-to-the-center;
    //animation-name: float-to-the-center;
    //-webkit-animation-duration: 1s;
    //-moz-animation-duration: 1s;
    //-o-animation-duration: 1s;
    //animation-duration: 1s;
    //-webkit-animation-timing-function: ease-in-out;
    //-moz-animation-timing-function: ease-in-out;
    //-o-animation-timing-function: ease-in-out;
    //animation-timing-function: ease-in-out;
    //-webkit-animation-fill-mode: forwards;
    //-moz-animation-fill-mode: forwards;
    //-o-animation-fill-mode: forwards;
    //animation-fill-mode: forwards;
    //-webkit-animation-delay: 1s;
    //-moz-animation-delay: 1s;
    //-o-animation-delay: 1s;
    //animation-delay: 1s;
    //
    //@keyframes float-to-the-center {
    //  from {
    //    top: 0;
    //    transform: translateY(0);
    //  }
    //  to {
    //    top: 50%;
    //    transform: translateY(-50%);
    //  }
    //}
  }
}
.row.error-wrapper {
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform .5s ease;
  color: red !important;
  
  .column {
    font-style: italic;
  }
  
  &.shown {
    transform: scaleY(1);
  }
}