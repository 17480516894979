.autocomplete-option-list-wrapper {
  height:0;
  left:0;
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: height 0.25s ease-in;
  -moz-transition: height 0.25s ease-in;
  -ms-transition: height 0.25s ease-in;
  -o-transition: height 0.25s ease-in;
  transition: height 0.25s ease-in;
  background-color:white;
  -webkit-box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.10);
  box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.10);

  ul {
    list-style:none;
    margin:0;
    padding:0;
    //-webkit-border-radius:2px;
    //-moz-border-radius:2px;
    //border-radius:2px;

    li {
      min-height:45px;
      line-height:45px;
      cursor:pointer;
      border-bottom:solid 1px rgba(202,202,202,1);
    }
    li:nth-last-child(1) {
      border:none;
    }
    li:hover, li.sort_of_selected {
      background-color:rgba(0,0,0,0.05);
    }
  }
}
.autocomplete-option-list-wrapper.shown {
  border:solid 1px rgba(202,202,202,1);
}