.logo-popup-background {
  background-color:rgba(0,0,0,.25);
  z-index:20;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  position:fixed;
}
.logo-popup-wrapper {
  z-index:21;
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh;

  .table {
    height:100vh;

    .cell {
      text-align:center;

      .logo-popup {
        display:inline-block;
        padding:50px;
        background-color:white;
        border-radius:11px;
        max-width:740px;
        @media only screen and (max-width: 40em) {
          padding:10px;
        }

        h1 {
          text-transform: none;
        }
      }
    }
  }
}