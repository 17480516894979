.complete-symbol {
  display: inline-block;
  margin-top: 50px;
  height: 86px;
  width: 86px;
  background-image:url('/img/icons/green-check.svg');
  background-repeat:no-repeat;
  background-position:center center;
}

.wrapper {
  position: relative;
  margin: 40px auto;
  background: white;
}

.show-if-complete {
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}
.show-if-complete:not(.show) {
  opacity:0;
}