.loading-indicator-wrapper {
  display: none;
  background-color: rgba(255,255,255,0.5);
}
.loading-indicator-wrapper.dark {
  background-color: rgba(10, 10, 10, 0.45);
}
[data-is-loading] {
  position: relative;

  .loading-indicator-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: inherit;
    display: block;
    text-align: center;

    $size: 25px;
    .loading-indicator {
      line-height: 100%;
      height: $size;
      width: $size;
      border-radius: $size/2;
      background-image: none !important;
      background-color: #0088ff;
      display: inline-block;
      animation: squareAndBack 1.5s linear infinite;
    }
    @keyframes squareAndBack {
      0% {
        border-radius: 0;
        transform: rotate(0deg) scale(1,1);
      }
      50% {
        border-radius: $size/2;
        transform: rotate(180deg) scale(0.4,0.4);
      }
      75% {
        border-radius: $size/2;
        transform: rotate(270deg) scale(0.7,0.7);
      }
      90% {
        border-radius: 0;
        transform: rotate(351deg) scale(0.95,0.95);
      }
      100% {
        border-radius: 0;
        transform: rotate(360deg) scale(1,1);
      }
    }
  }
}