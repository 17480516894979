.button {
  border-radius: 2px;
  font-size: 16px;
  margin:0;
  transition:all 0.15s ease-in;
  margin-bottom:1rem;
}
.button.primary:not(.alt), .button:not(.secondary) {
  background-color: #00a3ff;
  -webkit-box-shadow:inset 0 -10px 10px 0 #0088ff;
  box-shadow:inset 0 -10px 10px 0 #0088ff;
  border: solid 1px #0088FF;
  color: #FFFFFF;
}
.button.primary:not(.alt):hover {
  background-color: #0092e5;
  -webkit-box-shadow:inset 0 -10px 10px 0 #007ae5;
  box-shadow:inset 0 -10px 10px 0 #007ae5;
}
.button.primary.alt, button.alt:not(.secondary) {
  background-color: #FF9000;
  -webkit-box-shadow:inset 0 -10px 10px 0 #F76B1C;
  box-shadow:inset 0 -10px 10px 0 #F76B1C;
  border: solid 1px #FF9000;
  color: #FFFFFF;
}
.button.primary.alt:hover {
  background-color: #e58100;
  -webkit-box-shadow:inset 0 -10px 10px 0 #de6019;
  box-shadow:inset 0 -10px 10px 0 #de6019;
}
.button.secondary {
  background-color: #F4F4F4;
  -webkit-box-shadow:inset 0 -10px 10px 0 #DDDDDD;
  box-shadow:inset 0 -10px 10px 0 #DDDDDD;
  border: solid 1px #DCDCDC;
}
.button.secondary:hover {
  background-color: #ececec;
  -webkit-box-shadow:inset 0 -10px 10px 0 #d6d6d6;
  box-shadow:inset 0 -10px 10px 0 #d6d6d6;
}
.button.secondary:not(.alt) {
  color: #0088FF;
}
.button.secondary.alt {
  color: #F76B1C;
}