body.profile_view {
  .cover-photo-wrapper {
    position:absolute;
    top:115px;
    width:100vw;
    height:385px;
    @media only screen and (max-width: 64em) {
      height:555px;
    }
    left:0;
    z-index:-1;

    .cover-photo {
      height:inherit;
      width:inherit;
      background-position:center center;
      background-size:cover;
      background-repeat:no-repeat;
    }
  }
  .global-full-page-wrapper {
    background-color:transparent;
  }
  .profile-wrapper {
    width:100%;
    background-color:transparent;
    padding-top:65px;


    .profile-container {
      .profile-heading {
        min-height:254px;

        > *:not(.clearfix) {
          float:left;
          min-height:inherit;
          padding:30px;
        }

        .profile-heading-left {
          background-color: rgba(245,245,245,0.92);
          min-height:inherit;
          width:66%;

          > * {
            float:left;
          }

          .profile-picture-wrapper {
            width:193px;
            height:193px;
            margin-right:25px;

            img {
              width:inherit;
              height:inherit;;
            }
          }
          .profile-main-data-wrapper {
            min-height:193px;
            width:calc(100% - 193px - 25px);

            p {
              margin-bottom:0;
            }

          }
        }
        .profile-heading-right {
          background-color: rgba(255,255,255,0.92);
          width:34%;
          font-size: 18px;

          .contact-data-wrapper {
            height:193px;
          }
        }
        @media only screen and (max-width: 64em) {
          .profile-heading-left {
            width:100%;
            text-align:center;
            padding-bottom:0;

            .profile-picture-wrapper, .profile-main-data-wrapper {
              margin:0;
              display:inline-block;
              float: none;
              clear:both;
              width:100%;
              //min-height:193px;

              img {
                width: 193px;
              }
            }
          }
        }
        .table {
          height:100%;
        }
      }
      .profile-content-wrapper {
        min-height:400px;
        background-color:white;

        .crumbs {
          padding:0 30px;
        }

        > .profile-content {

          > .row {
            > .column, > .columns {

              .profile-content-container {
                position:relative;
                padding: 0 80px;
                @media only screen and (max-width: 40em) {
                  padding: 0 30px;
                }
                width:100%;
                select {
                  width:100%;
                }
              }

            }
          }
        }
        .new-project-wrapper {
          width:100%;
          height:25vh;
          //border:dashed 5px #ff9000;
          color:#999;
          font-weight:600;
          background-color:#F6F6F6;
          cursor: pointer;

          a {
            color:inherit;
          }
          img {
            width:25%;
          }

          .table {
            height:100%;
          }
        }
        .start-adding-projects-wrapper {
          background-color:#F6F6F6;
          padding:25px;
          margin-bottom:75px;

          img {
            width: 125px;
            margin-bottom:20px;
          }
          h3 {
            font-size:36px;
          }
          p.large-text {
            font-size:1.3em;
          }
        }
      }
    }
  }


  .reviews-wrapper, .review-preview {
    .reviews-listing-wrapper {
      > div {
        padding-bottom:15px;
        border-bottom:solid 1px #DCDCDC;
        margin-bottom:50px;
      }
    }
    .rating-summary {
      .bar {
        height: 10px;
        display: inline-block;
        background-color: #CCC;
        width: 100%;
        padding:0;
        line-height: 10px;
        position: relative;

        .bar-fill {
          height:inherit;
          display:inline-block;
          background-color:#0088FF;
          line-height: 10px;
          position: absolute;
        }
      }
      @for $i from 0 through 100 {
        .bar.filled-#{$i} {
          .bar-fill {
            width:percentage($i/100);
          }
        }
      }
      * {
        line-height:25px;
      }
      .count-number {
        font-size:10px;
        color:#999;
      }
    }
    [data-state=closed] {
      display:none;
    }
    .review-parts-wrapper {
      padding-top:1px;
      margin-bottom:25px;
      -webkit-transition: height 0.25s ease-in;
      -moz-transition: height 0.25s ease-in;
      -ms-transition: height 0.25s ease-in;
      -o-transition: height 0.25s ease-in;
      transition: height 0.25s ease-in;

      .review-parts-container {
        margin-top:10px;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        border-radius:4px;
        background-color:#F6F6F6;
        position: relative;

        .arrow-up-wrapper::before {
          content: "Verberg beoordelingen per onderdeel ";
          font-size:0.875rem;
          font-weight:bold;
          visibility: hidden;
          line-height:0;
          overflow:hidden;
          height:0;
        }
        .arrow-up {
          //display:inline-block;
          background:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\' width=\'32\' height=\'24\' viewBox=\'0 0 32 24\'><polygon points=\'32,24 0,24 16,0\' style=\'fill: rgb%28246, 246, 246%29\'></polygon></svg>");
          //background:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\' width=\'32\' height=\'24\' viewBox=\'0 0 32 24\'><polygon points=\'32,24 0,24 16,0\' style=\'fill: rgb%2810, 10, 10%29\'></polygon></svg>");
          position:absolute;
          background-repeat:no-repeat;
          background-origin:content-box;
          background-size: 9px 6px;
          margin-left:8.5px;
          width:9px;
          height:6px;
          margin-top:-6px;
        }
        .review-parts {
          padding:0 15px 25px 25px;
        }
      }
    }

    .review-parts-wrapper.closed {
      overflow:hidden;
      height:0;
    }
  }
  .respond-button::before {
    background-image:url("/img/icons/respond-button.svg");
    background-size:contain;
    background-origin:content-box;
    background-repeat:no-repeat;
    background-position: center center;
    content: "\00a0\00a0";
    width:13px;
    height:20px;
    display:inline-block;
  }
  .respond-button {
    cursor:pointer;
  }
  .respond-button:hover {
    text-decoration: underline;
  }
  .votes-wrapper {
    font-size:14px;
    color:#949494;
  }
  .response-input-wrapper {
    height:0;
    overflow:hidden;
    -webkit-transition: height 0.25s ease-in;
    -moz-transition: height 0.25s ease-in;
    -ms-transition: height 0.25s ease-in;
    -o-transition: height 0.25s ease-in;
    transition: height 0.25s ease-in;
  }
  .review-answer {
    background-color:#F6F6F6;
    border:solid 1px #DCDCDC;
    padding:10px;
  }
}