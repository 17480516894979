$placeholder_color: #999;
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $placeholder_color;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    $placeholder_color;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    $placeholder_color;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    $placeholder_color;
}
form {
  label {
    margin-bottom:-5px;
    font-weight:bold;
    color:#545454;

    sup {
      color:red;
    }
    sup::before {
      content: " ";
    }
  }
  input.no-margin {
    margin-top:-1rem;
  }
  fieldset {
    border:solid 1px rgba(0,0,0,0.25);
    padding:0 25px;

    legend {
      margin-left:5px;
      padding-left:5px;
      padding-right:5px;
      padding-top:5px;
      font-weight:bold;
    }
  }
}
//.button, button:not(.close-button) {
//  text-transform: uppercase;
//  border:solid 1px #FFF;
//  -webkit-box-shadow: 2px 2px 0 0 rgba(194,194,194,1);
//  box-shadow: 2px 2px 0 0 rgba(194,194,194,1);
//  padding-left:25px;
//  padding-right:25px;
//}
.spacer {
  height:15px;
}
p.comment {
  font-size:80%;
  line-height:110%;
  font-style:oblique;
  margin:0;
  color:#565656;
}
.hidden-by-overflow-wrapper {
  overflow:hidden;
  width:100%;
  height:0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

//.captcha-container {
//  height:0;
//  overflow:hidden;
//}

/*Special select2 css*/
.select2-container {
  .select2-selection--single, .select2-selection--multiple {
    background-image:url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" width=\"32\" height=\"24\" viewBox=\"0 0 32 24\"><polygon points=\"0,0 32,0 16,24\" style=\"fill: rgb%28138, 138, 138%29\"></polygon></svg>");
    background-origin:content-box;
    background-position:right center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    border:1px solid #cacaca;
    border-radius:2px;
    color:#0a0a0a;
    font-size: 1rem;
    line-height: normal;
    margin: 0 0 1rem;
    padding:0.5rem;

    .select2-selection__rendered {
      margin-top:-0.25rem;
      margin-left:-0.25rem;
    }

    .select2-selection__arrow {
      display:none;
    }
  }
  .select2-selection--single {
    height: 2.4375rem;
  }
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #CACACA;
}
.select2-dropdown {
  margin-top:-1rem;
}
/*Password view icon settings*/
.password-wrapper {
  position: relative;

  .view-icon {
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    color: #AAA;
    cursor: pointer;
  }
  .view-icon.shown {
    color: #555;
  }
}
input.inline-input {
  display:inline-block;
  width:auto;
  text-transform: lowercase;
  font-size:inherit;
  padding-top:6px;
}
input.add-icon {
  background-image:url('/img/icons/plus-sign.svg');
  background-position:95% center;
  background-repeat:no-repeat;
  max-width:250px;
  padding-right:35px;
}
span.error {
  font-size:10px;
  color:red;
  font-style: italic;
  display:none;
}