.form-loader-overlay {
  position:fixed;
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.5);
  left:0;
  top:0;
  z-index: 1000;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}
.form-loader-overlay:not(.shown) {
  opacity:0;
}
.form-loader-overlay.shown {
  opacity:1;
}
.form-loader-overlay.shown::before {
  display:block;
  height:200px;
  width:200px;
  background-image:url('/img/loader-logo.svg');
  background-size:contain;
  background-position:center center;
  background-repeat:no-repeat;
  position:fixed;
  left:50%;
  margin-left:-100px;
  top:50%;
  margin-top:-100px;
  content:"\00a0";
  animation-name: rotate-logo;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotate-logo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}