$primary_color : #0088ff;
$primary_light_color : #339fff;
$background_color : #e1e1e1;

body {
  background-color:$background_color;
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
  color:#414141;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  //line-height:80%;
  color:#414141;
}
h1.gray,
h2.gray,
h3.gray,
h4.gray,
h5.gray,
h6.gray
.relative-wrapper {
  color:#949494;
}
img:not(.noborders) {
  //border:solid 3px #737373;
  //-webkit-box-shadow: 5px 5px 0 0 #b4b4b4;
  //box-shadow: 5px 5px 0 0 #b4b4b4;
}
.debug {
  background-color:white;
  min-height:35px;
  position:fixed;
  left:0;
  top:0;
  z-index: 9999;
}
.table {
  display:table;
  min-height:inherit;
  width:100%;
  left:0;

  .cell {
    display:table-cell;
    vertical-align: middle;
  }
}

.wide-wrapper {
  min-height:0;
  width:100vw;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    //Medium screen
    /*
    min-height:calc(100vh - 75px - 7.5px);
    height:calc(100vh - 75px - 7.5px);
    */
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300;

  }
  h1 {
    font-size:1.6em;

    @media screen and (min-width: 40em) {
      font-size:3em;
    }
  }
  h6 {
    line-height:20px;
  }
  a:not(.button) {
    //font-size:14px;
  }
  .table {
    max-width:100%;
  }
}
.wide-wrapper.spaced {
  padding-top:100px;
  padding-bottom:100px;
}
.wide-wrapper.blue {
  background-color:#339fff;
  color:white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a{
    color:white;
  }
}
.wide-wrapper.dark {
  background-color:#343434;
  color:white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color:white;
  }
}
.wide-wrapper.white {
  background-color:#FFF;
  color:#949494;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color:#949494;
  }
}
ul.no-list {
  margin:0;
  list-style:none;
}
.bold {
  font-weight:bold;
}
.help-tooltip.tooltip-questionmark::before {
  content:"?";
}
.help-tooltip.tooltip-questionmark {
  height:16px;
  width:16px;
  line-height:14px;
  font-size:90%;
  background-color: $primary_color;
  display:inline-block;
  position:absolute;
  margin-top:-5px;
  margin-left:5px;
  text-align:center;
  border-radius:8px;
  color:white;
  cursor:pointer;
  border:solid 1px $primary_light_color;
  font-weight: 600;
}
.design-by {
  margin:10px;
}
//.orientation-2, .orientation-4, .orientation-5, .orientation-7 {
//  -moz-transform: scaleX(-1);
//  -o-transform: scaleX(-1);
//  -webkit-transform: scaleX(-1);
//  transform: scaleX(-1);
//  filter: FlipH;
//  -ms-filter: "FlipH";
//  //if(pos == 2) {
//  //  //only mirror
//  //} else if(pos == 3) {
//  //  //Rotate 180 deg
//  //} else if(pos == 4) {
//  //  //Rotate 180 deg & mirror
//  //} else if(pos == 5) {
//  //  //Rotate 90 deg & mirror
//  //} else if(pos == 6) {
//  //  //Rotate 90 deg
//  //} else if(pos == 7) {
//  //  //Rotate -90 deg & mirror
//  //} else if(pos == 8) {
//  //  //Rotate -90 deg
//  //}
//}
//.orientation-5, .orientation-6 {
//  -moz-transform: rotate(90deg);
//  -webkit-transform: rotate(90deg);
//  -o-transform: rotate(90deg);
//  -ms-transform: rotate(90deg);
//  transform: rotate(90deg);
//}
//.orientation-3, .orientation-4 {
//  -moz-transform: rotate(180deg);
//  -webkit-transform: rotate(180deg);
//  -o-transform: rotate(180deg);
//  -ms-transform: rotate(180deg);
//  transform: rotate(180deg);
//}
//.orientation-7, .orientation-8 {
//  -moz-transform: rotate(-90deg);
//  -webkit-transform: rotate(-90deg);
//  -o-transform: rotate(-90deg);
//  -ms-transform: rotate(-90deg);
//  transform: rotate(-90deg);
//}
.gray-text {
  color:#949494;
}
.dropdown-arrow {
  display:inline-block;
  background:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' version=\'1.1\' width=\'32\' height=\'24\' viewBox=\'0 0 32 24\'><polygon points=\'0,0 32,0 16,24\' style=\'fill: rgb%2810, 10, 10%29\'></polygon></svg>");
  background-repeat:no-repeat;
  background-origin:content-box;
  background-size: 9px 6px;
  margin-left:5px;
  width:9px;
  height:6px;
}
.footer-wrapper {
  padding-top:100px;
}
.social-button {
  .fb-share-button {
    span {
      height:26.5px !important;
    }
  }
}

@import "foundation.custom";
@import "alerts-and-messages";
@import "logo";
@import "top-bar";
@import "form";
@import "home";
@import "floating-iframe";
@import "not-home";
@import "icons";
@import "image-upload";
@import "search";
@import "search-bar";
@import "rating";
@import "profile";
@import "project";
@import "hover-options";
@import "page";
@import "register";
@import "auth";
@import "modal";
@import "job";
@import "no-margin-page";
@import "edit-form";
@import "scroll-wizard";
@import "form.complete-box";
@import "ajax-autocomplete";
@import "squde-select";
@import "loading-indicator";
@import "logo-popup";
@import "content-icons";
@import "user-edit";
@import "form-loader";
@import "review";
@import "big-ass-input";
@import "googleCitySearch";
@import "contact-box";
@import "thumbnail-photo-viewer";
@import "promotions";
@import "promotion_phone";
@import "login-first";
@import "map";
@import "complete_job";
@import "profile-searcher";
@import "isLoading";
@import "squde-popup";
@import "image-zoom";
@import "ads";