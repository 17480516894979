.edit-form-wrapper {
  line-height:40px;

  .field-editor {
    display:none;
  }
  .show-on-edit {
    display:none;
  }
  .is-editing {
    background-color:rgba(0,0,0,0.1);
    -webkit-transition: background-color 0.25s ease-in;
    -moz-transition: background-color 0.25s ease-in;
    -ms-transition: background-color 0.25s ease-in;
    -o-transition: background-color 0.25s ease-in;
    transition: background-color 0.25s ease-in;
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    border-radius:2px;
    padding-top:0.8rem;
    margin-bottom:0.8rem;
    margin-top:0.8rem;

    > :not(.identifier) {
      display:none;
    }
    > .show-on-edit {
      display:block;
    }

    .field-editor {
      display:block;
    }
  }
  .button {
    width:100%;
  }
  //.button.secondary {
  //  //background-image: linear-gradient(-180deg, #E0E0E0 0%, #D0D0D0 100%);
  //  background-color:rgba(255,255,255,0);
  //  -webkit-transition: background-color 0.2s ease-in;
  //  -moz-transition: background-color 0.2s ease-in;
  //  -ms-transition: background-color 0.2s ease-in;
  //  -o-transition: background-color 0.2s ease-in;
  //  transition: background-color 0.2s ease-in;
  //  border: 1px solid #DCDCDC;
  //  //border-radius: 2px;
  //  //font-size: 16px;
  //  color: #0088FF;
  //}
  //.button.secondary:hover {
  //  background-color:rgba(255,255,255,0.5);
  //}
  .value-wrapper {
    overflow:hidden;
  }
}