.floating-iframe-wrapper {
  $screen_largest_side: 100vw;
  $multiplier: 1600;

  position:absolute;
  left:50%;
  top:50%;

  margin-left:calc((#{$screen_largest_side} / #{$multiplier} * -415) + (#{$screen_largest_side} / #{$multiplier} * 38));
  margin-top:calc(#{$screen_largest_side} / #{$multiplier} * 70);
  width:calc(#{$screen_largest_side} / #{$multiplier} * 415);
  height:calc(#{$screen_largest_side} / #{$multiplier} * 350);
  @media screen and (max-width: 1600px) and (orientation:landscape)  {
    margin-left: -415 + 18;
    margin-top:70px;
    width:415px;
    height:350px;
  }
  @media screen and (orientation:portrait) {
    $multiplier: 1067;
    $screen_largest_side: 100vh;
    margin-left:calc((#{$screen_largest_side} / #{$multiplier} * -415) + (#{$screen_largest_side} / #{$multiplier} * 38));
    margin-top:calc(#{$screen_largest_side} / #{$multiplier} * 70);
    width:calc(#{$screen_largest_side} / #{$multiplier} * 415);
    height:calc(#{$screen_largest_side} / #{$multiplier} * 350);
  }
  iframe {
    pointer-events: auto;
    transform: matrix3d(0.103918, -0.251872, 0, 0.000141121, 0.436207, 0.220914, 0, -0.00000723136, 0, 0, 1, 0, 133, 281, 0, 1);
    transform-origin: 0px 0px 0px;
    width:calc(100% / 665 * 768);
    height:calc(100% / 560 * 1025)
    //width:inherit;
    //height:inherit;
  }
  @media screen and (width: 2560px) {
    //Don't show it anymore because the js dies when loading in browser.
    //jQuery falls over the search-bar saying "divStyle is null"
    iframe {
      display:block;
    }
  }

  .box {
    height:1024px;
    width:768px;
    background-color:red;
    opacity:0.5;
    pointer-events: auto;
    transform: matrix3d(0.103918, -0.251872, 0, 0.000141121, 0.436207, 0.220914, 0, -0.00000723136, 0, 0, 1, 0, 133, 281, 0, 1);
    transform-origin: 0px 0px 0px;
    //transform:matrix3d(0.110122, -0.252123, 0, 0.000161061, 0.425827, 0.212939, 0, 0.0000348358, 0, 0, 1, 0, 175, 306, 0, 1);
  }
}
//.white-bar {
//  height:240px;
//  background-color:white;
//  width:100vw;
//  position:absolute;
//  left:0;
//  bottom:0;
//  margin-bottom:-240px;
//  z-index:10;
//}