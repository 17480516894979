.squde-popup-latest-wrapper {
  border-radius: 10px;
  padding: 0;

  @media only screen and (max-width: 40em) {
    top: 0 !important;
  }

  .close-reveal-modal {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 30px;
    transform: translate(-10px,-5px);
  }

  .squde-popup-latest-html {
    width: 100%;

    .scroll-screen-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      transition: height 500ms ease;
      
      @for $i from 1 through 20 {
        &[data-screen-count="#{$i}"] {
          .scroll-screen-container {
            width: percentage($i);
            
            .scroll-screen {
              width: percentage(1 / $i);
            }
          }
        }
      }
      
      .scroll-screen-container {
        .scroll-screen {
          float: left;
          padding: 50px 100px;
          @media only screen and (max-width: 40em) {
            padding: 10px 20px;
          }
        }
      }
    }


    .big-text {
      font-size: 24px;

      p {
        font-size: 24px;
      }

      small.error {
        font-size: 20px;
        background-color: red;
        color: white;
        transform: translateY(-1rem);
        display: inline-block;
        width: 100%;
        outline: solid 1px white;
      }
      h1 {
        font-size: 3rem;
        line-height: 1.4;
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      @media only screen and (max-width: 40em) {
        font-size: 18px;

        h1 {
          font-size: 1.7rem;
        }
      }
    }

    .progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 5px;
      background-color: #0088FF;
      -webkit-transition: transform 0.25s ease-in;
      -moz-transition: transform 0.25s ease-in;
      -ms-transition: transform 0.25s ease-in;
      -o-transition: transform 0.25s ease-in;
      transition: transform 0.25s ease-in;
      transform-origin: left center;

      @for $i from 0 through 100 {
        &[data-progress="#{$i}"] {
          transform: scaleX($i / 100);
        }
      }
    }
  }
}