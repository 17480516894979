body.home {
  //.top-bar-background {
  //  height:0;
  //  opacity:0;
  //}
  //.logo.small {
  //  display:none;
  //}
  overflow-x:hidden;

  h1, h2, h3, h4 {
    text-transform: none;
  }
  .big-image:nth-child(1) {
    background-image: url('/img/bg.jpg');

    h1 {
      text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
      color:white;

      @media only screen and (max-width: 40em) {
        font-size:3em;
      }
    }
  }
  .big-image#screen-2 {
    background-image: url('/img/badkamer.jpg');
  }
  .big-image#screen-3 {
    background-image: url('/img/project.jpg');
  }
  .big-image#screen-4 {
    background-image: url('/img/vrouw.jpg');
    //background-position:center bottom;
    color:white;
    overflow:hidden;

    h2 {
      color:white;
      line-height:1.2em;
    }
  }
  .big-image {
    background-size: cover;
    background-position:center center;
    height:calc(100vh);
    font-size:16px;
    position:relative;

    .table {
      top:0;
      width:100vw;
      height:inherit;
    }

    > .row {
      height:100%;
    }
    .homepage-box {
      display:inline-block;
      min-height:200px;
      background-color:white;
      width:100%;
      padding:40px;
    }

    h1 {
      font-size:4.3em;
      line-height:1.2em;
    }
    h2 {
      font-size:2.25em;
    }
    h3 {
      font-size:1.55625em;
      color:#414141;
      line-height:1.2em;
    }
  }
  @media screen and (max-width: 63.9375em) and (orientation:landscape) {
    .big-image {
      height:100vh;
    }
  }
  @media screen and (max-width: 63.9375em) and (orientation:portrait) {
    .big-image {
      h1 {
        font-size:4em;
      }
      h2 {
        font-size:1.8em;
      }
      h3 {
        font-size:1.245em;
      }
    }
  }

  .big-image#screen-1 {
    margin-top: 113px;
    height: calc(100vh - 113px);
  }

  .wide-wrapper.full {
    //height:50vh;
    min-height:50vh;

    > .table {
      top:0;
      width:100vw;
      height:20px;

      > .cell {
        > .row {
          padding:50px;
          color:#414141;

          h2 {
            line-height:1.2em;
            font-weight:400;

            @media only screen and (max-width: 40em) {
              font-size:2em;
            }
          }
          h4 {
            font-weight:400;

          }
        }
      }
    }
  }
  .wide-wrapper.full.dark {
    > .table {
      > .cell {
        > .row {

          color:white;

          h2 {
            color:white;
          }
          h4 {
            color:#949494;
          }
        }
      }
    }
  }
  .wide-wrapper.full.grey, .wide-wrapper.grey {
    background-color: white;
  }
  .zzp-images {
    margin-bottom: 10px;
  }
  .small-wide-wrapper {
    margin: 0;
    min-height: auto;
  }


  //.search-wrapper {
  //  width:100vw;
  //  min-height:40vh;
  //  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+50,e8e8e8+50 */
  //  background: #f2f2f2; /* Old browsers */
  //  background: -moz-linear-gradient(left,  #f2f2f2 50%, #e8e8e8 50%); /* FF3.6-15 */
  //  background: -webkit-linear-gradient(left,  #f2f2f2 50%,#e8e8e8 50%); /* Chrome10-25,Safari5.1-6 */
  //  background: linear-gradient(to right,  #f2f2f2 50%,#e8e8e8 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#e8e8e8',GradientType=1 ); /* IE6-9 */
  //
  //
  //  .search-container {
  //    padding:25px;
  //    min-height:40vh;
  //    @media screen and (max-width: 39.9375em) {
  //      width:100vw;
  //    }
  //  }
  //  .search-container#left {
  //    background-color:#f2f2f2;
  //  }
  //  .search-container#right {
  //    background-color:#e8e8e8;
  //  }
  //}
}