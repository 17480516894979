body.show_job {
  .job-wrapper {
    color: #737373;
    padding:40px 100px;
    @media screen and (max-width: 63.9375em) {
      padding:25px;
    }

    h1 {
      line-height:100%;
      font-size:28px;
      font-weight:bold;
      min-height:56px;
    }
    //.images-wrapper {
    //  .main-image-wrapper {
    //    img {
    //      margin-bottom:50px;
    //    }
    //  }
    //}
  }
}

body.edit_job {
  form.add_job_form {
    fieldset {
      display:none;
    }
    fieldset:nth-of-type(1) {
      display:block;
    }
  }
}

.new-job-wrapper {
  h1, h2, h3, h4, h5 {
    text-transform: none;
  }
  .job-lightbox-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.9);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .job-lightbox-wrapper {
    width: 100vw;
    height: 100vh;
    z-index: 101;
    position: fixed;
    left: 0;
    top: 0;
    padding: 50px 0;
    max-width: 1200px;
    @media screen and (min-width: 1200px) {
      left: 50%;
      margin-left: -600px;
    }

    > .row {
      //background-color:rgba(0,0,0,0.85);
      border-radius: 3px;
    }
    .lightbox-close-button-wrapper {
      height:0;
      position:relative;

      a {
        text-shadow: 1px 1px 0 #212121;
        position:absolute;
        right:18px;
        top:0;
        color:white;
        font-size:40px;
        z-index:105;
      }
    }
    .table {
      height: 100%;
    }
    .new-job-container {
      $vertical_padding: 50px;
      $horizontal_padding: 100px;
      background-color:white;
      border-radius:10px;
      overflow: hidden;
      padding:$vertical_padding $horizontal_padding;

      .new-job-scroller-wrapper {
        width:100%;
        height:inherit;
        overflow: hidden;

        .new-job-scroller-container {
          .new-job-scroller {
            float:left;
            padding: 0 $horizontal_padding;


            .job-preview {
              width:inherit;
              /* Rectangle 14: */
              box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
              border-radius: 2px;
              padding: 20px;
              margin-bottom: 25px;

              .large-text {
                font-size: 1.25em;
              }
            }
          }
          .new-job-scroller.big-text {
            font-size: 24px;
          }
          .new-job-scroller:nth-child(0) {
            padding-left:0;
          }
          .new-job-scroller:nth-last-child(0) {
            padding-right:0;
          }
        }
      }
      @for $i from 1 through 10 {
        .new-job-scroller-wrapper[data-contains="#{$i}"]  {

          .new-job-scroller-container {
            width:percentage($i);

            .new-job-scroller {
              width:percentage(1/$i);
            }
          }
        }
      }


      .job-progress-wrapper {
        position:relative;
        height:0;
        width:calc(100% + (#{$horizontal_padding}*2));

        .job-progress {
          height:5px;
          background-color:#0088FF;
          position:absolute;
          top:$vertical_padding * -1;
          left: $horizontal_padding* -1;
          -webkit-transition: width 0.25s ease-in;
          -moz-transition: width 0.25s ease-in;
          -ms-transition: width 0.25s ease-in;
          -o-transition: width 0.25s ease-in;
          transition: width 0.25s ease-in;
        }
      }
      @for $i from 0 through 100 {
        .job-progress-wrapper[data-progress="#{$i}"] .job-progress  {
          width:percentage($i/100);
        }
      }
    }

    @media only screen and (max-width: 64em) {
      .new-job-container {
        $vertical_padding: 50px;
        $horizontal_padding: 10px;
        padding:$vertical_padding $horizontal_padding;

        .new-job-scroller-wrapper {
          .new-job-scroller-container {
            .new-job-scroller {
              padding: 0 $horizontal_padding;
            }
          }
        }
        .job-progress-wrapper {
          width:calc(100% + (#{$horizontal_padding}*2));

          .job-progress {
            left: $horizontal_padding* -1;
          }
        }
      }
    }
  }
}
.main-info-wrapper {
  background-color: rgba(0,0,0,0.05);
  padding: 25px;
  font-size: 14px;
  border: dashed 2px rgba(0,0,0,0.1);

  a.button {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 14px;
  }
}
body.jobs {
  .result-wrapper {
    .done-indicator {
      height: 100px;
      width: 100px;
      background-image: url('/img/job-done.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: 10px;
      margin-top: 10px;
      position: absolute;
      z-index: 10;
      //transform: translateX(-100%);

      &.shown {
        display: inline-block;
      }
      &:not(.shown) {
        display: none;
      }
    }
    .done-indicator.shown + img {
      opacity: 0.35;
    }
  }
}