.big-ass-input {
  input:not(.original-size), textarea:not(.original-size) {
    font-size:24px;
    line-height:30px;
    height:auto;
  }
  button:not(.original-size), .button:not(.original-size) {
    font-size:24px;
    line-height:24px;
    height:auto;
  }
}