.alert {

  button {
    border:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding:0;
    color:rgba(10, 10, 10, 0.25);
  }
  button:hover {
    color:rgba(10, 10, 10, 0.5);
  }
}