body:not(.home) {
  .search-screen-wrapper {
    .filters-button-wrapper {
      position: fixed;
      right: 0;
      top: 130px;
    }
    .filters-and-results-wrapper {
      overflow-x:hidden;
      width:100%;
      min-height:25vh;

      .filters-and-results-container {
        width:100%;

        > * {
          float:left;
        }
        .filters-wrapper {
          width:20%;

          .filters-container {
            background-color:white;
            padding: 25px 0.9375rem;

            h2 {
              font-size:18px;
              font-weight:bold;
              margin-bottom:15px;
              line-height:16px;
            }

            .filter {
              padding-bottom:15px;

              input {
                margin:0;
              }
              label {
                font-weight:normal;
              }
              input.search-filters {
                margin-top:15px;
                margin-bottom:5px;
              }

              h3 {
                font-size:14px;
                font-weight:bold;
                text-transform: none;
              }

              @media screen and (max-width: 63.9375em) {
                .rating {
                  font-size:26px;
                }
              }

              .list-wrapper {
                overflow-y:hidden;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                transition: all 1s;

                ul {

                  li {
                    display:none;
                    font-size:0.875rem;
                  }
                  li:nth-child(1), li:nth-child(2), li:nth-child(3), li:nth-child(4)  {
                    display:block;
                  }
                  li.no-match-filter {
                    display:none;
                  }
                }
              }
            }
            .filter:nth-last-child(1) {
              padding-bottom:0;
            }
          }
        }
        .results-wrapper {
          width:75%;
          float:right;
          background-color:white;

          .before-results-header {
            padding: 25px;
            padding-bottom:0;

            label {
              line-height:2.4375rem;
            }
            .chosen-filters-wrapper {
              label {
                display:inline;
              }
              a.remove-filter {
                .chosen-filter-wrapper {
                  line-height:calc(2.4375rem - 1px);
                  height:2.2375rem;
                  border:solid 1px #545454;
                  background-color: $primary_color;
                  color:white;
                  border-radius:6px;
                  padding-left:5px;
                  padding-right:5px;
                  display:inline-block;
                  -webkit-box-shadow: 2px 2px 0 0 #b4b4b4;
                  box-shadow: 2px 2px 0 0 #b4b4b4;
                  font-size:120%;
                  margin-right:5px;
                  margin-bottom:10px;

                  span.cross {
                    color:white;
                    text-decoration: underline;
                    font-size:120%;
                    font-weight:300;
                    -webkit-transition: all 0.25s;
                    -moz-transition: all 0.25s;
                    -ms-transition: all 0.25s;
                    -o-transition: all 0.25s;
                    transition: all 0.25s;
                  }
                }
              }
              a.remove-filter:hover {
                .chosen-filter-wrapper {
                  span.cross {
                    color:#ff9393;
                  }
                }
              }
            }
            .results-counter-wrapper {
              position:relative;
              color:white;
              font-weight:bold;
              height:50px;

              .results-counter {
                position:absolute;
                min-width:150%;
                background-color:#c2c2c2;
                padding-left:15px;
                padding-right:15px;
                padding-top:8px;
                padding-bottom:8px;
              }

            }
          }

          .divider-wrapper {
            padding:0 25px;
          }

          .results-container {

            .result-wrapper:nth-child(even) {
              background-color:#f7f7f7;
            }
            .result-wrapper {
              color:#737373;
              font-size:14px;
              padding: 20px 25px;
              min-height:160px;

              img.profile-picture, img.job-picture {
                height:100px;
                width:100px;
                margin:10px;
              }
              h3 a {
                line-height:20px;
                font-size:22px;
                font-weight:bold;
                text-decoration: underline;
                -webkit-transition: all 0.25s;
                -moz-transition: all 0.25s;
                -ms-transition: all 0.25s;
                -o-transition: all 0.25s;
                transition: all 0.25s;
              }
              h3 a:hover {
                color:$primary_light_color;
              }
              .location-info {
                margin-top:22px;
                line-height:16px;
              }
              .statistics-info {
                font-size:80%;
              }
              @media screen and (max-width: 39.9375em) {
                h3 {
                  margin-top:10px;
                }
                .location-info {
                  margin-top: 15px;
                  margin-left: 10px;
                }
                .statistics-info {
                  margin-left: 10px;
                }
              }
            }
          }
        }

        .no-data-wrapper {

        }
      }
    }
    @media screen and (max-width: 63.9375em) {

      $filters_width: 60vw;
      $results_width: 100vw;

      .filters-and-results-wrapper {
        .filters-and-results-container {
          width: $filters_width + $results_width;

          .filters-wrapper {
            width:$filters_width*0.95;
            margin-right:$filters_width*0.05;
            margin-left:$filters_width*-1;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -ms-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
          }
          .results-wrapper {
            width:$results_width;
            float:left;
          }
        }
      }
      .filters-and-results-wrapper.open {
        .filters-and-results-container {
          .filters-wrapper {
            margin-left:0;          }

        }
      }
    }
    /*
    .search-filters-wrapper {
      background-color:white;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      transition: all 0.8s;

    }
    */

    .busy-loading {
      width:100%;
      height:0;
      overflow:hidden;
      transition:height .5s ease-in;

      p {
        line-height:50px;
      }
    }
    .busy-loading.show {
      height:50px;
    }
  }
}