.image-upload-wrapper {
  width:232px;
  height:232px;
  color:#949494;
  margin-bottom:0.9rem;
  display:inline-block;

  .table {
    height:100%;
    //border:dashed 4px #CCC;

    /* Rectangle 137: */
    background-color: #F8F8F8;
    background-image:url('/img/icons/big-upload-icon.svg');
    background-position:center center;
    background-repeat:no-repeat;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    font-size:24px;

    .cell:not(.multiple):not(.dz-started) {
      cursor:pointer;
    }
    .cell {
      text-align:center;
      vertical-align: bottom;
      padding-bottom:25px;
    }
    .cell.dz-started {
      position:relative;

      .dz-preview {
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;

        >:not(.dz-image) {
          display:none;
        }
        > .dz-image {
          border:none;
          position:absolute;
          left:0;
          top:0;
          width:100%;
          height:100%;
          background-size:cover;
          background-position:center center;

          > img {
            border:none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
          }
        }
      }
    }
  }
}
.uploaded-picture-wrapper {
  display:none;
}
.uploaded-picture-wrapper, .saved-picture-wrapper {
  text-align:center;

  .uploaded-picture, .saved-picture {
    background-size:cover;
    background-position:center center;
    height:232px;
    width:232px;
    border:1px solid #dedede;
    border-radius:2px;
    display:inline-block;
  }
  .uploaded-picture.fi-page-pdf::before, .saved-picture.fi-page-pdf::before {
    line-height: 232px;
    font-size: 200px;
  }
}