$number_of_steps: 3;
.profile-creator-heading-wrapper {
  height:0;
  overflow:hidden;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s;

  .profile-creator-heading {

    progress /*
    Wait for safari to add a nice way to show the progress css
    progress::-moz-progress-bar, progress::-webkit-progress-value*/ {
      width:100%;
      border-radius:0;
      border:none;
      height:5px;
      background-color:$primary_light_color;
    }
  }
}
.profile-creator-wrapper {
  width:100%;
  height:0;
  overflow:hidden;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s;



  .profile-creator-container {
    width:percentage($number_of_steps*1);

    .profile-creator-step {
      padding-bottom:15px;
      float:left;
      width:percentage(1/$number_of_steps);

      fieldset {
        border:none;
        border-top:solid 1px #565656;

        legend {
          padding:0 25px;
          text-transform: uppercase;
          color:#565656;
        }

        > .row:nth-last-child(1) {
          margin-top:25px;
        }

      }
    }
  }
}
.g-recaptcha {
  > div {
    text-align:center;

    > div {
      display:inline-block;
    }
  }
}
.register_follow_up {
  span.account-choice-sprite {
    display: inline-block;
    //height: 180px;
    height:220px;
    width: 86px;
    background-image: url('/img/icons/account-choice-sprites.svg');
    background-repeat: no-repeat;
    //margin-bottom:50px;
  }
  .fancy-radio-button {
    padding:15px 5px;
    display:inline-block;
    width:46%;
    float:left;
    border-radius:3px;
    border:solid 1px #E9E9E9;
    background-color:#FAFAFA;
    margin:0 1%;
    cursor:pointer;
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;

    @media screen and (max-width: 39.9375em) {
      width:80%;
      margin-left:10%;
      text-align:center;
      margin-bottom:2%;
    }
  }
  .fancy-radio-button[data-input-value=searcher] {
    span.account-choice-sprite {
      background-position:right center;
    }
  }
  .fancy-radio-button[data-input-value=profile] {
    span.account-choice-sprite {
      background-position:left center;
    }
  }
  .fancy-radio-button:not([data-input-checked]):not(.no-choice) {
    opacity:0.5;
  }
  .profile-form-wrapper {
    display:block;
  }

  span.big-professions-sprite {
    display:inline-block;
    height:184px;
    width:130px;
    background-image:url('/img/icons/big-professions-icon.svg');
    background-position:center center;
    background-repeat:no-repeat;
    margin-top:25px;
  }
  input#profession {
    z-index:5;
  }
}
.fancy-radio-button:hover {
  -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,0.25);
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.25);
}

body.register_follow_up {
  > .bullseye-flash {
    transform: translateY(115px);
  }
}