.no-margin-page-wrapper {

  h1, h2, h3, h4, h5, h6 {
    text-transform: none;
  }
  //.sidebar {
  //  width:28%;
  //  padding:0;
  //
  //  .sidebar-box {
  //    min-height:300px;
  //  }
  //}
  .main-content {
    //width:72%;
    width:100%;
    padding:25px 100px;
    float:left;

    h1 {
      text-transform: none;
    }

    .open-sidebar-wrapper {
      position:absolute;
      font-size:30px;
      color:#343434;
      left:0.9375rem;
      margin-top:-0.2625rem;
      cursor:pointer;
    }
  }
  .grouped-menu {
    border-right:solid 1px #979797;

    ul {
      list-style:none;
      padding:0;
      margin:0;

      li {
        padding:0;
        margin:0;

        a {
          padding: 0 0.9375rem;
          display:block;
          line-height:40px;

          .icon {
            //padding: 0 0.9375rem;
            padding-right:0.5rem;
            font-size:30px;
            display:block;
            float:left;
            color:#434343;
          }

          [class^="beye-"]:before,
          [class*=" beye-"]:before {
            font-size:20px;
          }
        }
        a:hover {
          background-color:rgba(0,0,0,0.1);
        }
      }
      li.group-header {
        border-top:solid 1px #979797;

        > span {
          padding: 0 0.9375rem;
          color: #757575;
          font-weight:bold;
        }
        > ul {
          > li {
            > a {
              padding: 0 0.9375rem;
            }
          }
        }
      }
      li:not(.group-header) {
        height:40px;
        line-height:40px;
      }
    }
  }

  .gray-background {
    background-color:rgba(0,0,0,0.1);
  }
}
@media screen and (max-width: 63.9375em) {
  //$sidebar_width: 75vw;
  //$columns_width: 175vw;
  //@media screen and (max-width: 39.9375em) {
  //  $sidebar_width: 45%;
  //  $columns_width: 200%;
  //}


  .no-margin-page-wrapper {
    //width: 100vw;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    background-color:white;

    > .columns {
      //width: $columns_width;
    }
    .main-content {
      //width: 100vw;
      //padding: 1.2rem;
      padding:25px 100px;
      @media only screen and (max-width: 40em) {
        padding:25px 0px;
      }
      position:relative;
      //-webkit-transform: translateX(-#{$sidebar_width});
      //-moz-transform: translateX(-#{$sidebar_width});
      //-ms-transform: translateX(-#{$sidebar_width});
      //-o-transform: translateX(-#{$sidebar_width});
      //transform: translateX(-#{$sidebar_width});
      //-webkit-transform: translateX(-95%);
      //-moz-transform: translateX(-95%);
      //-ms-transform: translateX(-95%);
      //-o-transform: translateX(-95%);
      //transform: translateX(-95%);
    }
    //.sidebar, .main-content {
    //  -webkit-transition: transform 0.5s;
    //  -moz-transition: transform 0.5s;
    //  -ms-transition: transform 0.5s;
    //  -o-transition: transform 0.5s;
    //  transition: transform 0.5s;
    //}
  }
  //.no-margin-page-wrapper.open-sidebar {
  //  .sidebar {
  //    -webkit-transform: translateX(0);
  //    -moz-transform: translateX(0);
  //    -ms-transform: translateX(0);
  //    -o-transform: translateX(0);
  //    transform: translateX(0);
  //  }
  //  .main-content {
  //    -webkit-transform: translateX(0);
  //    -moz-transform: translateX(0);
  //    -ms-transform: translateX(0);
  //    -o-transform: translateX(0);
  //    transform: translateX(0);
  //  }
  //}
}