$small_size: 60px;
$medium_size: 300px;
$big_size: 580px;
$pupil_size_small : $small_size / 100 * 15;
$pupil_size_medium: $medium_size / 100 * 15;
$pupil_size_big: $big_size / 100 * 15;
$server: "https://bullseye.nl";

.logo {
  height: $big_size;
  width: $big_size;
  max-width: 90vw;
  max-height: 90vw;

  .logo-pupil {
    height: $pupil_size_big;
    width: $pupil_size_big;
    margin-left: $pupil_size_big / 2 * -1;
    margin-top: $pupil_size_big / 2 * -1;
    background-size: $pupil_size_big $pupil_size_big;
  }
}
.logo.medium {
  height: $medium_size;
  width: $medium_size;

  .logo-pupil {
    height: $pupil_size_medium;
    width: $pupil_size_medium;
    margin-left: $pupil_size_medium / 2 * -1;
    margin-top: $pupil_size_medium / 2 * -1;

    background-size: $pupil_size_medium $pupil_size_medium;
  }
}
.logo.small {
  height: $small_size/1.2;
  width: $small_size;

  .logo-pupil {
    height: $pupil_size_small;
    width: $pupil_size_small;
    margin-left: $pupil_size_small / 2 * -1;
    margin-top: $pupil_size_small / 2 * -1;

    background-size: $pupil_size_small $pupil_size_small;
  }
}
//@media screen and (max-width: 63.9375em) {
//  .logo.small {
//    height: $small_size*0.75;
//    width: $small_size*0.75;
//
//    .logo-pupil {
//      height: $pupil_size_small*0.75;
//      width: $pupil_size_small*0.75;
//      margin-left: $pupil_size_small*0.75 / 2 * -1;
//      margin-top: $pupil_size_small*0.75 / 2 * -1;
//
//      background-size: $pupil_size_small*0.75 $pupil_size_small*0.75;
//    }
//  }
//}


.logo {
  display: inline-block;
  position:relative;

  > div {
    position:absolute;
  }

  .logo-foreground {
    background-image:url('#{$server}/img/logo-foreground-01.svg');
    height: inherit;
    width: inherit;
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
    z-index:3;
  }

  .logo-pupil-wrapper {
    width:25%;
    height:25%;
    left:50%;
    margin-left:-13%;
    top:50%;
    margin-top:-5%;
    z-index:2;
    overflow: hidden;

    .logo-pupil {
      background-image:url('#{$server}/img/pupil.svg');
      position:absolute;
      left:60%;
      top:65%;

    }
  }

  .logo-background {
    background-image:url('#{$server}/img/logo-background-01.svg');
    height:inherit;
    width:inherit;
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
    z-index:1;
  }
}

.static-logo {
  display: inline-block;
  position:relative;
  background-image:url('#{$server}/img/logo-horns-no-b.svg');
  width:15vw;
  height:7.5vw;
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center center;
}
@media screen and (max-width: 63.9375em) and (orientation:landscape) {
  .static-logo {
    width:30vw;
    height:15vw;
  }
}
@media screen and (max-width: 63.9375em) and (orientation:portrait) {
  .static-logo {
    width:50vw;
    height:25vw;
  }
}