//$green_rating: #79ef4b;
$rating_color: #FF9000;
$no_rating: #CCC;

.rating.rating-0 {
  .star-1, .star-2, .star-3, .star-4, .star-5 {
    color:$no_rating;
  }
}
.rating.rating-1 {
  .star-1 {
    color:$rating_color;
  }
  .star-2, .star-3, .star-4, .star-5 {
    color:$no_rating;
  }
}
.rating.rating-2 {
  .star-1, .star-2 {
    color:$rating_color;
  }
  .star-3, .star-4, .star-5 {
    color:$no_rating;
  }
}
.rating.rating-3 {
  .star-1, .star-2, .star-3 {
    color:$rating_color;
  }
  .star-4, .star-5 {
    color:$no_rating;
  }
}
.rating.rating-4 {
  .star-1, .star-2, .star-3, .star-4 {
    color:$rating_color;
  }
  .star-5 {
    color:$no_rating;
  }
}
.rating.rating-5 {
  .star-1, .star-2, .star-3, .star-4, .star-5 {
    color:$rating_color;
  }
}