body.login, body.password_forgot, body.password_reset {
  .middle {
    background-image:url("../img/bg_blur.jpg");
    background-size:cover;
    background-position:center top;

    > .wide-wrapper.main-content-wrapper {
      height:calc(100vh - 200px );

      .table {
        height:inherit;

        .auth-box {
          width:100%;
          background-color:white;
          min-height:10px;
          position:relative;
          -webkit-border-radius:3px;
          -moz-border-radius:3px;
          border-radius:3px;

          //margin-top:100px;
          //opacity:0;
          animation-name: fly-auth-box-in;
          animation-duration: 1s;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -o-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

          .auth-box-title {
            margin-top:-25px;
            padding-left:10px;
            padding-right:10px;
            //position:absolute;
            min-width:1px;
            background-color:white;
            -webkit-border-radius:3px;
            -moz-border-radius:3px;
            border-radius:3px;

            h1 {
              line-height:50px;
              font-size:24px;
              margin:0;
            }
          }
          .auth-box-content {
            padding:25px;
            padding-bottom:5px;

            input[type=submit] {
              margin-bottom:10px;
            }
          }
        }
      }
    }
  }
}
@keyframes fly-auth-box-in {
  from {
    margin-top:100px;
    opacity:0;
  }
  to {
    margin-top:0;
    opacity: 1;
  }
}