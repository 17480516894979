.new-review-wrapper {
  .review-preview {
    width: inherit;
    /* Rectangle 14: */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.50);
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 25px;

    .large-text {
      font-size: 1.25em;
    }
  }
}