.reveal-overlay {
  .reveal {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    h1 {
      line-height:100%;
      font-size:1.3rem;
    }
    p:not(.lead) {
      font-size:14px;
    }

  }
}