.page-icon-wrapper {
  width:0;
  height:0;
  position:relative;
}
.page-icon {
  width:55px;
  height:55px;
  position:absolute;
  left:-72.5px;
}
.page-icon#job-icon {
  background-image:url('/img/icons/zakelijk 2.svg');
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center center;
}
.arrow-icon::before {
  $size: 20px;
  width: $size;
  height: $size;
  content: "\00a0";
  background-position:center center;
  background-repeat:no-repeat;
  background-size: contain;
  display: inline-block;
  background-image:url('data:image/svg+xml;utf8,<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847" enable-background="new 0 0 451.846 451.847" xml:space="preserve"> <path style="fill: rgba(51,159,255,0.999);" d="M345.441,248.292L151.154,442.574c-12.359,12.363-32.397,12.363-44.75,0c-12.354-12.355-12.354-32.393,0-44.744 l171.914-171.91L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"/></svg>');
}

.info-icon-wrapper {
  font-size: 12px;

  > .row {
    margin-bottom: 20px;
  }
  span {
    display:block;
    text-align:center;
  }
  span.key {
    font-weight:bold;
    color:#a4a4a4;
  }

  .info-icon {
    width:100%;
    height:40px;
    background-size:contain;
    background-repeat:no-repeat;
    background-position: center center;
  }
  .info-icon.money {
    background-image:url('/img/icons/geld.svg');
  }
  .info-icon.time {
    background-image:url('/img/icons/tijd.svg');
  }
  .info-icon.calendar {
    background-image:url('/img/icons/cirkel-kalender.svg');
  }
  .info-icon.category {
    background-image:url('/img/icons/cirkel-categorie.svg');
  }
  .info-icon.location {
    background-image:url('/img/icons/cirkel-locatie.svg');
  }
  .info-icon.comments {
    background-image:url('/img/icons/cirkel-reacties.svg');
  }
  .info-icon.views {
    background-image:url('/img/icons/cirkel-oog.svg');
  }
}