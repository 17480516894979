body.home {
  .phone-button-wrapper {
    $size: 200px;
    position: absolute;
    right: 50px;
    top: 50px;
    background-color: #0088ff;
    border:solid 10px rgba(255,255,255,1);
    border-radius: $size / 2;
    width: $size;
    height: $size;
    padding: 15px;
    color: white;
    transform: rotate(8deg);
    @media only screen and (max-width: 40em) {
      transform: rotate(8deg) scale(0.75);
      right: 0;
      top: 0;
    }

    .phone-button-content {
      height: 80%;
      width: 80%;
      margin-left: 10%;
      margin-top: 20%;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
}