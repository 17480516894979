.complete-job-wrapper {
  h1, h2, h3, h4, h5 {
    text-transform: none;
  }
  .complete-job-lightbox-background {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.9);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .complete-job-lightbox-wrapper {
    width: 100vw;
    height: 100vh;
    z-index: 101;
    //position: fixed;
    position: absolute;
    left: 0;
    top: 0;
    padding: 50px 0;
    max-width: 1200px;
    @media screen and (min-width: 1200px) {
      left: 50%;
      margin-left: -600px;
    }

    > .row {
      //background-color:rgba(0,0,0,0.85);
      border-radius: 3px;
    }
    .lightbox-close-button-wrapper {
      height: 0;
      position: relative;

      a {
        text-shadow: 1px 1px 0 #212121;
        position: absolute;
        right: 18px;
        top: 0;
        color: rgba(255,255,255,0.6);
        font-size: 40px;
        z-index: 105;
      }
    }
    .table {
      height: 100%;
    }
    .complete-job-container {
      $vertical_padding: 50px;
      $horizontal_padding: 100px;
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      padding: $vertical_padding $horizontal_padding;

      .complete-job-scroller-wrapper {
        width: 100%;
        height: inherit;
        overflow: hidden;

        .complete-job-scroller-container {
          .complete-job-scroller {
            float: left;
            padding: 0 $horizontal_padding;
          }
          .complete-job-scroller.big-text {
            font-size: 24px;
          }
          .complete-job-scroller:nth-child(0) {
            padding-left: 0;
          }
          .complete-job-scroller:nth-last-child(0) {
            padding-right: 0;
          }
        }
      }
      @for $i from 1 through 10 {
        .complete-job-scroller-wrapper[data-contains="#{$i}"]  {

          .complete-job-scroller-container {
            width: percentage($i);

            .complete-job-scroller {
              width: percentage(1/$i);
            }
          }
        }
      }


      .complete-job-progress-wrapper {
        position: relative;
        height: 0;
        width: calc(100% + (#{$horizontal_padding}*2));

        .complete-job-progress {
          height: 5px;
          background-color: #0088FF;
          position: absolute;
          top:$vertical_padding * -1;
          left: $horizontal_padding* -1;
          -webkit-transition: width 0.25s ease-in;
          -moz-transition: width 0.25s ease-in;
          -ms-transition: width 0.25s ease-in;
          -o-transition: width 0.25s ease-in;
          transition: width 0.25s ease-in;
        }
      }
      @for $i from 0 through 100 {
        .complete-job-progress-wrapper[data-progress="#{$i}"] .complete-job-progress  {
          width: percentage($i/100);
        }
      }
    }

    @media only screen and (max-width: 64em) {
      .complete-job-container {
        $vertical_padding: 50px;
        $horizontal_padding: 10px;
        padding: $vertical_padding $horizontal_padding;

        .complete-job-scroller-wrapper {
          .complete-job-scroller-container {
            .complete-job-scroller {
              padding: 0 $horizontal_padding;
            }
          }
        }
        .complete-job-progress-wrapper {
          width: calc(100% + (#{$horizontal_padding}*2));

          .complete-job-progress {
            left: $horizontal_padding* -1;
          }
        }
      }
    }
  }
}