.image-zoom-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.8);
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1000;
  transition: opacity 500ms ease;

  &.show {
    opacity: 1;
  }

  img {
    max-width: 90vw;
    max-height: 90vh;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
    outline: 10px solid white;
  }
}