.promotion-side-wrapper {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 50vh;
  transform: translateY(-50%) translateX(4px);
  min-height: 200px;
  width: 150px;
  background-color: white;
  border: 4px solid #339fff;
  border-radius: 2px;
  transition: transform .5s ease-out;

  .open-close-wrapper {
    position: absolute;
    padding-top: 3px;

    .arrow-icon::before {
      transition: transform .5s ease-in;
    }
  }


  span.title {
    color: #339fff;
    font-weight: bold;
    margin: 4px;
  }

  ul.options {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      display: none;
      text-align: center;


      .present-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  span.progress-text {
    font-size: 12px;
    text-align: center;
  }

  ul.steps {
    margin: 0;
    padding: 0;
    font-size: 12px;
    text-align: left;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      opacity: 0.6;
    }
    li::before {
      display: block;
      float: left;
      background-image: url("/img/icons/green-check-no-circle.svg");
      width: 20px;
      height: 20px;
      background-size: contain;
      content: "\00a0";
      clear: left;
    }
    li.done {
      opacity: 1;
    }
  }


  p.done {
    font-size: 12px;
    text-align: left;
    padding: 10px;
    word-break: keep-all;
    margin: 0;
    padding-bottom: 0;

    .button {
      font-size: inherit;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
.promotion-side-wrapper.hidden {
  transform: translateX(100%) translateY(-50%);
}
body.register_follow_up {
  .profile-form-wrapper:not([style="display: none;"]) {
    .phone-select-wrapper {
      animation-name: show_the_select_wrapper;
      animation-duration: .5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      margin-bottom: 50px;
    }
    @keyframes show_the_select_wrapper {
      from {
        opacity: 0;
        transform: scaleY(0);
      }
      to {
        opacity: 1;
        transform: scaleY(1);
      }
    }

    .present-item {
      margin: 20px;
      width: calc(100% - 40px);
      background-color: white;

      > * {
        width: 100%;
      }
      @media only screen and (max-width: 40em) {
        > * {
          width: 50%;
          float: left;
        }
        > *:nth-last-child(1) {
          clear: right;
        }
      }

      .present-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}

@media only screen and (max-width: 64em) {
  .promotion-side-wrapper:not(.open):not(.hidden) {
    transform: translateY(-50%) translateX(80%);

    ul.steps {
      //display: none;
    }
  }
  .promotion-side-wrapper.open {
    .open-close-wrapper {
      .arrow-icon::before {
        transform: rotate(180deg);
      }
    }
  }
}
@media only screen and (min-width: 64.063em) {
  .open-close-wrapper:not(.hidden) {
    display: none;
  }
}
