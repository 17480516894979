.select-children-wrapper, .tag-selector {
  > ul {
    margin:0;
    padding:0;

    > li {
      display:inline-block;
      margin-left:7.5px;
      margin-right:7.5px;
      position:relative;

      //span.plus-icon {
      //  position:absolute;
      //  margin-left:-10px;
      //  top:0;
      //  background-image:url('/img/icons/plus-sign.svg');
      //  background-repeat:no-repeat;
      //  background-position:center center;
      //  width:18px;
      //  display:block;
      //  z-index:5;
      //  line-height:60px;
      //  left:100%;
      //}
      //span.plus-icon::before {
      //  content:"\00a0\00a0";
      //}
    }
    > li.option {
      line-height:60px;

      span {
        background: #FF9000;
        border-radius: 2px;
        padding:9px 10px;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
    > li:not(.option) {
      position:relative;
      clear:both;
      float:left;
    }
    > li:not(.option)::before {
      content:"\00a0";
      //line-height:60px;
    }

    > li.option:hover::after {
      background-color:#999;
      //color:rgba(0,0,0,0.25);
    }
    //> li.empty-option {
    //  margin:0;
    //}
  }
  input {
    margin:7.5px;
  }
}
.select-children-wrapper {
   width:100%;
   min-height:200px;
   padding:5px;
 }
.tag-selector {
  padding:0;

  > ul {
    > li.option {
      cursor:pointer;

      span {
        background: #0088ff;
      }
    }
  }
}
.select-children-wrapper:not([readonly]), .tag-selector:not([readonly]) {
  border:solid 1px rgba(202,202,202,1);

  > li.option {
    span {
      cursor:pointer;
    }
  }
  li.option::after {
    $close_sign_size: 14px;
    $border_size: 2px;
    content:"×";
    line-height:$close_sign_size - $border_size*2;
    cursor:pointer;
    position:absolute;
    top:5.5px;
    margin-left:$close_sign_size/2*-1;
    border-radius:$close_sign_size/2;
    width:$close_sign_size;
    height:$close_sign_size;
    font-size:$close_sign_size - 1;
    text-align:center;
    border:solid $border_size white;
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -ms-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    background-color:#CCC;
    color:rgba(255,255,255,1);
  }
}
.tag-selector:not([readonly]) {
  border:none;

  > li.option {
    background-color:blue;
  }
}