.global-page-wrapper {
  .page-wrapper {
    padding:25px;

  }
  .sidebar {
    width:20%;

    .sidebar-box {
      padding: 25px 0.9375rem;
    }
    .sidebar-box.options-menu {
      .row .column > ul {
        margin: 0 -0.9375rem;
      }
      ul {
        list-style:none;

        li {

          a {
            line-height:30px;
            display:block;
            padding:0 0.9375rem;
          }

          > ul {
            margin:0;
            overflow:hidden;
            height:0;

            li a {
              padding-left:30px;
            }
          }
        }

        li.active, li:hover {
          > ul {
            overflow: auto;
            height:auto;
          }
        }
        li:not(.active):hover {
          position:relative;
          > ul {
            position:absolute;
            background-color:white;
            width:100%;
            margin-left:100%;
            margin-top:-30px;
          }
        }

        a:hover {
          background-color:rgba(0,0,0,0.025);
        }
        li.active > a {
          background-color:rgba(0,136,255,0.1);
        }
        li.active > a:hover {
          background-color:rgba(0,136,255,0.25);
        }
      }
    }
  }
  .sidebar.has-button {
    .sidebar-box:nth-child(2) {
      margin-top:0;
    }
  }
  .sidebar.sidebar-left {
    margin-right:5%;
  }
  .sidebar.sidebar-right {
    margin-left:5%;
  }
  .main-content {
    width:75%;
    float:left;
  }
  @media screen and (max-width: 63.9375em) and (min-width: 40em) {
    .sidebar {
      width:35%;
    }
    .main-content {
      width:60%;
    }
  }
  @media screen and (max-width: 39.9375em) {
    .sidebar, .sidebar.sidebar-left, .sidebar.sidebar-right {
      width:100%;
      margin:0;
      margin-bottom:5%;
    }
    .main-content {
      width:100%;
    }

  }
}
.global-full-page-wrapper {
  background-color:white;

  .page-wrapper {
    padding:25px;
  }
  .main-content {
    float:none;
    width:55%;
    margin-left:auto;
    margin-right:auto;
    @media screen and (max-width: 63.9375em) and (min-width: 40em) {
      width:90%;
    }
    @media screen and (max-width: 39.9375em) {
      width:100%;
    }

    h1 {
      font-size:2.5em;
    }
    h2 {
      font-size:2em;
    }
    h3 {
      font-size:1.6em;
    }
    h4 {
      font-size:1.28em;
    }
    h5 {
      font-size:1.024em;
    }
    h1, h2, h3, h4, h5 {
      line-height:normal;
      font-weight:normal;
      text-transform:none;
    }
    .large-text {
      font-size:1.25em;
    }
    .small-text {
      font-size:0.75em;
    }
  }
}
.page-image {
  height: 200px;
  width: 100%;
  background-position: center center;
  background-size: cover;

  h1 {
    color: white;
    text-shadow: 1px 1px 0 #414141;
    line-height: 200px;
    margin: 0;
  }
}